import React, { useState, useContext, memo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { TierContext } from "../../context/TierContext";
import {
  useGetPostByIdQuery,
  useDeletePostMutation,
} from "../../services/post";
import { Typography, CircularProgress } from "@mui/material";
import PostCard from "./PostCard";
import { ConfirmationDialog } from "../";
import PostPageNavbar from "./PostPageNavbar";
import NotificationSnackbar from "../helpers/notification-snackbar";
import MiragePostToNewCategoryForm from "../../services/posts/MiragePostToNewCategoryForm";

const PostPage = () => {
  const { postId } = useParams();
  console.log("postId", postId);
  const navigate = useNavigate();
  const { userId, accountType, userImage, userName } = useContext(AuthContext);
  const { currentTierId, tierType } = useContext(TierContext);
  const { data: post, isLoading } = useGetPostByIdQuery(postId, {
    skip: !postId || postId === undefined || postId === null,
  });
  console.log("post", post);

  const [deletePost] = useDeletePostMutation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [
    migratePostToNewCategoryDialogOpen,
    setMigratePostToNewCategoryDialogOpen,
  ] = useState(false);
  const [postToMigrate, setPostToMigrate] = useState(null);

  const handleDeletePost = async () => {
    const result = await deletePost({
      postId: post?._id,
      tierId: currentTierId,
    });

    if (result) {
      setNotification({ open: true, message: "Post deleted successfully." });
      handleDialogClose();
      navigate(-1);
    } else {
      setNotification({
        open: true,
        message: `Error deleting post. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleDialogOpen = () => {
    setDeleteDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleMigratePostToNewCategoryDialogOpen = (post) => {
    setMigratePostToNewCategoryDialogOpen(true);
    setPostToMigrate(post);
  };

  const handleMigratePostToNewCategoryDialogClose = (event) => {
    event && event.stopPropagation();
    setMigratePostToNewCategoryDialogOpen(false);
    setPostToMigrate(null);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <div
      mt={2}
      className={`post-page ${tierType === "conference" ? "conference" : ""}`}
    >
      <PostPageNavbar
        selectedCategory={post?.category?.path || ""}
        selectedCategoryName={post?.category?.name || ""}
        selectedIcon={post?.category?.icon || ""}
      />
      <div className="post-page-body">
        {isLoading && (
          <div className="loading-spinner">
            <CircularProgress size={24} />
          </div>
        )}
        {post && (
          <PostCard
            key={post?._id}
            post={post}
            onDialogOpen={handleDialogOpen}
            onMigratePostToNewCategoryDialogOpen={
              handleMigratePostToNewCategoryDialogOpen
            }
            currentUserId={userId}
            currentUserName={userName}
            currentUserImageUrl={userImage}
            accountType={accountType}
            isPostList={false}
            isPostPage={true}
          />
        )}
        {!post && !isLoading && (
          <Typography variant="h6" m="2rem 0.1rem 0.5rem 5rem">
            The post was not found
          </Typography>
        )}
      </div>
      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDeletePost}
        title="Confirm Deleting"
        description="Are you sure you want to delete this post?"
      />
      <MiragePostToNewCategoryForm
        open={migratePostToNewCategoryDialogOpen}
        onClose={handleMigratePostToNewCategoryDialogClose}
        post={postToMigrate}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </div>
  );
};

export default memo(PostPage);
