import React, { useContext } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"; // Or use HelpOutlineIcon for a question mark
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { TierContext } from "../../context/TierContext";
import { AuthContext } from "../../context/AuthContext";
import { formatPhoneNumber } from "../helpers/utils";
import {
  Card,
  Box,
  CardContent,
  CardMedia,
  Typography,
  Tooltip,
  CardHeader,
} from "@mui/material";
dayjs.extend(relativeTime);

const MemberCard = ({ member, onSelect, viewMode = "grid" }) => {
  const { tierType } = useContext(TierContext);
  const { accountType } = useContext(AuthContext);
  const lastOnlineTime = member?.user?.lastOnlineTime;

  let lastSeen;
  if (tierType === "conference") {
    const isOnlineWithinWeek =
      lastOnlineTime &&
      dayjs(lastOnlineTime).isAfter(dayjs().subtract(7, "day"));
    lastSeen = isOnlineWithinWeek
      ? `Last Seen: ${dayjs(lastOnlineTime).fromNow()}`
      : null;
  } else {
    lastSeen = lastOnlineTime
      ? `Last Seen: ${dayjs(lastOnlineTime).fromNow()}`
      : "Last Seen: Never";
  }

  // Adjust these values to fit your design needs
  let cardStyle = {
    width: 300, // Set a fixed width for the card
    height: 450, // Set a fixed height for the card
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // Ensures content inside is spaced out
  };
  // Override styles if the view mode is 'row'
  if (viewMode === "row") {
    cardStyle = {
      ...cardStyle, // Spread the default styles
      height: 140, // Set a different fixed height for the card in row view
      flexDirection: "row", // Set the direction of items for row view
    };
  }

  return (
    <Card
      sx={cardStyle}
      className={`member-card ${viewMode}`}
      onClick={onSelect}
    >
      <CardContent className="member-card-item">
        {viewMode === "grid" && (
          <CardMedia
            component="img"
            className="card-media-image"
            image={
              member?.user?.image?.url || "/imgs/Coming-soon-Headshot.webp"
            }
            alt={`${member?.user?.firstName} ${member?.user?.lastName}`}
          />
        )}
        <CardHeader
          avatar={
            viewMode === "row" && (
              <CardMedia
                component="img"
                className="card-media-image"
                image={
                  member?.user?.image?.url || "/imgs/Coming-soon-Headshot.webp"
                }
                alt={`${member?.user?.firstName} ${member?.user?.lastName}`}
              />
            )
          }
          title={
            <Box sx={{ display: "flex", flexDirection: "column", m: 0, p: 0 }}>
              <Typography
                variant="h6"
                component="div"
                mt={0}
                align="left"
                className="card-title"
              >
                {member?.user?.firstName} {member?.user?.lastName}
              </Typography>
              {tierType === "conference" &&
                member?.office &&
                member?.office?.name && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#f0f0f0",
                      padding: "1px 8px",
                      borderRadius: "4px",
                      width: "fit-content",
                    }}
                  >
                    <BusinessIcon
                      fontSize="small"
                      sx={{ color: "#1976d2", mr: 0.5 }}
                    />
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{ fontWeight: 500, color: "#1976d2" }}
                    >
                      {member?.office?.name}
                    </Typography>
                  </Box>
                )}
              {tierType === "conference" &&
                member?.office &&
                member?.office?.city &&
                member?.office?.stateProvince && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LocationOnIcon
                      fontSize="small"
                      sx={{ color: "#757575", mr: 0.5, mb: 1 }}
                    />
                    <Typography variant="body2" component="div" align="left">
                      {`${member?.office?.city}, ${member?.office?.stateProvince}`}
                    </Typography>
                  </Box>
                )}
            </Box>
          }
          subheader={
            <>
              {tierType === "conference" &&
                member?.conferenceParticipantType &&
                member.conferenceParticipantType !== "Attendee" && (
                  <Typography
                    variant="body2"
                    component="div"
                    align="left"
                    sx={{ mt: 0.5 }}
                  >
                    {`Role: ${member.conferenceParticipantType}`}
                  </Typography>
                )}

              {tierType !== "conference" && member?.profileTitle && (
                <Typography
                  variant="body2"
                  component="div"
                  align="left"
                  sx={{ mt: 0.5 }}
                >
                  {member.profileTitle}
                </Typography>
              )}

              {tierType !== "conference" && (
                <Typography variant="h7" component="div" align="left">
                  {member?.user ? (
                    member?.user?.isEmailPrivate ? (
                      member?.user?.emailPublic ? (
                        <a
                          href={`mailto:${member?.user?.emailPublic}`}
                          style={{
                            textDecoration: "underline",
                            color: "#007bff",
                            cursor: "pointer",
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {member?.user?.emailPublic}
                        </a>
                      ) : (
                        "Email not available"
                      )
                    ) : member?.user?.email ? (
                      <a
                        href={`mailto:${member?.user?.email}`}
                        style={{
                          textDecoration: "underline",
                          color: "#007bff",
                          cursor: "pointer",
                        }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {member?.user?.email}
                      </a>
                    ) : (
                      "Email not available"
                    )
                  ) : (
                    "Email not available"
                  )}
                </Typography>
              )}

              <Typography variant="h7" component="div" align="left">
                {tierType !== "conference" && member?.phone ? (
                  <a
                    href={`tel:${member?.phone}`}
                    style={{
                      textDecoration: "underline",
                      color: "#007bff",
                      cursor: "pointer",
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {formatPhoneNumber(member?.phone)}
                  </a>
                ) : tierType !== "conference" ? (
                  <Tooltip
                    title={`Ask ${
                      member?.user?.firstName || "this user"
                    } to add their phone number in their profile`}
                    placement="top"
                  >
                    <div
                      style={{ display: "inline-flex", alignItems: "center" }}
                    >
                      <span style={{ color: "grey", cursor: "default" }}>
                        Phone not available
                      </span>
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ color: "grey", marginLeft: 4 }}
                      />
                    </div>
                  </Tooltip>
                ) : null}
              </Typography>
              {tierType !== "conference" && member?.officeExt && (
                <Typography variant="h7" component="div" align="left">
                  Office Extension: <strong>{member?.officeExt}</strong>
                </Typography>
              )}
              {(accountType === "brokerage" || accountType === "reosadmin") &&
                lastSeen && <Typography variant="body2">{lastSeen}</Typography>}
            </>
          }
        />
      </CardContent>
    </Card>
  );
};

export default MemberCard;
