import React from "react";
import {
  AccountBox,
  ForwardToInbox,
  ViewSidebar,
  Groups,
  AttachMoney,
  Category,
  Tune,
  Business,
  Workspaces,
  SupportOutlined,
  ReportOutlined,
  DynamicFeedOutlined,
} from "@mui/icons-material";

const sidebarCategories = {
  admin: [
    {
      title: "User Management",
      path: "/user-management",
      icon: <AccountBox />,
    },
    {
      title: "Role Management",
      path: "/role-management",
      icon: <AccountBox />,
    },
    {
      title: "Tier Management",
      path: "/tier-management",
      icon: <Workspaces />,
    },
    {
      title: "Billing Management",
      path: "/billing",
      icon: <AttachMoney />,
    },
    {
      title: "Brokerage Management",
      path: "/brokerage-management",
      icon: <Business />,
    },
    {
      title: "Brokerage Ecosystem Management",
      path: "/brokerage-ecosystem-management",
      icon: <Business />,
    },
    {
      title: "Category Management",
      path: "/category-management",
      icon: <Category />,
    },
    {
      title: "Category Settings Management",
      path: "/category-settings-management",
      icon: <Tune />,
    },
    {
      title: "Post Management",
      path: "/post-management",
      icon: <DynamicFeedOutlined />,
    },
    {
      title: "Team Management",
      path: "/team-management",
      icon: <Groups />,
    },
    {
      title: "Support Management",
      path: "/support-management",
      icon: <SupportOutlined />,
    },
    {
      title: "Error Logs Management",
      path: "/error-logs-management",
      icon: <ReportOutlined />,
    },
    {
      title: "Invitation Logs Management",
      path: "/invitation-logs-management",
      icon: <ReportOutlined />,
    },
    {
      title: "Data Entry Logs Management",
      path: "/data-entry-logs-management",
      icon: <ReportOutlined />,
    },
    {
      title: "Duties Management",
      path: "/duties-management",
      icon: <ReportOutlined />,
    },
    {
      title: "Feature Settings Management",
      path: "/feature-settings-management",
      icon: <ReportOutlined />,
    },
    {
      title: "Invitation List",
      path: "/invitation-list",
      icon: <ForwardToInbox />,
    },
    {
      title: "Listing Tour Management",
      path: "/listing-tour-management",
      icon: <ReportOutlined />,
    },
    {
      title: "Migration Management",
      path: "/migration-management",
      icon: <ReportOutlined />,
    },
  ],
  brokerage: [
    {
      title: "Office Management",
      path: "/brokerage-ecosystem-management",
      icon: <ViewSidebar />,
    },
  ],
};

export default sidebarCategories;
