import React, { useEffect, useRef, useCallback, useState } from "react";
import Webcam from "react-webcam";
import { IconButton, Button, Box, Typography } from "@mui/material";
import {
  RadioButtonChecked,
  StopCircle,
  DesktopWindows,
} from "@mui/icons-material";

const videoConstraints = {
  width: 524,
  height: 320,
  facingMode: "user",
};

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const RecordView = ({ onSave, userRole }) => {
  const webcamRef = useRef(null);
  const screenStreamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isPreview, setIsPreview] = useState(false);
  const [recordingStartTime, setRecordingStartTime] = useState(null);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [isScreenRecording, setIsScreenRecording] = useState(false);
  const recordingIntervalRef = useRef(null);
  const [mergedStream, setMergedStream] = useState(null); // Store combined stream

  const MAX_VIDEO_DURATION_LIMIT = userRole?.includes("reos")
    ? Infinity
    : userRole?.includes("brokerage")
    ? 600
    : 300;
  const MAX_VIDEO_DURATION = userRole?.includes("reos")
    ? Infinity
    : userRole?.includes("brokerage")
    ? 599
    : 299;

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds.toFixed(0)}s`;
  };

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const requestPermissions = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
    } catch (err) {
      console.error("Permission denied:", err);
    }
  };

  useEffect(() => {
    requestPermissions();
  }, []);

  const mergeStreams = async (webcamStream, screenStream) => {
    const mergedTracks = [
      ...webcamStream.getVideoTracks(),
      ...screenStream.getVideoTracks(),
    ];
    const audioTracks = webcamStream.getAudioTracks(); // Optionally capture audio from webcam

    const combinedStream = new MediaStream([...mergedTracks, ...audioTracks]);
    return combinedStream;
  };

  const handleStartCaptureClick = useCallback(async () => {
    try {
      const webcamStream = webcamRef.current?.stream;
      const screenStream = screenStreamRef.current;

      if (!webcamStream || !screenStream) {
        console.error("Stream not available.");
        return;
      }

      // Merge webcam and screen streams into a single stream
      const combinedStream = await mergeStreams(webcamStream, screenStream);
      setMergedStream(combinedStream);

      setCapturing(true);
      setIsPreview(false);
      const startTime = Date.now();
      setRecordingStartTime(startTime);
      const mimeType = isSafari ? "video/mp4" : "video/webm";

      try {
        mediaRecorderRef.current = new MediaRecorder(combinedStream, {
          mimeType,
        });
      } catch (e) {
        console.error("Unsupported mimeType:", mimeType);
        return;
      }

      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();

      recordingIntervalRef.current = setInterval(() => {
        const duration = (Date.now() - startTime) / 1000;
        setRecordingDuration(duration);

        if (duration >= MAX_VIDEO_DURATION) {
          handleStopCaptureClick();
        }
      }, 1000);
    } catch (error) {
      console.error("Error starting capture:", error);
    }
  }, [handleDataAvailable, MAX_VIDEO_DURATION]);

  const handleStopCaptureClick = useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    setCapturing(false);
    setIsPreview(true);

    if (recordingStartTime) {
      const duration = (Date.now() - recordingStartTime) / 1000;
      setRecordingDuration(duration);
    } else {
      console.error("Recording start time is not set.");
    }

    clearInterval(recordingIntervalRef.current);
  }, [recordingStartTime]);

  const handleScreenRecording = async () => {
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      });
      screenStreamRef.current = screenStream;
      setIsScreenRecording(true);
      handleStartCaptureClick();
    } catch (error) {
      console.error("Error starting screen recording:", error);
    }
  };

  const handleRetake = () => {
    setRecordedChunks([]);
    setIsPreview(false);
    setRecordingDuration(0);
    clearInterval(recordingIntervalRef.current);
  };

  const handleSave = useCallback(() => {
    if (recordedChunks.length) {
      const mimeType = isSafari ? "video/mp4" : "video/webm";
      const blob = new Blob(recordedChunks, {
        type: mimeType,
      });
      const videoFile = new File(
        [blob],
        `recorded_video.${mimeType.split("/")[1]}`,
        {
          type: mimeType,
        }
      );
      onSave(videoFile);
      setRecordedChunks([]);
      setRecordingDuration(0);
      setIsPreview(false);
    }
  }, [recordedChunks, onSave]);

  useEffect(() => {
    return () => {
      setCapturing(false);
      setRecordedChunks([]);
      setIsPreview(false);
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
      clearInterval(recordingIntervalRef.current);
    };
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      {/* Display webcam or screen recording */}
      {!isPreview && (
        <>
          <Webcam
            audio
            ref={webcamRef}
            videoConstraints={videoConstraints}
            muted
            className="webcam"
          />
        </>
      )}
      {isPreview && recordedChunks.length > 0 && (
        <video
          src={URL.createObjectURL(
            new Blob(recordedChunks, {
              type: isSafari ? "video/mp4" : "video/webm",
            })
          )}
          controls
          autoPlay
          className="webcam"
        />
      )}
      <Box
        sx={{
          position: "absolute",
          top: 16,
          left: "50%",
          transform: "translateX(-50%)",
          color: "white",
        }}
      >
        {capturing && (
          <Typography color="red">
            {formatDuration(recordingDuration)}
          </Typography>
        )}
        {isPreview && (
          <Typography color="red">
            Duration: {formatDuration(recordingDuration)}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 1, mt: 2 }}>
        <Box
          sx={{
            position: "absolute",
            bottom: 25,
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "center",
            gap: 1,
          }}
        >
          {capturing && (
            <IconButton sx={{ color: "red" }} onClick={handleStopCaptureClick}>
              <StopCircle />
            </IconButton>
          )}
          {!isPreview && !capturing && (
            <>
              <IconButton
                sx={{ color: "red" }}
                onClick={handleStartCaptureClick}
              >
                <RadioButtonChecked />
              </IconButton>
              <Button variant="contained" onClick={handleScreenRecording}>
                Record Screen
              </Button>
            </>
          )}
        </Box>
        {isPreview && (
          <>
            <Button variant="outlined" onClick={handleRetake} size="small">
              Retake
            </Button>
            <Button
              variant="contained"
              color="success"
              size="small"
              onClick={handleSave}
              disabled={recordingDuration > MAX_VIDEO_DURATION_LIMIT}
            >
              Save Video
            </Button>
          </>
        )}
        {recordingDuration >= MAX_VIDEO_DURATION && (
          <Typography variant="body2" color="error" align="center">
            Maximum video duration reached
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default RecordView;
