import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    padding: 5,
    fontSize: 8,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "100%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomWidth: 1,
    backgroundColor: "#f0f0f0",
    padding: 5,
    textAlign: "center",
  },
  tableCol: {
    width: "100%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
  },
  tableCellHeader: {
    fontWeight: "bold",
  },
  tableCell: {
    textAlign: "center",
  },
  title: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 10,
  },
});

const getCellValue = (row, col) => {
  let value = "";

  if (col.valueGetter) {
    value = col.valueGetter(null, row);
  } else if (col.valueFormatter) {
    value = col.valueFormatter(null, row);
  } else if (col.field === "agent") {
    value = row.agent ? `${row.agent.firstName} ${row.agent.lastName}` : "";
  } else if (col.field === "comments") {
    if (!row.comments || !Array.isArray(row.comments)) {
      value = "";
    } else {
      value = row.comments
        .map(
          (comment) =>
            `${comment.user?.firstName || ""} ${
              comment.user?.lastName?.charAt(0) || ""
            }: ${comment.comment || ""}`
        )
        .join("; ");
    }
  } else if (col.field === "listingDate" && row[col.field]) {
    value = new Date(row[col.field]).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  } else {
    value = row[col.field] !== undefined ? row[col.field] : "";
  }

  if (typeof value === "object" && value !== null) {
    value = JSON.stringify(value);
  }

  return value;
};

const PDFDocument = ({ data, columns, listingTourTitle, listingTourDate }) => {
  const getColumnWidth = (column) => {
    const fixedWidths = {
      address: 15,
      comments: 15,
      bedrooms: 3,
      bathrooms: 3,
      totalSqft: 5,
    };
    const totalWidth = 100;
    const fixedWidthColumns = columns.filter((col) => fixedWidths[col.field]);
    const totalFixedWidth = fixedWidthColumns.reduce(
      (sum, col) => sum + fixedWidths[col.field],
      0
    );
    const remainingWidth = totalWidth - totalFixedWidth;
    const remainingColumns = columns.filter((col) => !fixedWidths[col.field]);
    const defaultWidth =
      remainingColumns.length > 0
        ? remainingWidth / remainingColumns.length
        : 0;

    if (fixedWidths[column.field]) {
      return fixedWidths[column.field];
    } else {
      return defaultWidth;
    }
  };
  return (
    <Document>
      <Page style={styles.page} size="A4" orientation="landscape">
        <Text style={styles.title}>
          {listingTourTitle ? listingTourTitle?.toUpperCase() : "Listing Tour"}
        </Text>
        <Text style={styles.subtitle}>
          {listingTourDate
            ? new Date(listingTourDate)?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            : ""}
        </Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            {columns?.map((col) => (
              <View
                key={col?.field}
                style={[
                  styles.tableColHeader,
                  { width: `${getColumnWidth(col)}%` },
                ]}
              >
                <Text style={styles.tableCellHeader}>{col?.headerName}</Text>
              </View>
            ))}
          </View>
          {data?.map((row, rowIndex) => (
            <View key={rowIndex} style={styles.tableRow}>
              {columns.map((col) => (
                <View
                  key={col?.field}
                  style={[
                    styles.tableCol,
                    { width: `${getColumnWidth(col)}%` },
                  ]}
                >
                  <Text style={styles.tableCell}>{getCellValue(row, col)}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
