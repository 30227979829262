import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from "@mui/material";
import {
  useCreateRolePermissionEnumMutation,
  useUpdateRolePermissionEnumMutation,
} from "../../../services/role-permission-enum";
import { useGetAllUserRoleNameEnumsQuery } from "../../../services/user-role-name-enum";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const CreateEditRolePermissionEnumForm = ({
  mode,
  open,
  onClose,
  refetch,
  rolePermissionEnum,
}) => {
  const [userRoleNameId, setUserRoleNameId] = useState("");
  const [rolePermission, setRolePermission] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const { data: userRoleNameEnums, isLoading: isLoadingUserRoleNames } =
    useGetAllUserRoleNameEnumsQuery();

  const [createRolePermissionEnum, { isLoading: isCreating }] =
    useCreateRolePermissionEnumMutation();

  const [updateRolePermissionEnum, { isLoading: isUpdating }] =
    useUpdateRolePermissionEnumMutation();

  useEffect(() => {
    if (mode === "edit" && rolePermissionEnum) {
      setUserRoleNameId(rolePermissionEnum?.userRoleName?._id);
      setRolePermission(rolePermissionEnum?.rolePermission);
    } else {
      setUserRoleNameId("");
      setRolePermission("");
    }
  }, [mode, rolePermissionEnum]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let result;
    if (mode === "create") {
      result = await createRolePermissionEnum({
        userRoleNameId,
        rolePermission,
      });
    } else if (mode === "edit") {
      result = await updateRolePermissionEnum({
        rolePermissionEnumId: rolePermissionEnum?._id,
        userRoleNameId,
        rolePermission,
      });
    }

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (mode === "create") {
          setUserRoleNameId("");
          setRolePermission("");
        }
        refetch?.();
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving Role Permission Enum: ${
          result?.error?.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const isSubmitting = isCreating || isUpdating;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {mode === "create"
          ? "Create Role Permission Enum"
          : "Edit Role Permission Enum"}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="user-role-name-label">User Role Name</InputLabel>
            <Select
              labelId="user-role-name-label"
              id="user-role-name"
              value={userRoleNameId}
              label="User Role Name"
              onChange={(e) => setUserRoleNameId(e.target.value)}
              disabled={isLoadingUserRoleNames}
            >
              {userRoleNameEnums &&
                userRoleNameEnums.map((urn) => (
                  <MenuItem key={urn._id} value={urn._id}>
                    {urn.userRoleName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            required
            fullWidth
            id="rolePermission"
            label="Role Permission"
            name="rolePermission"
            value={rolePermission}
            onChange={(e) => setRolePermission(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={24} />
            ) : mode === "create" ? (
              "Create"
            ) : (
              "Update"
            )}
          </Button>
        </DialogActions>
      </form>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default CreateEditRolePermissionEnumForm;
