import React, { useContext, useState } from "react";
import { Typography, Grid, Link, Button } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import HistoryIcon from "@mui/icons-material/History";
import PublicIcon from "@mui/icons-material/Public";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { TierContext } from "../../../context/TierContext";
import {
  formattedUrl,
  formatPhoneNumber,
  formatDateToDayMonthYear,
} from "../../helpers/utils";

const ShortenedBio = ({ bio }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleReadMoreClick = () => {
    setIsExpanded(!isExpanded);
  };

  const MAX_LENGTH = 100; // Set the maximum number of characters for the short bio

  return (
    <>
      <Typography sx={{ width: "90%" }} variant="body1" className="data-item">
        {bio.length > MAX_LENGTH && !isExpanded
          ? bio.substring(0, MAX_LENGTH) + "..."
          : bio}
      </Typography>
      {bio.length > MAX_LENGTH && (
        <Button onClick={handleReadMoreClick}>
          {isExpanded ? "Read Less" : "Read More"}
        </Button>
      )}
    </>
  );
};

const MemberDataTab = ({ member }) => {
  const formattedPhone = formatPhoneNumber(member?.phone);
  const { tierType } = useContext(TierContext);

  function formatList(list, emptyMessage = "Not Entered") {
    return list && list.length > 0 ? list.join(", ") : emptyMessage;
  }

  function formatLocation(location) {
    if (!location?.city) return "Not Entered";
    const { city, state, country } = location;
    return [city, state, country].filter(Boolean).join(", ");
  }

  function formatDateOfBirth(birthday) {
    // Implement your date formatting logic here
  }
  const WebsiteDetail = ({ member, formattedUrl }) => {
    return (
      <Typography
        component="div"
        variant="body1"
        sx={{ marginBottom: "5px" }}
        className="data-item"
      >
        <PublicIcon
          className="red-icon"
          style={{
            fontSize: "inherit",
            verticalAlign: "middle",
            marginRight: "8px",
          }}
        />
        <strong>Website:</strong>{" "}
        {member?.website ? (
          <Link
            href={formattedUrl(member?.website) || ""}
            target="_blank"
            rel="noopener noreferrer"
            className="data-link"
          >
            {member?.website}
          </Link>
        ) : (
          "No Website"
        )}
      </Typography>
    );
  };
  function EmailDetail({ member }) {
    return (
      <Typography component="div" variant="body1" className="data-item">
        <EmailIcon
          className="red-icon"
          style={{
            fontSize: "inherit",
            verticalAlign: "middle",
            marginRight: "8px",
          }}
        />
        {member?.isEmailPrivate && member?.emailPublic ? (
          <>
            <strong>Public Email:</strong>{" "}
            <a
              href={`mailto:${member?.emailPublic}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {member?.emailPublic}
            </a>
          </>
        ) : member?.email ? (
          <>
            <strong>Email:</strong>{" "}
            <a
              href={`mailto:${member?.email}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {member?.email}
            </a>
          </>
        ) : (
          "No email provided"
        )}
      </Typography>
    );
  }
  function PhoneDetail({ member, formattedPhone }) {
    return (
      <Typography component="div" variant="body1" className="data-item">
        <PhoneIcon
          className="red-icon"
          style={{
            fontSize: "inherit",
            verticalAlign: "middle",
            marginRight: "8px",
          }}
        />
        <strong>Phone:</strong>{" "}
        <a
          href={`tel:${member?.phone}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {formattedPhone}
        </a>
      </Typography>
    );
  }
  function BiographyDetail({ member }) {
    return (
      <Typography component="div" variant="body1" className="data-item">
        <AccountCircleIcon
          className="red-icon"
          style={{ verticalAlign: "middle", marginRight: "8px" }}
        />
        <strong>Bio:</strong>
        <div style={{ marginLeft: "30px" }}>
          <ShortenedBio bio={member?.bio || "No Bio"} />
        </div>
      </Typography>
    );
  }

  const renderLinks = (links, type) => {
    if (!links || links?.length === 0) {
      return null; // Don't render anything if there are no links
    }
    // console.log(type, links, links?.length);
    return (
      <>
        <Typography className="data-header" sx={{ fontWeight: "bolder" }}>
          {type} Links
        </Typography>
        {links?.length > 0 &&
          links?.map((link, index) => (
            <Typography
              key={index}
              component="div"
              variant="body1"
              sx={{ marginLeft: "20px", marginBottom: "10px" }}
              className="data-item"
            >
              <Link
                href={formattedUrl(link?.linkUrl)}
                target="_blank"
                rel="noopener noreferrer"
                className="data-link"
              >
                {link?.linkName}
              </Link>
            </Typography>
          ))}
      </>
    );
  };

  return (
    <div className="member-data-tab" style={{ marginBottom: "120px" }}>
      <Grid
        container
        spacing={2}
        className="member-data-tab-body"
        style={{ paddingBottom: "20px", marginRight: "50px" }}
      >
        {/* Profile Information */}
        <Grid item xs={12} className="data-section">
          <Typography variant="h6" className="data-header">
            Profile Information
          </Typography>
          <div style={{ marginLeft: "20px" }}>
            <Typography
              variant="body1"
              sx={{ marginBottom: "5px" }}
              className="data-item"
            >
              Title: <strong>{member?.profileTitle || "No Profession"}</strong>
            </Typography>
            {tierType === "conference" && (
              <Typography
                variant="body1"
                sx={{ marginBottom: "5px" }}
                className="data-item"
              >
                Conference Role:{" "}
                <strong>{member?.conferenceParticipantType}</strong>
              </Typography>
            )}
            <Typography
              component="div"
              variant="body1"
              sx={{ marginBottom: "5px" }}
              className="data-item"
            >
              <WebsiteDetail member={member} formattedUrl={formattedUrl} />
            </Typography>
            <Typography
              component="div"
              variant="body1"
              sx={{ marginBottom: "5px" }}
              className="data-item"
            >
              <BiographyDetail member={member} />
            </Typography>
          </div>
        </Grid>
        {/* Contact and Location */}
        <Grid item xs={12} className="data-section">
          <Typography component="div" variant="h6" className="data-header">
            Contact and Location
          </Typography>
          <div style={{ marginLeft: "20px" }}>
            <Typography component="div" variant="body1" className="data-item">
              <PhoneDetail member={member} formattedPhone={formattedPhone} />
            </Typography>
            <Typography component="div" variant="body1" className="data-item">
              <EmailDetail member={member} />
            </Typography>

            <Typography variant="body1" className="data-item">
              <AccessTimeIcon
                className="red-icon"
                style={{
                  fontSize: "inherit",
                  verticalAlign: "middle",
                  marginRight: "8px",
                }}
              />
              <strong>Timezone:</strong> {member?.timezone || "Not set"}
            </Typography>
            <Typography variant="body1" className="data-item">
              <LocationOnIcon
                className="red-icon"
                style={{
                  fontSize: "inherit",
                  verticalAlign: "middle",
                  marginRight: "8px",
                }}
              />
              <strong>Current Location:</strong>{" "}
              {formatLocation(member?.currentLocation)}
            </Typography>
          </div>
        </Grid>
        {/* Professional Details */}
        <Grid item xs={12} className="data-section">
          <Typography variant="h6" className="data-header">
            Professional Details
          </Typography>
          <div style={{ marginLeft: "20px" }}>
            <Typography variant="body1">
              <VerifiedUserIcon
                className="red-icon"
                style={{ verticalAlign: "middle", marginRight: "8px" }}
              />
              <strong>Certifications:</strong>{" "}
              {member?.certifications?.join(", ") || "None Entered"}
            </Typography>
            <Typography variant="body1">
              <EmojiEventsIcon
                className="red-icon"
                style={{ verticalAlign: "middle", marginRight: "8px" }}
              />
              <strong>Awards:</strong>{" "}
              {member?.awards?.join(", ") || "No Awards Entered"}
            </Typography>
            <Typography component="div" variant="body1">
              <HistoryIcon
                className="red-icon"
                style={{ verticalAlign: "middle", marginRight: "8px" }}
              />
              <strong>Years Licensed:</strong>{" "}
              {member?.yearsLicensed || "Not Specified"}
            </Typography>
            <Typography component="div" variant="body1">
              <FormatListNumberedIcon
                className="red-icon"
                style={{ verticalAlign: "middle", marginRight: "8px" }}
              />
              <strong>License Number:</strong>{" "}
              {member?.licenseNumber || "No License Number"}
            </Typography>
          </div>
        </Grid>
        {/* Personal Interests */}
        <Grid item xs={12} className="data-section">
          <Typography variant="h6" className="data-header">
            Personal Interests
          </Typography>
          <div style={{ marginLeft: "20px" }}>
            <Typography variant="body1" className="data-item">
              <strong>Hobbies:</strong> {formatList(member?.hobbies)}
            </Typography>
            {member?.geographicAreasServiced &&
              member?.geographicAreasServiced?.length > 0 && (
                <Typography variant="body1" className="data-item">
                  <strong>Geographic Areas Serviced:</strong>{" "}
                  {formatList(
                    member?.geographicAreasServiced,
                    "No Serviced Areas Yet"
                  )}
                </Typography>
              )}
          </div>
        </Grid>
        {/* Additional Information */}
        <Grid item xs={12} className="data-section">
          <Typography variant="h6" className="data-header">
            Additional Information
          </Typography>
          <div style={{ marginLeft: "20px" }}>
            <Typography component="div" variant="body1" className="data-item">
              {/* Gender: {member?.gender || "Prefer not to say"} */}
            </Typography>
            <Typography variant="body1" className="data-item">
              <strong>Birthday:</strong>{" "}
              {formatDateOfBirth(member?.birthday) || "Not Entered"}
            </Typography>
          </div>
        </Grid>
        {/* Floor Duty Preferences */}
        <Grid item xs={12} className="data-section">
          <Typography component="div" variant="h6" className="data-header">
            Floor Duty Preferences
          </Typography>
          <div style={{ marginLeft: "20px" }}>
            <Typography component="div" variant="body1" className="data-item">
              <strong>Office Locations:</strong>
              {member?.officeLocations?.length > 0
                ? member.officeLocations.map((office, index) => (
                    <div key={index}>
                      <Typography
                        component="div"
                        variant="body2"
                        style={{
                          fontWeight: "bold",
                          padding: "0",
                          margin: "0",
                        }}
                      >
                        {office.officeName}
                      </Typography>
                      <Typography
                        component="div"
                        variant="body2"
                        style={{ marginLeft: "16px" }}
                      >
                        {office.location}
                      </Typography>
                    </div>
                  ))
                : "Not Entered"}
            </Typography>
            <Typography component="div" variant="body1" className="data-item">
              <strong>Available Days:</strong>{" "}
              {member?.availability?.days?.length > 0
                ? member.availability.days.join(", ")
                : "Not Entered"}
            </Typography>
            <Typography component="div" variant="body1" className="data-item">
              <strong>Available Time Slots:</strong>{" "}
              {member?.availability?.timeSlots?.length > 0
                ? member.availability.timeSlots.map((slot, index) => (
                    <div key={index}>
                      {slot.startTime} - {slot.endTime}
                    </div>
                  ))
                : "Not Entered"}
            </Typography>
            <Typography component="div" variant="body1" className="data-item">
              <strong>Unavailability (in general):</strong>
              {member?.unavailability?.length > 0
                ? member?.unavailability?.map((item, index) => (
                    <div key={index}>
                      <Typography
                        component="div"
                        variant="body2"
                        style={{ fontWeight: "bold", marginTop: "5px" }}
                      >
                        {item?.day}
                      </Typography>
                      {item?.timeSlots?.length > 0 ? (
                        item?.timeSlots?.map((slot, slotIndex) => (
                          <Typography
                            key={slotIndex}
                            component="div"
                            variant="body2"
                            style={{ marginLeft: "16px" }}
                          >
                            {slot?.startTime} - {slot?.endTime}
                          </Typography>
                        ))
                      ) : (
                        <Typography
                          component="div"
                          variant="body2"
                          style={{ marginLeft: "16px" }}
                        >
                          No specific time slots
                        </Typography>
                      )}
                    </div>
                  ))
                : "No Unavailability Entered"}
            </Typography>
            <Typography component="div" variant="body1" className="data-item">
              <strong>Unavailable Periods (vacation, etc):</strong>{" "}
              {member?.unavailablePeriods?.length > 0
                ? member.unavailablePeriods?.map((period, index) => (
                    <div key={index}>
                      {formatDateToDayMonthYear(period?.startDate)} -{" "}
                      {formatDateToDayMonthYear(period?.endDate)}
                    </div>
                  ))
                : "No Unavailable Periods"}
            </Typography>
          </div>
        </Grid>
        {/* Links */}
        {((member?.socialMediaLinks && member?.socialMediaLinks?.length > 0) ||
          (member?.otherLinks && member?.otherLinks.length > 0)) && (
          <Grid item xs={12} className="data-section">
            <Typography component="div" variant="h6" className="data-header">
              Links
            </Typography>
            <div style={{ marginLeft: "20px" }}>
              {member?.socialMediaLinks &&
                member?.socialMediaLinks?.length > 0 &&
                renderLinks(member?.socialMediaLinks, "Social Media")}

              {member?.otherLinks &&
                member?.otherLinks?.length > 0 &&
                renderLinks(member?.otherLinks, "Other")}
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default MemberDataTab;
