import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const postApi = createApi({
  reducerPath: "post",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/posts/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Post"],
  endpoints: (builder) => ({
    getRealtorTeamIdByUserId: builder.query({
      query: (userId) => ({
        url: `get-realtor-team-id/${userId}`,
        method: "GET",
      }),
      skip: (userId) => !userId,
    }),
    downloadAttachment: builder.query({
      query: ({ attachmentId, disposition }) =>
        `download-attachment/${attachmentId}?disposition=${disposition}`,
      skip: (attachmentId, disposition) => !attachmentId || !disposition,
    }),
    useLazyDownloadAttachmentQuery: builder.query({
      query: ({ attachmentId, disposition }) => ({
        url: `download-attachment/${attachmentId}?disposition=${disposition}`,
        method: "GET",
      }),
      skip: (attachmentId, disposition) => !attachmentId || !disposition,
    }),
    downloadVideo: builder.query({
      query: ({ videoId }) => `download-video/${videoId}`,
      skip: (videoId) => !videoId,
    }),
    useLazyDownloadVideoQuery: builder.query({
      query: ({ videoId }) => ({
        url: `download-video/${videoId}`,
        method: "GET",
      }),
      skip: (videoId) => !videoId,
    }),
    downloadPhotograph: builder.query({
      query: ({ photographId, disposition }) =>
        `download-photograph/${photographId}?disposition=${disposition}`,
      skip: (photographId, disposition) => !photographId || !disposition,
    }),
    useLazyDownloadPhotographQuery: builder.query({
      query: ({ photographId, disposition }) => ({
        url: `download-photograph/${photographId}?disposition=${disposition}`,
        method: "GET",
      }),
      skip: (photographId, disposition) => !photographId || !disposition,
    }),
    downloadImage: builder.query({
      query: ({ imageUrl, disposition }) =>
        `download-image/${encodeURIComponent(
          imageUrl
        )}?disposition=${disposition}`,
      skip: (imageUrl, disposition) => !imageUrl || !disposition,
    }),
    useLazyDownloadImageQuery: builder.query({
      query: ({ imageUrl, disposition }) => ({
        url: `download-image/${encodeURIComponent(
          imageUrl
        )}?disposition=${disposition}`,
        method: "GET",
      }),
      skip: (imageUrl, disposition) => !imageUrl || !disposition,
    }),
    makePost: builder.mutation({
      query: ({ tierId, userId, formData }) => {
        return {
          url: `create-post/${tierId}/${userId}`,
          method: "POST",
          body: formData,
        };
      },
      onQueryStarted: async ({ userId }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.post.author._id !== userId) {
            dispatch(postApi.util.invalidateTags(["Post"]));
          }
        } catch (error) {
          dispatch(postApi.util.invalidateTags(["Post"]));
        }
      },
    }),
    editPost: builder.mutation({
      query: ({ tierId, postId, formData }) => ({
        url: `edit-post/${postId}/${tierId}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Post"],
    }),
    likePost: builder.mutation({
      query: ({ tierId, postId, userId }) => ({
        url: `like-post/${postId}/${userId}/${tierId}`,
        method: "POST",
      }),
      invalidatesTags: ["Post"],
    }),
    unlikePost: builder.mutation({
      query: ({ postId, userId }) => ({
        url: `unlike-post/${postId}/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["Post"],
    }),
    getAllPostsByTierOrCategory: builder.query({
      query: ({
        userId,
        tierId,
        categoryId,
        sort,
        page = 1,
        limit = 10,
        shouldPaginate,
        eventTypeId,
      }) => {
        const queryParams = `page=${page}&limit=${limit}`;
        return {
          url: `all-posts-by-tier/${tierId}?${queryParams}`,
          method: "GET",
          params: { categoryId, sort, shouldPaginate, eventTypeId },
        };
      },
      skip: (tierId, userId, categoryPath) =>
        !tierId ||
        tierId === "undefined" ||
        tierId === null ||
        !userId ||
        categoryPath === "chat",
      providesTags: (result) =>
        result ? [{ type: "Post", id: "LIST" }] : ["Post"],
    }),
    getAllPosts: builder.query({
      query: ({ page = 1, limit = 10 }) => {
        return {
          url: `all-posts?page=${page}&limit=${limit}`,
          method: "GET",
        };
      },
      skip: (userId, accountType) => !userId || accountType !== "reosadmin",
      providesTags: (result) =>
        result ? [{ type: "Post", id: "LIST" }] : ["Post"],
    }),
    getNewPostsAmountByCategory: builder.query({
      query: ({ categoryId }) => {
        return {
          url: `new-posts-amount-by-category/${categoryId}`,
          method: "GET",
        };
      },
      skip: (categoryId) => !categoryId,
      providesTags: (result) =>
        result ? [{ type: "PostBadge", id: "LIST" }] : ["PostBadge"],
    }),
    getNewPostsAmountByTier: builder.query({
      query: (userId) => {
        return {
          url: `new-posts-amount-by-tier`,
          method: "GET",
        };
      },
      skip: (userId) => !userId,
      providesTags: (result) =>
        result ? [{ type: "PostTierBadge", id: "LIST" }] : ["PostTierBadge"],
    }),
    getPostById: builder.query({
      query: (postId) => {
        return {
          url: `get-by-id/${postId}`,
          method: "GET",
        };
      },
      skip: (postId) => !postId,
      providesTags: (result) =>
        result ? [{ type: "Post", id: "LIST" }] : ["Post"],
    }),
    commentOnPost: builder.mutation({
      query: ({ tierId, userId, postId, content }) => ({
        url: `comment-on-post/${userId}/${postId}/${tierId}`,
        method: "POST",
        body: { content },
      }),
      invalidatesTags: ["Post"],
    }),
    likeComment: builder.mutation({
      query: ({ tierId, commentId, userId }) => ({
        url: `like-comment/${commentId}/${userId}/${tierId}`,
        method: "POST",
      }),
      invalidatesTags: ["Post"],
    }),
    unlikeComment: builder.mutation({
      query: ({ commentId, userId }) => ({
        url: `unlike-comment/${commentId}/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["Post"],
    }),
    editComment: builder.mutation({
      query: ({ commentId, content }) => ({
        url: `edit-comment/${commentId}`,
        method: "POST",
        body: { content },
      }),
      invalidatesTags: ["Post"],
    }),
    deleteComment: builder.mutation({
      query: ({ commentId }) => ({
        url: `delete-comment/${commentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Post"],
    }),
    replyToComment: builder.mutation({
      query: ({ tierId, userId, commentId, content }) => ({
        url: `reply-to-comment/${userId}/${commentId}/${tierId}`,
        method: "POST",
        body: { content },
      }),
      invalidatesTags: ["Post"],
    }),
    likeReply: builder.mutation({
      query: ({ tierId, replyId, userId }) => ({
        url: `like-reply/${replyId}/${userId}/${tierId}`,
        method: "POST",
      }),
      invalidatesTags: ["Post"],
    }),
    unlikeReply: builder.mutation({
      query: ({ replyId, userId }) => ({
        url: `unlike-reply/${replyId}/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["Post"],
    }),
    editReply: builder.mutation({
      query: ({ replyId, content }) => ({
        url: `edit-reply/${replyId}`,
        method: "POST",
        body: { content },
      }),
      invalidatesTags: ["Post"],
    }),
    attendEvent: builder.mutation({
      query: ({ postId, userId }) => ({
        url: `attend-event/${postId}/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["Post"],
    }),
    unattendEvent: builder.mutation({
      query: ({ postId, userId }) => ({
        url: `unattend-event/${postId}/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["Post"],
    }),
    acknowledgeAnnouncement: builder.mutation({
      query: ({ postId, userId }) => ({
        url: `acknowledge-announcement/${postId}/${userId}`,
        method: "POST",
      }),
      invalidatesTags: ["Post"],
    }),
    getAllUnacknowledgedAnnouncements: builder.query({
      query: (userId) => ({
        url: `get-all-unacknowledged-announcements`,
        method: "GET",
      }),
      skip: (userId) => !userId,
      providesTags: (result) =>
        result ? [{ type: "Post", id: "LIST" }] : ["Post"],
    }),
    getUnacknowledgedUsersByPost: builder.query({
      query: (postId) => ({
        url: `get-unacknowledged-members-by-post/${postId}`,
        method: "GET",
      }),
      skip: (postId) => !postId,
      providesTags: (result) =>
        result ? [{ type: "Post", id: "LIST" }] : ["Post"],
    }),
    deletePost: builder.mutation({
      query: ({ postId, tierId }) => ({
        url: `delete-post/${postId}/${tierId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Post"],
    }),
    deleteAllRecurringPosts: builder.mutation({
      query: ({ postId, tierId }) => ({
        url: `delete-all-recurring-event-posts/${postId}/${tierId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Post"],
    }),
  }),
});

export const {
  useMakePostMutation,
  useCommentOnPostMutation,
  useDownloadAttachmentQuery,
  useLazyDownloadAttachmentQuery,
  useDownloadVideoQuery,
  useLazyDownloadVideoQuery,
  useDownloadPhotographQuery,
  useLazyDownloadPhotographQuery,
  useDownloadImageQuery,
  useLazyDownloadImageQuery,
  useGetAllPostsQuery,
  useGetAllPostsByTierOrCategoryQuery,
  useGetNewPostsAmountByCategoryQuery,
  useGetNewPostsAmountByTierQuery,
  useGetPostByIdQuery,
  useLikePostMutation,
  useUnlikePostMutation,
  useEditPostMutation,
  useEditCommentMutation,
  useDeleteCommentMutation,
  useLikeCommentMutation,
  useUnlikeCommentMutation,
  useReplyToCommentMutation,
  useLikeReplyMutation,
  useUnlikeReplyMutation,
  useEditReplyMutation,
  useDeletePostMutation,
  useAttendEventMutation,
  useUnattendEventMutation,
  useAcknowledgeAnnouncementMutation,
  useGetAllUnacknowledgedAnnouncementsQuery,
  useGetUnacknowledgedUsersByPostQuery,
  useGetRealtorTeamIdByUserIdQuery,
  useDeleteAllRecurringPostsMutation,
} = postApi;

export default postApi;
export { postApi };
