import React, { useState, useContext } from "react";
import { useGetAllUserRoleNameEnumsQuery } from "../../../services/user-role-name-enum";
import { AuthContext } from "../../../context/AuthContext";
import {
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  Divider,
  Grid,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import RoleNameEnumsList from "./RoleNameEnumsList";
import CreateEditUserRoleNameEnumForm from "./CreateEditUserRoleNameEnumForm";

const RoleNameEnumManager = () => {
  const { accountType, userId } = useContext(AuthContext);
  const { data: userRoleNameEnums } = useGetAllUserRoleNameEnumsQuery(
    accountType,
    {
      skip: !userId && accountType !== "reosadmin",
    }
  );
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [openCreateRoleNameEnumDialog, setOpenCreateRoleNameEnumDialog] =
    useState(false);

  const handleOpenCreateRoleNameEnumDialog = () => {
    setOpenCreateRoleNameEnumDialog(true);
  };

  const handleCloseCreateRoleNameEnumDialog = () => {
    setOpenCreateRoleNameEnumDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (accountType !== "reosadmin") {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        You are not allowed access to this page
      </Typography>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Role Role Name Enum Management
        </Typography>
        <Divider />
        <Grid container>
          <Grid item xs={6} sx={{ p: 2 }}>
            <List style={{ width: "100%", marginRight: "0.1rem" }}>
              <ListItem
                onClick={handleOpenCreateRoleNameEnumDialog}
                className="user-management-item"
              >
                Create new Role Name Enum
              </ListItem>
              <Divider />
            </List>
          </Grid>
        </Grid>
        <RoleNameEnumsList userRoleNameEnums={userRoleNameEnums} />
        <CreateEditUserRoleNameEnumForm
          mode="create"
          open={openCreateRoleNameEnumDialog}
          onClose={handleCloseCreateRoleNameEnumDialog}
        />
        <NotificationSnackbar
          open={notification.open}
          message={notification.message}
          onClose={handleClose}
        />
      </Paper>
    </Container>
  );
};

export default RoleNameEnumManager;
