import React, { memo, useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import {
  useGetDutySchedulingByDutyScheduleIdQuery,
  useUpdateDutyByDnDMutation,
  useCreateDutyByDnDMutation,
} from "../../../../services/duty-scheduling";
import { useGetDutyScheduleByIdQuery } from "../../../../services/duty-schedule";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import CustomEvent from "./CustomEvent";
import SelectedDutyDialog from "./SelectedDutyDialog";
import DutySchedulingMembersList from "./DutySchedulingMembersList";
import CreateDutyByDnDConfirmationDialog from "./CreateDutyByDnDConfirmationDialog";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../../../../src/components/brokerage/duty-scheduling/duty-scheduling-handling/custom-calendar-styles.css"; // Add this import for custom styles

import dayjs from "dayjs";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const DragAndDropCalendar = withDragAndDrop(Calendar);

const CategoryFloorDutyScheduling = ({ tierId, dutyScheduleId }) => {
  const { userRole } = useContext(AuthContext);
  const { data: events = [], refetch } =
    useGetDutySchedulingByDutyScheduleIdQuery(dutyScheduleId, {
      skip: !dutyScheduleId,
    });
  const { data: dutySchedule } = useGetDutyScheduleByIdQuery(dutyScheduleId, {
    skip: !dutyScheduleId,
  });

  const [updateDutyByDnD] = useUpdateDutyByDnDMutation();
  const [createDutyByDnD] = useCreateDutyByDnDMutation();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [updatedDay, setUpdatedDay] = useState(null);
  const [parsedMemberData, setParsedMemberData] = useState(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [dropStart, setDropStart] = useState(null);
  const [dropEnd, setDropEnd] = useState(null);
  const [daysEvents, setDaysEvents] = useState([]);
  const [dutyConfirmationDialogOpen, setDutyConfirmationDialogOpen] =
    useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const isAdminRole = [
    "reosadmin",
    "reossupportadmin",
    "reossuperadmin",
    "brokeragesuperadmin",
    "brokerageadmin",
    "teamsuperadmin",
  ].includes(userRole);

  const [eventList, setEventList] = useState(
    events && events.length > 0
      ? events.map((event) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
        }))
      : []
  );

  const handleEventDrop = async ({ event, start, end }) => {
    try {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      if (start <= today || end <= today) {
        setNotification({
          open: true,
          message: "You cannot move or swap duties to the past or today.",
        });
        return;
      }

      const updatedDutyData = {
        start: start.toISOString(),
        end: end.toISOString(),
      };

      const result = await updateDutyByDnD({
        dutyId: event._id,
        updatedDutyData,
      });

      if (result.data) {
        setEventList((prevEventList) =>
          prevEventList.map((e) =>
            e._id === event._id
              ? { ...event, start: new Date(start), end: new Date(end) }
              : e
          )
        );

        setNotification({
          open: true,
          message: `${result?.data?.msg}.`,
        });
      } else {
        setNotification({
          open: true,
          message: `Failed to update the duty, error: ${result?.error?.data?.msg}`,
        });
      }
    } catch (error) {
      console.error("Error updating duties:", error);
      setNotification({
        open: true,
        message: `Failed to update the duty, error: ${error.message}`,
      });
    }
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };

  const handleCloseDialog = () => {
    setSelectedEvent(null);
  };

  const handleExternalDrop = async ({ start, end }) => {
    const memberData = JSON.parse(event.dataTransfer.getData("member"));
    if (!memberData) {
      setNotification({
        open: true,
        message: "Member Data is missing.",
      });
      return;
    }

    if (!memberData?._id || !dutyScheduleId) {
      setNotification({
        open: true,
        message: "Duty Schedule or Member ID is missing.",
      });
      return;
    }

    const newUpdatedDay = start.toLocaleDateString("en-US", {
      weekday: "long",
    });
    const newAvailableTimeSlots =
      dutySchedule?.dailyTimeSlots?.[newUpdatedDay] || [];

    if (newAvailableTimeSlots.length === 0) {
      setNotification({
        open: true,
        message: `No available time slots for ${newUpdatedDay}.`,
      });
      return;
    }
    // get all events for the day
    const dayEvents = eventList.filter(
      (event) => event.start.toDateString() === start.toDateString()
    );
    setDaysEvents(dayEvents);
    setParsedMemberData(memberData);
    setUpdatedDay(newUpdatedDay);
    setAvailableTimeSlots(newAvailableTimeSlots);
    setDropStart(start);
    setDropEnd(end);
    setDutyConfirmationDialogOpen(true);
  };

  const handleConfirm = async (selectedTimeSlot, selectedDutyType) => {
    if (!selectedTimeSlot || !selectedDutyType) {
      setNotification({
        open: true,
        message: "Please select a valid time slot and duty type.",
      });
      return;
    }
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [startTime, endTime] = selectedTimeSlot?.split(" - ");

    // Parse off AM/PM from start and end time
    const startTimeParts = startTime?.match(/(\d+:\d+)\s*(AM|PM)/i);
    const endTimeParts = endTime?.match(/(\d+:\d+)\s*(AM|PM)/i);

    if (!startTimeParts || !endTimeParts) {
      console.error("Invalid time format: ", startTime, endTime);
      setNotification({
        open: true,
        message: "Invalid time format. Please select a valid time slot.",
      });
      return;
    }

    const [, startTimeWithoutAMPM, startAMPM] = startTimeParts;
    const [, endTimeWithoutAMPM, endAMPM] = endTimeParts;

    let startHours = parseInt(startTime.split(":")[0], 10);
    const startMinutes = parseInt(startTime.split(":")[1], 10);
    let endHours = parseInt(endTime.split(":")[0], 10);
    const endMinutes = parseInt(endTime.split(":")[1], 10);

    if (startAMPM === "PM" && startHours !== 12) {
      startHours += 12;
    }
    if (endAMPM === "PM" && endHours !== 12) {
      endHours += 12;
    }

    const startDateTime = new Date(dropStart);
    startDateTime.setHours(startHours, startMinutes, 0, 0);

    const endDateTime = new Date(dropStart);
    endDateTime.setHours(endHours, endMinutes, 0, 0);

    const startUTC = dayjs(startDateTime)
      .tz(currentTimeZone)
      .utc()
      .toISOString();
    const endUTC = dayjs(endDateTime).tz(currentTimeZone).utc().toISOString();

    // Check if the slot is already assigned
    const existingEvent =
      eventList.find((event) => {
        const eventStart = new Date(event.start);
        const eventEnd = new Date(event.end);
        return (
          eventStart.getDate() === startDateTime.getDate() &&
          eventStart.getMonth() === startDateTime.getMonth() &&
          eventStart.getFullYear() === startDateTime.getFullYear() &&
          ((startDateTime >= eventStart && startDateTime < eventEnd) ||
            (endDateTime > eventStart && endDateTime <= eventEnd) ||
            (startDateTime <= eventStart && endDateTime >= eventEnd))
        );
      }) || null;

    const dutyData = {
      start: startUTC,
      end: endUTC,
      dutyType: selectedDutyType,
      title: `${parsedMemberData?.user?.firstName} ${parsedMemberData?.user?.lastName} (${selectedDutyType})`,
      description: existingEvent ? `Updated via DnD` : `Assigned via DnD`,
      externalDrop: true,
    };
    let result;

    if (existingEvent) {
      result = await updateDutyByDnD({
        dutyId: existingEvent._id,
        memberId: parsedMemberData?._id,
        updatedDutyData: dutyData,
      });
    } else {
      result = await createDutyByDnD({
        dutyScheduleId,
        memberId: parsedMemberData?._id,
        dutyData,
      });
    }

    if (result.data) {
      setEventList((prevEventList) => {
        const newDuty = {
          ...dutyData,
          start: new Date(dutyData.start),
          end: new Date(dutyData.end),
          member: parsedMemberData,
          _id: result?.data?._id,
        };
        return [...prevEventList, newDuty];
      });
      setNotification({
        open: true,
        message: `${result?.data?.msg}.`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed to create the duty, error: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  useEffect(() => {
    if (events && events.length > 0) {
      setEventList(
        events.map((event) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
          dutyType: event.dutyType,
        }))
      );
    } else {
      setEventList([]);
    }
  }, [events]);

  const minDate =
    eventList && eventList.length
      ? new Date(Math.min(...eventList.map((e) => new Date(e.start))))
      : new Date();

  if (!isAdminRole) {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        You are not allowed access to this page
      </Typography>
    );
  }

  return (
    <>
      <div style={{ height: "auto", width: "100%", display: "flex" }}>
        <div style={{ flex: 3, marginRight: "20px" }}>
          <DragAndDropCalendar
            className="custom-calendar"
            localizer={localizer}
            events={eventList}
            startAccessor="start"
            endAccessor="end"
            defaultDate={new Date()}
            min={minDate}
            components={{
              event: CustomEvent,
            }}
            style={{
              height: "80vh",
              width: "100%",
              border: "1px solid darkgray",
              borderRadius: "4px",
              backgroundColor: "var(--calendar-bg-color)",
              color: "var(--calendar-text-color)",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
            onEventDrop={handleEventDrop}
            onDropFromOutside={handleExternalDrop}
            onSelectEvent={handleSelectEvent}
            resizable
            eventPropGetter={(event) => ({
              style: {
                backgroundColor:
                  event.dutyType === "Office"
                    ? "var(--color-office-duty)"
                    : "var(--color-other-duty)",
                color: "#fff",
                borderRadius: "4px",
                border: "1px solid rgba(0, 0, 0, 0.2)",
                padding: "1px 4px",
                margin: "1px 1px",
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                fontWeight: "bold",
                textTransform: "capitalize",
                fontSize: "0.7rem",
                fontWeight: "bold",
                textWrap: "wrap",
              },
            })}
            dayPropGetter={(date) => ({
              style: {
                backgroundColor:
                  date.getDay() === 0 || date.getDay() === 6
                    ? "var(--weekend-bg-color)"
                    : "var(--weekday-bg-color)",
                borderRadius: "4px",
                // fontSize: "1rem",
                color: "var(--calendar-day-text-color)",
                fontWeight: "bold",
                textWrap: "wrap",
              },
            })}
            toolbar={(toolbarProps) => (
              <div className="rbc-toolbar">
                <span className="rbc-btn-group">
                  <button onClick={() => toolbarProps.onNavigate("PREV")}>
                    Back
                  </button>
                  <button onClick={() => toolbarProps.onNavigate("TODAY")}>
                    Today
                  </button>
                  <button onClick={() => toolbarProps.onNavigate("NEXT")}>
                    Next
                  </button>
                </span>
                <span className="rbc-toolbar-label">{toolbarProps.label}</span>
                <span className="rbc-btn-group">
                  {["month", "week", "day"].map((view) => (
                    <button
                      key={view}
                      onClick={() => toolbarProps.onView(view)}
                      className={toolbarProps.view === view ? "rbc-active" : ""}
                    >
                      {view}
                    </button>
                  ))}
                </span>
              </div>
            )}
            messages={{
              week: "Week",
              work_week: "Work Week",
              day: "Day",
              month: "Month",
              previous: "Back",
              next: "Next",
              today: "Today",
              agenda: "Agenda",
            }}
            step={30}
            timeslots={2}
          />
        </div>
        <div style={{ flex: 1 }}>
          <DutySchedulingMembersList tierId={tierId} />
        </div>
      </div>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
        notificationDuration={3000}
      />
      <SelectedDutyDialog
        selectedEvent={selectedEvent}
        handleCloseDialog={handleCloseDialog}
        refetch={refetch}
      />
      <CreateDutyByDnDConfirmationDialog
        open={dutyConfirmationDialogOpen}
        onClose={() => setDutyConfirmationDialogOpen(false)}
        onConfirm={handleConfirm}
        day={updatedDay}
        daysEvents={daysEvents}
        memberData={parsedMemberData}
        timeSlots={availableTimeSlots.map((slot) => slot.timeSlot)}
        dutyTypes={dutySchedule?.dutyScheduleType || []}
      />
    </>
  );
};

export default memo(CategoryFloorDutyScheduling);
