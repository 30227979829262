import React, { useContext, useEffect, useState } from "react";
import {
  useGetAllDutyChangesQuery,
  useGetAllDutyChangesByTierQuery,
  useClearAllDutyChangesLogMutation,
  useClearAllDutyChangesLogByTierMutation,
} from "../../../services/duty-schedule";
import { useGetAllTiersQuery } from "../../../services/tier";
import { AuthContext } from "../../../context/AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Box,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import { ConfirmationDialog } from "../..";

function DutiesChangesList() {
  const { accountType, userId } = useContext(AuthContext);
  const { data: tiers } = useGetAllTiersQuery(accountType, {
    skip: !userId && accountType !== "reosadmin",
  });
  const [selectedTier, setSelectedTier] = useState(null);
  const [allLogs, setAllLogs] = useState([]);
  const { data: dutyChanges } = useGetAllDutyChangesQuery();
  const { data: tierDutyChanges } = useGetAllDutyChangesByTierQuery(
    selectedTier?._id,
    {
      skip: !selectedTier?._id,
    }
  );
  const [clearAllDutyChangesLog] = useClearAllDutyChangesLogMutation();
  const [clearAllDutyChangesLogByTier] =
    useClearAllDutyChangesLogByTierMutation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [deleteAction, setDeleteAction] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleClearDutyChangesClick = (action) => {
    setDeleteAction(action);
    setIsConfirmDialogOpen(true);
  };

  const handleClearAllDutyChanges = async () => {
    const result = await clearAllDutyChangesLog();
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setIsConfirmDialogOpen(false);
    } else {
      setNotification({
        open: true,
        message: `Error clearing changes. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClearDutyChangesByTier = async () => {
    if (selectedTier) {
      const result = await clearAllDutyChangesLogByTier(selectedTier?._id);
      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        setIsConfirmDialogOpen(false);
      } else {
        setNotification({
          open: true,
          message: `Error clearing changes. ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleConfirmDelete = async () => {
    if (deleteAction === "all") {
      await handleClearAllDutyChanges();
    } else if (deleteAction === "byTier") {
      await handleClearDutyChangesByTier();
    }
    setDeleteAction(null);
    setIsConfirmDialogOpen(false);
  };

  useEffect(() => {
    if (selectedTier && tierDutyChanges) {
      setAllLogs(tierDutyChanges);
    } else if (!selectedTier && dutyChanges) {
      setAllLogs(dutyChanges);
    }
  }, [selectedTier, dutyChanges, tierDutyChanges]);

  return (
    <div className="management-page-body">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClearDutyChangesClick("all")}
            disabled={!dutyChanges || dutyChanges?.length === 0}
          >
            Clear All Duty Changes
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleClearDutyChangesClick("byTier")}
            disabled={!selectedTier}
          >
            Clear Duty Changes by Tier
          </Button>
        </Box>
      </Paper>
      <Box>
        <Autocomplete
          value={selectedTier}
          onChange={(event, newValue) => {
            setSelectedTier(newValue);
          }}
          options={tiers || []}
          getOptionLabel={(option) =>
            option
              ? `${option?.level} ${
                  option?.brokerage || option?.team || option?.group
                    ? option?.brokerage?.officeName ||
                      option?.team?.name ||
                      option?.group?.name
                    : ""
                } ${
                  option?.level === "system" || option?.level === "global"
                    ? " - REACirc"
                    : ""
                } ${
                  option?._id === option?.brokerage?.leadBrokerageTier
                    ? " - (Lead Brokerage Office)"
                    : ""
                } ${
                  option?._id === option?.brokerage?.tier ? " - (Office)" : ""
                }`
              : ""
          }
          isOptionEqualToValue={(option, value) => option?._id === value._id}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a Tier"
              variant="outlined"
              margin="normal"
              required
            />
          )}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Name</TableCell>
              <TableCell>Action Description</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allLogs &&
              allLogs.length > 0 &&
              allLogs?.map((log, index) => (
                <TableRow key={`${log._id}-${index}`}>
                  <TableCell>{`${log.user?.firstName} ${log.user?.lastName}`}</TableCell>
                  <TableCell>
                    {log.changes
                      .map((change) => change.description)
                      .join("<br>")}
                  </TableCell>
                  <TableCell>
                    {new Date(log.createdAt).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {(!dutyChanges || dutyChanges?.length === 0) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 20,
          }}
        >
          <Typography variant="h5" gutterBottom>
            No Duty Changes Logs available
          </Typography>
        </Box>
      )}
      <Divider style={{ margin: "20px 0" }} />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={() => {
          setIsConfirmDialogOpen(false);
          setDeleteAction(null);
        }}
        onConfirm={handleConfirmDelete}
        title={`Confirm Clearing of ${
          deleteAction === "all" ? "All Duty Changes" : "Duty Changes by Tier"
        }`}
        description={`Are you sure you want to clear ${
          deleteAction === "all"
            ? "all Duty Schedule Changes?"
            : "all Duty Schedule Changes for the selected tier?"
        } This action cannot be undone.`}
      />
    </div>
  );
}

export default DutiesChangesList;
