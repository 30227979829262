export const allTierPermissions = [
  "can-chat",
  "can-post",
  "can-comment",
  "can-reply",
  "can-like",
  "is-full-member",
  "can-add-listing",
  "can-add-listings-to-listing-tour",
  "can-remove-listings-from-listing-tour",
];
export const defaultPermissions = [
  "can-chat",
  "can-post",
  "can-comment",
  "can-reply",
  "can-like",
  "is-full-member",
];
export const defaultViewerPermissions = [
  "can-post",
  "can-comment",
  "can-reply",
  "can-like",
];
