import React, { useContext, memo } from "react";
import { AppBar, Toolbar } from "@mui/material";
import { SidebarContext } from "../../../context/SidebarContext";
import { BackButton } from "../..";

const ListingPageNavbar = () => {
  const { sidebarOpen } = useContext(SidebarContext);
  const postPageNavbarClass = `app-bar ${
    !sidebarOpen ? "sidebar-closed" : "sidebar-open"
  }`;

  return (
    <AppBar className={postPageNavbarClass}>
      <Toolbar className={postPageNavbarClass}>
        <BackButton />
      </Toolbar>
    </AppBar>
  );
};

export default memo(ListingPageNavbar);
