import React, { useState } from "react";
import {
  useAddListingsToListingTourMutation,
  useRemoveListingsFromListingTourMutation,
} from "../../../services/listings-on-the-tours";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const AddListingsToListingTOurDialog = ({
  open,
  onClose,
  tierId,
  listingIds,
  listingTours = [],
  refetch,
  mode = "add",
  listingTourId,
}) => {
  const [addListingsToListingTour, { isLoading: isAdding }] =
    useAddListingsToListingTourMutation();
  const [removeListingsFromListingTour, { isLoading: isRemoving }] =
    useRemoveListingsFromListingTourMutation();

  const [selectedListingTour, setSelectedListingTour] = useState(
    mode === "remove" && listingTourId ? listingTourId : ""
  );
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const isProcessing = isAdding || isRemoving;

  const handleChange = (e) => {
    setSelectedListingTour(e.target.value);
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    if (!listingIds?.length || !tierId) {
      setNotification({
        open: true,
        message: "Some data are missing.",
      });
      return;
    }

    let selectedTourId = selectedListingTour;
    if (mode === "remove" && listingTourId) {
      selectedTourId = listingTourId;
    }

    if (!selectedTourId?.trim()) {
      setNotification({
        open: true,
        message: "Listing Tour is required.",
      });
      return;
    }

    let result;
    if (mode === "add") {
      result = await addListingsToListingTour({
        tierId,
        listingTourId: selectedTourId,
        listingIds,
      });
    } else if (mode === "remove") {
      result = await removeListingsFromListingTour({
        tierId,
        listingTourId: selectedTourId,
        listingIds,
      });
    }

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        setSelectedListingTour("");
        onClose();
        refetch?.();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error ${mode === "add" ? "adding" : "removing"} listings: ${
          result?.error?.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ backgroundColor: "#1976d2", color: "white" }}>
        {mode === "add"
          ? `Add ${listingIds?.length || 0} Selected Listings To Listing Tour`
          : `Remove ${
              listingIds?.length || 0
            } Selected Listings From Listing Tour`}
      </DialogTitle>
      <DialogContent>
        {mode === "add" && (
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel id="listingTour-select-label">Listing Tour</InputLabel>
            <Select
              labelId="listingTour-select-label"
              id="listingTour-select"
              label="Listing Tour"
              name="listingTour"
              value={selectedListingTour || ""}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>No Tour | Not for Tour</em>
              </MenuItem>
              {listingTours?.map((tour) => (
                <MenuItem key={tour?._id} value={tour?._id}>
                  {`${tour?.listingTourTitle} - ${new Date(
                    tour?.listingTourDate
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {mode === "remove" && (
          <Typography variant="body1" sx={{ mt: 2 }}>
            {`Removing from Listing Tour: ${
              listingTours.find((tour) => tour._id === listingTourId)
                ?.listingTourTitle || ""
            }`}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          disabled={isProcessing}
        >
          {mode === "add" ? "Add to Tour" : "Remove from Tour"}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AddListingsToListingTOurDialog;
