import React, { useState, useContext } from "react";
import {
  useGetAllRolePermissionEnumsQuery,
  useGetRolePermissionEnumsByUserRoleNameQuery,
  useCreateInitialRolePermissionsEnumsMutation,
  useSyncRolePermissionsEnumsMutation,
} from "../../../services/role-permission-enum";
import { useGetAllUserRoleNameEnumsQuery } from "../../../services/user-role-name-enum";
import { AuthContext } from "../../../context/AuthContext";
import {
  Container,
  Typography,
  Paper,
  Divider,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import { Add, LibraryAdd, Delete, Sync } from "@mui/icons-material/";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import RolePermissionEnumsList from "./RolePermissionEnumsList";
import CreateEditRolePermissionEnumForm from "./CreateEditRolePermissionEnumForm";
import ConfirmationDialog from "../../helpers/ConfirmationDialog";
import DeleteRolePermissionsDialog from "./DeleteRolePermissionsDialog";
import SyncResultsDialog from "./SyncResultsDialog";

const RolePermissionManager = () => {
  const { accountType } = useContext(AuthContext);
  const [selectedRoleId, setSelectedRoleId] = useState("all");
  const { data: userRoleNameEnums, isLoading: isLoadingRoleNameEnums } =
    useGetAllUserRoleNameEnumsQuery();
  const {
    data: rolePermissionEnums,
    refetch,
    isLoading: isLoadingPermissions,
  } = selectedRoleId === "all"
    ? useGetAllRolePermissionEnumsQuery()
    : useGetRolePermissionEnumsByUserRoleNameQuery(selectedRoleId);
  const [createInitialRolePermissionsEnums, { isLoading: isCreating }] =
    useCreateInitialRolePermissionsEnumsMutation();
  const [syncRolePermissionsEnums, { isLoading: isSyncing }] =
    useSyncRolePermissionsEnumsMutation();

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [openCreateRolePermissionDialog, setOpenCreateRolePermissionDialog] =
    useState(false);
  const [
    openCreateInitialRolePermissionsEnumsDialog,
    setOpenCreateInitialRolePermissionsEnumsDialog,
  ] = useState(false);
  const [
    openSyncRolePermissionsEnumsDialog,
    setOpenSyncRolePermissionsEnumsDialog,
  ] = useState(false);
  const [openDeleteRolePermissionsDialog, setOpenDeleteRolePermissionsDialog] =
    useState(false);
  const [openSyncResultsDialog, setOpenSyncResultsDialog] = useState(false);
  const [syncResults, setSyncResults] = useState(null);

  const handleOpenCreateRolePermissionDialog = () => {
    setOpenCreateRolePermissionDialog(true);
  };

  const handleCloseCreateRolePermissionDialog = () => {
    setOpenCreateRolePermissionDialog(false);
  };

  const handleOpenCreateInitialRolePermissionsEnumsDialog = () => {
    setOpenCreateInitialRolePermissionsEnumsDialog(true);
  };

  const handleCloseCreateInitialRolePermissionsEnumsDialog = () => {
    setOpenCreateInitialRolePermissionsEnumsDialog(false);
  };

  const handleOpenDeleteRolePermissionsDialog = () => {
    setOpenDeleteRolePermissionsDialog(true);
  };

  const handleCloseDeleteRolePermissionsDialog = () => {
    setOpenDeleteRolePermissionsDialog(false);
  };

  const handleOpenSyncRolePermissionsEnumsDialog = () => {
    setOpenSyncRolePermissionsEnumsDialog(true);
  };

  const handleCloseSyncRolePermissionsEnumsDialog = () => {
    setOpenSyncRolePermissionsEnumsDialog(false);
  };

  const handleConfirmCreateInitialRolePermissionsEnums = async () => {
    const result = await createInitialRolePermissionsEnums();
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      handleCloseCreateInitialRolePermissionsEnumsDialog();
      refetch?.();
    } else {
      setNotification({
        open: true,
        message: `Error creating Initial Roles Permission Enums: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleConfirmSyncRolePermissionsEnums = async () => {
    const result = await syncRolePermissionsEnums();
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setSyncResults(result.data.details);
      setOpenSyncResultsDialog(true);
      handleCloseSyncRolePermissionsEnumsDialog();
      refetch?.();
    } else {
      setNotification({
        open: true,
        message: `Error Syncing Roles Permission Enums: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (accountType !== "reosadmin") {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        You are not allowed to access this page
      </Typography>
    );
  }

  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Role Permission Management
        </Typography>
        <Divider />
        <Grid container>
          <Grid item xs={6} sx={{ p: 1 }}>
            <Button
              onClick={handleOpenCreateRolePermissionDialog}
              variant="contained"
              endIcon={<Add />}
            >
              Create New Role Permission Enum
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ p: 1 }}>
            <Button
              onClick={handleOpenCreateInitialRolePermissionsEnumsDialog}
              variant="contained"
              color="success"
              endIcon={<LibraryAdd />}
              disabled={isCreating}
            >
              {isCreating ? (
                <CircularProgress />
              ) : (
                "Create Initial Role Permissions Enums"
              )}
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ p: 1 }}>
            <Button
              onClick={handleOpenDeleteRolePermissionsDialog}
              variant="contained"
              color="error"
              endIcon={<Delete />}
              fullWidth
            >
              Delete Role Permissions
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ p: 1 }}>
            <Button
              onClick={handleOpenSyncRolePermissionsEnumsDialog}
              variant="contained"
              color="success"
              endIcon={<Delete />}
              disabled={isSyncing}
            >
              {isSyncing ? <CircularProgress /> : "Sync Role Permissions Enums"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper>
        <Typography variant="h6" gutterBottom p={1}>
          Filter by User Role Name
        </Typography>
        {isLoadingRoleNameEnums ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item>
              <Button
                variant={selectedRoleId === "all" ? "contained" : "outlined"}
                onClick={() => setSelectedRoleId("all")}
              >
                All
              </Button>
            </Grid>
            {userRoleNameEnums &&
              userRoleNameEnums?.map((role) => (
                <Grid item key={role?._id}>
                  <Button
                    variant={
                      selectedRoleId === role?._id ? "contained" : "outlined"
                    }
                    onClick={() => setSelectedRoleId(role?._id)}
                  >
                    {role?.userRoleName}
                  </Button>
                </Grid>
              ))}
          </Grid>
        )}
        <Divider sx={{ mt: 2, mb: 2 }} />
        <RolePermissionEnumsList
          rolePermissionEnums={rolePermissionEnums}
          refetch={refetch}
          isLoadingPermissions={isLoadingPermissions}
        />
      </Paper>
      <CreateEditRolePermissionEnumForm
        mode="create"
        open={openCreateRolePermissionDialog}
        onClose={handleCloseCreateRolePermissionDialog}
        refetch={refetch}
      />
      <ConfirmationDialog
        open={openCreateInitialRolePermissionsEnumsDialog}
        onClose={handleCloseCreateInitialRolePermissionsEnumsDialog}
        onConfirm={handleConfirmCreateInitialRolePermissionsEnums}
        title="Confirm Action"
        description="Are you sure you want to create Initial Role Permissions Enums?"
        isLoading={isCreating}
      />
      <DeleteRolePermissionsDialog
        open={openDeleteRolePermissionsDialog}
        onClose={handleCloseDeleteRolePermissionsDialog}
        refetch={refetch}
      />
      <ConfirmationDialog
        open={openSyncRolePermissionsEnumsDialog}
        onClose={handleCloseSyncRolePermissionsEnumsDialog}
        onConfirm={handleConfirmSyncRolePermissionsEnums}
        title="Confirm Sync"
        description="Are you sure you want to sync role permissions enums with all the existing roles?"
        isLoading={isSyncing}
      />
      <SyncResultsDialog
        open={openSyncResultsDialog}
        onClose={() => setOpenSyncResultsDialog(false)}
        syncResults={syncResults}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleCloseNotification}
      />
    </Container>
  );
};

export default RolePermissionManager;
