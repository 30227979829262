import React, { useState, useContext } from "react";
import { titleCase } from "title-case";
import { useGetAllTiersQuery } from "../../../services/tier";
import {
  useCreateGroupInitialCategoryMutation,
  useAssignDefaultOrderToCategoriesMutation,
  useFixingPutAllCategoriesToTierCategoriesCollectionMutation,
} from "../../../services/category";
import { useCreateBrokerageInitialCategoryMutation } from "../../../services/brokerage";
import { AuthContext } from "../../../context/AuthContext";
import {
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  Divider,
  Grid,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CategoriesList from "./CategoriesList";
import CategoryGroupsList from "./CategoryGroupsList";
import CreateEditCategoryForm from "./CreateEditCategoryForm";
import CreateEditCategoryGroupForm from "./CreateEditCategoryGroupForm";

const CategeryManagement = () => {
  const { userId, accountType, userRole } = useContext(AuthContext);
  const [selectedTier, setSelectedTier] = useState(null);
  const [inputtiervalue, setInputtiervalue] = useState("");
  const { data: tiers } = useGetAllTiersQuery();
  const [
    createBrokerageInitialCategory,
    { isLoading: isBrokerageCreateInitialCategoriesLoading },
  ] = useCreateBrokerageInitialCategoryMutation();
  const [
    fixingPutAllCategoriesToTierCategoriesCollection,
    { isLoading: isFixingPutAllCategoriesToTierCategoriesCollectionLoading },
  ] = useFixingPutAllCategoriesToTierCategoriesCollectionMutation();
  const [
    createGroupInitialCategory,
    { isLoading: isGroupCreateInitialCategoriesLoading },
  ] = useCreateGroupInitialCategoryMutation();
  const [
    assignDefaultOrderToCategories,
    { isLoading: isAssignDefaultOrderToCategoriesLoading },
  ] = useAssignDefaultOrderToCategoriesMutation();

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [openCreateCategoryDialog, setOpenCreateCategoryDialog] =
    useState(false);
  const [openCreateCategoryGroupDialog, setOpenCreateCategoryGroupDialog] =
    useState(false);

  const handleOpenCreateCategoryDialog = () => {
    setOpenCreateCategoryDialog(true);
  };

  const handleCloseCreateCategoryDialog = () => {
    setOpenCreateCategoryDialog(false);
  };

  const handleOpenCreateCategoryGroupDialog = () => {
    setOpenCreateCategoryGroupDialog(true);
  };

  const handleCloseCreateCategoryGroupDialog = () => {
    setOpenCreateCategoryGroupDialog(false);
  };

  const handleCreateBrokerageInitialCategories = async () => {
    if (selectedTier?.level !== "brokerage") {
      setNotification({
        open: true,
        message: "Please select a tier with the brokerage level first.",
      });
      return;
    }
    const result = await createBrokerageInitialCategory({
      tierId: selectedTier?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed to create Brokerage initial categories ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleFixingPutAllCategoriesToTierCategoriesCollection = async () => {
    if (!selectedTier) {
      setNotification({
        open: true,
        message: "Please select the tier first.",
      });
      return;
    }
    const result = await fixingPutAllCategoriesToTierCategoriesCollection({
      tierId: selectedTier?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed to put all categories back to tier categories collection ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleCreateGroupInitialCategories = async () => {
    if (selectedTier?.level !== "group") {
      setNotification({
        open: true,
        message: "Please select a tier with the group level first.",
      });
      return;
    }
    const result = await createGroupInitialCategory({
      userId: userId,
      tierId: selectedTier?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed to create Group initial categories ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleAssignDefaultOrderToCategories = async () => {
    if (!selectedTier) {
      setNotification({
        open: true,
        message: "Please select a tier first.",
      });
      return;
    }
    const result = await assignDefaultOrderToCategories({
      tierId: selectedTier?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed; ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (accountType !== "reosadmin") {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        You are not allowed access to this page
      </Typography>
    );
  }

  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Category Management
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          Choose Area to Manage
        </Typography>
        <Grid item>
          <Autocomplete
            value={selectedTier}
            onChange={(event, newValue) => {
              setSelectedTier(newValue);
            }}
            inputValue={inputtiervalue}
            onInputChange={(event, newInputValue) => {
              setInputtiervalue(newInputValue);
            }}
            options={(tiers || []).slice().sort((a, b) => {
              // Assuming you want to sort based on the brokerage, team, or group name
              const nameA = `${
                a?.level ||
                a?.brokerage?.officeName ||
                a?.team?.name ||
                a?.group?.name
              }`.toLowerCase();
              const nameB = `${
                b?.level ||
                b?.brokerage?.officeName ||
                b?.team?.name ||
                b?.group?.name
              }`.toLowerCase();
              return nameA.localeCompare(nameB);
            })}
            getOptionLabel={(option) =>
              option
                ? `${titleCase(option?.level)}${
                    (option?.brokerage || option?.team || option?.group) && // Check if next word is printed
                    (option?.brokerage?.officeName ||
                      option?.team?.name ||
                      option?.group?.name) // Check if next word is present
                      ? ` - ${
                          option?.brokerage?.officeName ||
                          option?.team?.name ||
                          option?.group?.name
                        }` // Add "-" if next word is printed
                      : ""
                  } ${
                    option?.level === "system" || option?.level === "global"
                      ? " - REACirc"
                      : ""
                  } ${
                    option?._id === option?.brokerage?.leadBrokerageTier
                      ? " - (Lead Brokerage Office)"
                      : ""
                  } ${
                    option?._id === option?.brokerage?.tier ? " - (Office)" : ""
                  }`
                : ""
            }
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Tier"
                variant="outlined"
                margin="normal"
                required
              />
            )}
          />
        </Grid>
        <Divider />
        <Grid container>
          <Grid item xs={6} sx={{ p: 2 }}>
            <List style={{ width: "100%", marginRight: "0.1rem" }}>
              <ListItem
                onClick={handleOpenCreateCategoryDialog}
                className="user-management-item"
              >
                Create new Category
              </ListItem>
              <Divider />
              <ListItem
                onClick={handleOpenCreateCategoryGroupDialog}
                className="user-management-item"
              >
                Create new Category Group
              </ListItem>
              <Divider />
              {userRole.includes("reos") && (
                <>
                  <ListItem
                    onClick={
                      handleFixingPutAllCategoriesToTierCategoriesCollection
                    }
                    className="user-management-item"
                  >
                    {isFixingPutAllCategoriesToTierCategoriesCollectionLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Put All Categories To Tier Categories Collection"
                    )}
                  </ListItem>
                  <Divider />
                </>
              )}
              <ListItem
                onClick={handleCreateBrokerageInitialCategories}
                className="user-management-item"
              >
                {isBrokerageCreateInitialCategoriesLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Create Brokerage Initial Categories"
                )}
              </ListItem>
              <Divider />
              <ListItem
                onClick={handleCreateGroupInitialCategories}
                className="user-management-item"
              >
                {isGroupCreateInitialCategoriesLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Create Group Initial Categories"
                )}
              </ListItem>
              <Divider />
              <ListItem
                onClick={handleAssignDefaultOrderToCategories}
                className="user-management-item"
              >
                {isAssignDefaultOrderToCategoriesLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Assign Default Order To Categories"
                )}
              </ListItem>
              <Divider />
            </List>
          </Grid>
        </Grid>
        <CreateEditCategoryForm
          mode="create"
          selectedTier={selectedTier}
          open={openCreateCategoryDialog}
          onClose={handleCloseCreateCategoryDialog}
        />
        <CreateEditCategoryGroupForm
          mode="create"
          selectedTier={selectedTier}
          open={openCreateCategoryGroupDialog}
          onClose={handleCloseCreateCategoryGroupDialog}
        />
        <NotificationSnackbar
          open={notification.open}
          message={notification.message}
          onClose={handleClose}
        />
      </Paper>
      <CategoriesList selectedTier={selectedTier} />
      <CategoryGroupsList selectedTier={selectedTier} />
    </Container>
  );
};

export default CategeryManagement;
