import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const mapsApi = createApi({
  reducerPath: "map",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/maps-api/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["ListingTourItem"],
  endpoints: (builder) => ({
    validateAddress: builder.query({
      query: ({ tierId, address }) => ({
        url: `validate-address/${tierId}?address=${encodeURIComponent(
          address
        )}`,
        method: "GET",
      }),
      skip: (tierId, address) => !tierId || !address,
    }),
  }),
});

export const { useValidateAddressQuery } = mapsApi;

export default mapsApi;
export { mapsApi };
