import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const listingTourApi = createApi({
  reducerPath: "listing-tour",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/listing-tours/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["ListingTour"],
  endpoints: (builder) => ({
    getAllListingTours: builder.query({
      query: () => ({
        url: "get-all",
        method: "GET",
      }),
      providesTags: [{ type: "ListingTour", id: "LIST" }],
    }),
    getAllListingToursByTier: builder.query({
      query: (tierId) => ({
        url: `get-all-by-tier/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: [{ type: "ListingTour", id: "LIST" }],
    }),
    getListingTourById: builder.query({
      query: (listingTourId) => ({
        url: `get-one-by-id/${listingTourId}`,
        method: "GET",
      }),
      skip: (listingTourId) => !listingTourId,
      providesTags: [{ type: "ListingTour", id: "LIST" }],
    }),
    createListingTour: builder.mutation({
      query: ({ tierId, listingTourData }) => ({
        url: `create-one/${tierId}`,
        method: "POST",
        body: { listingTourData },
      }),
      invalidatesTags: [{ type: "ListingTour", id: "LIST" }],
    }),
    updateListingTour: builder.mutation({
      query: ({ listingTourId, listingTourData }) => ({
        url: `update-one/${listingTourId}`,
        method: "PUT",
        body: { listingTourData },
      }),
      invalidatesTags: [{ type: "ListingTour", id: "LIST" }],
    }),
    deleteListingTour: builder.mutation({
      query: ({ listingTourId }) => ({
        url: `delete-one/${listingTourId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "ListingTour", id: "LIST" }],
    }),
    deleteAllListingTours: builder.mutation({
      query: () => ({
        url: `delete-all`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "ListingTour", id: "LIST" }],
    }),
    downloadTourSheet: builder.query({
      query: ({ tourSheetUrl, disposition }) =>
        `download-tour-sheet/${encodeURIComponent(
          tourSheetUrl
        )}?disposition=${disposition}`,
      skip: (tourSheetUrl, disposition) => !tourSheetUrl || !disposition,
    }),
    useLazyDownloadTourSheetQuery: builder.query({
      query: ({ tourSheetUrl, disposition }) => ({
        url: `download-tour-sheet/${encodeURIComponent(
          tourSheetUrl
        )}?disposition=${disposition}`,
        method: "GET",
      }),
      skip: (tourSheetUrl, disposition) => !tourSheetUrl || !disposition,
    }),
  }),
});

export const {
  useGetAllListingToursQuery,
  useGetAllListingToursByTierQuery,
  useGetListingTourByIdQuery,
  useCreateListingTourMutation,
  useUpdateListingTourMutation,
  useDeleteListingTourMutation,
  useDeleteAllListingToursMutation,
  useDownloadTourSheetQuery,
  useLazyDownloadTourSheetQuery,
} = listingTourApi;

export default listingTourApi;
export { listingTourApi };
