// components/Admin/SyncResultsDialog.js

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

const SyncResultsDialog = ({ open, onClose, syncResults }) => {
  if (!syncResults) return null;

  const { syncedRoles, skippedRoles, errors } = syncResults;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Synchronization Results</DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" gutterBottom>
          Synced Roles
        </Typography>
        {syncedRoles?.length > 0 ? (
          <List>
            {syncedRoles?.map((role, index) => (
              <ListItem key={index} alignItems="flex-start">
                <ListItemText
                  primary={`Role: ${role?.role}`}
                  secondary={
                    <>
                      {role?.addedPermissions && (
                        <Typography variant="body2" color="textPrimary">
                          Added Permissions:{" "}
                          {role?.addedPermissions?.join(", ")}
                        </Typography>
                      )}
                      {role?.createdPermissions &&
                        role?.createdPermissions?.length > 0 && (
                          <Typography variant="body2" color="textPrimary">
                            Created Permissions:{" "}
                            {role?.createdPermissions?.join(", ")}
                          </Typography>
                        )}
                      {role?.status && (
                        <Typography variant="body2" color="textPrimary">
                          Status: {role?.status}
                        </Typography>
                      )}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body2">No roles were synchronized.</Typography>
        )}
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" gutterBottom>
          Skipped Roles
        </Typography>
        {skippedRoles?.length > 0 ? (
          <List>
            {skippedRoles?.map((role, index) => (
              <ListItem key={index} alignItems="flex-start">
                <ListItemText
                  primary={`Role: ${role?.role}`}
                  secondary={`Reason: ${role?.reason}`}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body2">No roles were skipped.</Typography>
        )}
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" gutterBottom>
          Errors
        </Typography>
        {errors?.length > 0 ? (
          <List>
            {errors?.map((error, index) => (
              <ListItem key={index} alignItems="flex-start">
                <ListItemText
                  primary={`Role: ${error?.role}`}
                  secondary={`Permission: ${error?.permission} - Error: ${error?.error}`}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body2">No errors encountered.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SyncResultsDialog;
