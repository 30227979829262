import React, { useState, useEffect, useContext, memo } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetListingByIdQuery,
  useDeleteListingMutation,
  useLazyDownloadPhotographQuery,
} from "../../../services/listings";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { formatMoney } from "../../helpers/utils";
import Carousel from "react-material-ui-carousel";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Chip,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import { MoreVert, DeleteForever, Edit } from "@mui/icons-material/";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CreateEditListingForm from "./CreateEditListingForm";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import MemberProfileDrawer from "../../members/MemberProfileDrawer";
import { AvatarStyledBadge } from "../../helpers/utils";
import { ConfirmationDialog } from "../..";

const ListingCard = ({ listingId }) => {
  const navigate = useNavigate();
  const { data: listing } = useGetListingByIdQuery(listingId);
  const { accountType, userId } = useContext(AuthContext);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openEditListingDialog, setOpenEditListingDialog] = useState(false);
  const [trigger, { data: downloadedPhotograph }] =
    useLazyDownloadPhotographQuery();
  const [photographUrls, setPhotographUrls] = useState({});
  const [deleteListing] = useDeleteListingMutation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (listing && listing.photographs) {
      listing.photographs.forEach((photo) => {
        if (photo?._id && !photographUrls[photo?._id]) {
          trigger({
            photographId: photo?._id,
            disposition: "attachment",
          }).then((result) => {
            if (result?.data?.url) {
              setPhotographUrls((prev) => ({
                ...prev,
                [photo._id]: result?.data?.url,
              }));
            }
          });
        }
      });
    }
  }, [listing, trigger, photographUrls]);

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditListingDialog = () => {
    setOpenEditListingDialog(true);
    handleMenuClose();
  };

  const handleCloseEditListingDialog = () => {
    setOpenEditListingDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleMemberClick = (memberId) => {
    setSelectedMemberId(memberId);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedMemberId(null);
  };

  const renderPhotographs = (photographs) => {
    if (!photographs) return;

    return photographs.map((photo, i) => (
      <Paper key={i} elevation={0} className="aspect-ratio-16-9">
        <img
          src={photographUrls[photo?._id]}
          alt={`Photograph ${photo?._id}`}
          className="aspect-ratio-content"
        />
      </Paper>
    ));
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const isAuthor = listing?.agent?._id === userId;
  const isAuthorOrAdmin =
    isAuthor || accountType === "reosadmin" || accountType === "brokerage";

  const handleDeleteListing = () => {
    setIsConfirmDialogOpen(true);
  };

  const confirmDeleteListing = async () => {
    if (!listing?._id) {
      setNotification({
        open: true,
        message: "The listing ID is missing.",
      });
      return;
    }

    const result = await deleteListing({ listingId: listing?._id });
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        setIsConfirmDialogOpen(false);
        navigate(-1);
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Failed to delete listing: ${
          result?.error?.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const cancelDeleteListing = () => {
    setIsConfirmDialogOpen(false);
  };

  return (
    <Card sx={{ overflow: "auto" }} className="listing-card">
      <CardHeader
        className="listing-card-header"
        avatar={
          <Tooltip title="Member Profile link">
            <AvatarStyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              color="success"
              invisible={!listing?.agent?.isOnline}
              width="5px"
              height="5px"
            >
              <Avatar
                className="avatar"
                src={listing?.agent?.image?.url}
                onClick={(event) => {
                  event.stopPropagation();
                  handleMemberClick(listing?.agent?._id);
                }}
              >
                R
              </Avatar>
            </AvatarStyledBadge>
          </Tooltip>
        }
        action={
          <>
            <IconButton
              className="menu-button"
              aria-label="more"
              aria-controls={open ? "listing-menu" : undefined}
              aria-haspopup="true"
              onClick={handleMenuOpen}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="listing-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
            >
              {isAuthorOrAdmin && (
                <MenuItem onClick={handleOpenEditListingDialog}>
                  <Edit />
                  <Typography ml={1}>Edit Listing</Typography>
                </MenuItem>
              )}
              {isAuthorOrAdmin && (
                <MenuItem onClick={handleDeleteListing}>
                  <DeleteForever />
                  <Typography ml={1}>Delete Listing</Typography>
                </MenuItem>
              )}
              {!(accountType === "reosadmin") && !isAuthorOrAdmin && (
                <MenuItem>
                  <Typography ml={1}>No actions</Typography>
                </MenuItem>
              )}
            </Menu>
          </>
        }
        title={
          <div className="card-header-title">
            <Tooltip title="Member Profile link">
              <Link
                onClick={(event) => {
                  event.stopPropagation();
                  handleMemberClick(listing?.agent?._id);
                }}
                className="avatar-name"
              >
                {listing?.agent?.firstName} {listing?.agent?.lastName}
              </Link>
            </Tooltip>
          </div>
        }
      />
      <CardHeader
        title={
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography className="listing-card-address">
                {listing?.address ? listing?.address : ""}
              </Typography>
              <Typography className="listing-card-list-price">
                {listing?.listPrice
                  ? "$" + formatMoney(listing.listPrice, 0)
                  : "TBD"}
              </Typography>
            </div>
          </>
        }
      />
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "0 0 1rem 0",
        }}
      >
        {listing?.listingDate && new Date(listing.listingDate) > new Date() && (
          <div className="coming-soon-banner">
            COMING SOON -{" "}
            {new Date(listing.listingDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </div>
        )}
        <Carousel
          autoPlay={false}
          navButtonsAlwaysVisible={true}
          animation="slide"
          swipe={true}
          duration={500}
        >
          {listing?.photographs && listing.photographs.length > 0 ? (
            renderPhotographs(listing.photographs)
          ) : (
            <Paper key="comingsoon" elevation={3} className="aspect-ratio-16-9">
              <img
                src="/imgs/Home-img-coming-soon.jpg"
                alt="coming soon"
                className="aspect-ratio-content"
              />
            </Paper>
          )}
        </Carousel>
      </div>
      <CardContent className="listing-custom-card-content">
        <TableContainer>
          <Table size="small">
            <TableBody>
              <StyledTableRow
                key={listing?._id}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <StyledTableCell width={110} className="listing-table-label">
                  Area
                </StyledTableCell>
                <StyledTableCell>{listing?.area}</StyledTableCell>
                <StyledTableCell width={120} className="listing-table-label">
                  Bedrooms
                </StyledTableCell>
                <StyledTableCell>{listing?.bedrooms}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell className="listing-table-label">
                  SubArea
                </StyledTableCell>
                <StyledTableCell>{listing?.subarea}</StyledTableCell>
                <StyledTableCell className="listing-table-label">
                  Bathrooms
                </StyledTableCell>
                <StyledTableCell>{listing?.bathrooms}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell className="listing-table-label">
                  Total Sqft
                </StyledTableCell>
                <StyledTableCell>
                  {listing?.totalSqft?.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell className="listing-table-label">
                  Finished Sqft
                </StyledTableCell>
                <StyledTableCell>
                  {listing?.finishedSqft?.toLocaleString()}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell className="listing-table-label">
                  Lot Sqft
                </StyledTableCell>
                <StyledTableCell>
                  {listing?.lotSqft?.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell className="listing-table-label">
                  Lot Acres
                </StyledTableCell>
                <StyledTableCell>
                  {listing?.lotAcres?.toLocaleString()}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell colSpan={1} className="listing-table-label">
                  Entered By
                </StyledTableCell>
                <StyledTableCell colSpan={3}>
                  <Tooltip title="Member Profile link">
                    <Link
                      onClick={(event) => {
                        event.stopPropagation();
                        handleMemberClick(listing?.agent?._id);
                      }}
                    >
                      {listing?.agent?.firstName} {listing?.agent?.lastName}
                    </Link>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell colSpan={1} className="listing-table-label">
                  Listing Date
                </StyledTableCell>
                <StyledTableCell colSpan={3}>
                  <Typography className="date-text">
                    {new Date(listing?.listingDate)?.toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell colSpan={4}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bolder" }}>
                    Listing Highlights
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell colSpan={4}>
                  <Typography variant="body1" gutterBottom>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: listing?.listingComment
                          ? listing?.listingComment?.join("\n")
                          : "",
                      }}
                    />
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
              {listing?.videoLinks && listing?.videoLinks?.length > 0 && (
                <TableRow>
                  <TableCell colSpan={4}>
                    {listing?.videoLinks?.map((videoLink, index) => (
                      <Grid item key={index}>
                        <Chip
                          label={`Video ${index + 1}`}
                          component="a"
                          href={videoLink}
                          clickable
                        />
                      </Grid>
                    ))}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Divider />
      <CreateEditListingForm
        open={openEditListingDialog}
        onClose={handleCloseEditListingDialog}
        mode="edit"
        tierId={listing?.tier?._id}
        listing={listing}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <MemberProfileDrawer
        userId={selectedMemberId}
        open={isDrawerOpen}
        onClose={handleDrawerClose}
      />
      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={cancelDeleteListing}
        onConfirm={confirmDeleteListing}
        title="Confirm Deletion"
        description="Are you sure you want to delete this listing? This action cannot be undone."
      />
    </Card>
  );
};

export default memo(ListingCard);
