import React, { useContext, useState, memo } from "react";
import { useParams } from "react-router-dom";
import { useGetAllTierMembersQuery } from "../../services/member";
import MemberCard from "./MemberCard";
import MemberProfileDrawer from "./MemberProfileDrawer";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { Close as CloseIcon } from "@mui/icons-material/";

import {
  Grid,
  IconButton,
  CircularProgress,
  Typography,
  Divider,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import { TierContext } from "../../context/TierContext";
import MembersPageNavbar from "./MembersPageNavbar";

const MembersPage = () => {
  const { categoryName } = useParams();
  const { userId } = useContext(AuthContext);
  const { currentTierId, tierType } = useContext(TierContext);
  const [filter, setFilter] = useState("");
  const [timeFilter, setTimeFilter] = useState("ever");
  const [participantType, setParticipantType] = useState("");

  const {
    data: members,
    isLoading,
    error,
  } = useGetAllTierMembersQuery(
    {
      userId,
      tierId: currentTierId,
    },
    {
      skip: !currentTierId,
    }
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [viewMode, setViewMode] = useState("grid");

  const handleMemberSelect = (member) => {
    setSelectedMemberId(member?.user?._id);
    setIsDrawerOpen(true);
  };

  const handleMemberDrawerClose = () => {
    setSelectedMemberId(null);
    setIsDrawerOpen(false);
  };
  const clearFilters = () => {
    setFilter("");
  };

  const shouldShowMember = (lastOnlineTime, period) => {
    if (period === "ever") return true;
    if (period === "never") {
      return !lastOnlineTime;
    }
    if (!lastOnlineTime) {
      return false;
    }

    const now = dayjs();
    const lastOnline = dayjs(lastOnlineTime);
    let isOnline = false;

    switch (period) {
      case "now":
        isOnline = lastOnline.isAfter(now.subtract(5, "minute"));
        break;
      case "15m":
        isOnline = lastOnline.isAfter(now.subtract(15, "minute"));
        break;
      case "30m":
        isOnline = lastOnline.isAfter(now.subtract(30, "minute"));
        break;
      case "1h":
        isOnline = lastOnline.isAfter(now.subtract(1, "hour"));
        break;
      case "4h":
        isOnline = lastOnline.isAfter(now.subtract(4, "hour"));
        break;
      case "12h":
        isOnline = lastOnline.isAfter(now.subtract(12, "hour"));
        break;
      case "today":
        isOnline = lastOnline.isSame(now, "day");
        break;
      case "week":
        isOnline = lastOnline.isSame(now, "week");
        break;
      case "month":
        isOnline = lastOnline.isSame(now, "month");
        break;
      default:
        isOnline = false;
    }

    return isOnline;
  };

  const sortedMembers = React.useMemo(() => {
    if (!members) {
      return [];
    }

    return members
      .filter((member) => {
        // Combine first name and last name into a full name string, lowercased
        const fullName =
          `${member?.user?.firstName} ${member?.user?.lastName}`?.toLowerCase();

        // Normalize the filter text
        const normalizedFilter = filter?.toLowerCase();

        const nameMatch =
          member?.user?.firstName?.toLowerCase()?.includes(normalizedFilter) ||
          member?.user?.lastName?.toLowerCase()?.includes(normalizedFilter) ||
          fullName?.includes(normalizedFilter); // Check full name match

        const typeMatch = participantType
          ? member?.conferenceParticipantType === participantType
          : true;

        return (
          nameMatch &&
          typeMatch &&
          shouldShowMember(member?.user?.lastOnlineTime, timeFilter)
        );
      })
      .sort((a, b) => {
        const lastOnlineA = a?.user?.lastOnlineTime
          ? new Date(a.user.lastOnlineTime).getTime()
          : 0;
        const lastOnlineB = b?.user?.lastOnlineTime
          ? new Date(b.user.lastOnlineTime).getTime()
          : 0;

        if (lastOnlineA !== lastOnlineB) {
          return lastOnlineB - lastOnlineA;
        } else {
          const lastNameCompare = (a?.user?.lastName || "").localeCompare(
            b?.user?.lastName || ""
          );
          if (lastNameCompare !== 0) {
            return lastNameCompare;
          }
          return (a?.user?.firstName || "").localeCompare(
            b?.user?.firstName || ""
          );
        }
      });
  }, [members, filter, timeFilter, participantType]);

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );
  if (error)
    return (
      <Typography variant="body1">
        Error loading members: {error.message}
      </Typography>
    );

  return (
    <div
      className={`members-page ${
        tierType === "conference" ? "conference" : ""
      }`}
    >
      <MembersPageNavbar
        label={categoryName}
        membersAmount={sortedMembers?.length}
        viewMode={viewMode}
        setViewMode={setViewMode}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column", // Default to column for mobile view
          justifyContent: "space-between", // Space out the items equally
          padding: "5px 0",
          background: "var(--bg-color)",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          p: 1,
          mx: 2,
          width: "100%", // Take full width of the container
          // Use a media query to apply different styles on larger screens
          "@media (min-width: 900px)": {
            // Adjust the width as needed for your breakpoint
            flexDirection: "row", // Set back to row for larger screens
          },
        }}
      >
        {tierType === "conference" && (
          <FormControl
            sx={{
              minWidth: 200,
              flexGrow: 1, // Ensure the select grows
              flexShrink: 0, // Ensure the select doesn't shrink
              ml: 0,
              mx: 2, // Add horizontal margin for spacing
            }}
          >
            <InputLabel
              sx={{
                mt: "5px",
                backgroundColor: "var(--bg-color)", // Ensure it stands out
                px: 1, // Add padding for better visibility
              }}
              id="participant-type-filter-label"
            >
              Conference Participant Type
            </InputLabel>
            <Select
              labelId="participant-type-filter-label"
              value={participantType}
              onChange={(e) => setParticipantType(e.target.value)}
              label="Participant Type"
              sx={{ mt: "5px" }}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="Attendee">Attendee</MenuItem>
              <MenuItem value="Organizer">Organizer</MenuItem>
              <MenuItem value="Keynote Speaker">Keynote Speaker</MenuItem>
              <MenuItem value="Panelist">Panelist</MenuItem>
              <MenuItem value="Sponsor">Sponsor</MenuItem>
              <MenuItem value="Exhibitor">Exhibitor</MenuItem>
              <MenuItem value="Media">Media</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
        )}
        <TextField
          variant="outlined"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Filter by Name"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {filter && (
                  <IconButton onClick={clearFilters} edge="end">
                    <CloseIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            flexGrow: 1, // Ensure the text field grows
            mx: 2, // Add horizontal margin for spacing
            "@media (min-width: 900px)": {
              maxWidth: 350,
            },
          }}
        />

        <Typography
          variant="body1"
          sx={{
            flexGrow: 1, // Ensure the text grows
            flexShrink: 0, // Ensure the text doesn't shrink
            mx: 2, // Add horizontal margin for spacing
          }}
        >
          <strong>Matches:</strong> {sortedMembers?.length}
        </Typography>

        <FormControl
          sx={{
            minWidth: 200,
            flexGrow: 1, // Ensure the select grows
            flexShrink: 0, // Ensure the select doesn't shrink
            ml: 0,
            mx: 2, // Add horizontal margin for spacing
          }}
        >
          <InputLabel sx={{ mt: "5px" }} id="time-filter-label">
            Online Since
          </InputLabel>
          <Select
            labelId="time-filter-label"
            value={timeFilter}
            onChange={(e) => setTimeFilter(e.target.value)}
            label="Online Since"
            sx={{ mt: "5px" }}
          >
            <MenuItem value="now">Online Now</MenuItem>
            <MenuItem value="15m">Last 15 Minutes</MenuItem>
            <MenuItem value="30m">Last 30 Minutes</MenuItem>
            <MenuItem value="1h">Last Hour</MenuItem>
            <MenuItem value="4h">Last 4 Hours</MenuItem>
            <MenuItem value="12h">Last 12 Hours</MenuItem>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="week">This Week</MenuItem>
            <MenuItem value="month">This Month</MenuItem>
            <MenuItem value="ever">Show All</MenuItem>
            <MenuItem value="never">Never Logged In</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Grid
        container
        spacing={2}
        justifyContent="center"
        mt={3}
        className={`members-page-body ${viewMode}`}
      >
        {sortedMembers?.map((member) => (
          <div key={member?._id} className="members-page-body-item">
            <MemberCard
              viewMode={viewMode}
              member={member}
              onSelect={() => handleMemberSelect(member)}
            />
            <Divider variant="fullWidth" />
          </div>
        ))}
        <MemberProfileDrawer
          userId={selectedMemberId}
          open={isDrawerOpen}
          onClose={() => handleMemberDrawerClose()}
        />
      </Grid>
    </div>
  );
};

export default memo(MembersPage);
