import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";

const AgentEditCell = ({ params, handleAgentChange, members = [] }) => {
  const apiRef = useGridApiContext();
  const [selectedAgent, setSelectedAgent] = useState(null);

  useEffect(() => {
    const currentAgent = members?.find(
      (member) => member?.user?._id === params?.row?.agent?._id
    )?.user;
    setSelectedAgent(currentAgent || null);
  }, [members, params]);

  const handleChange = (e, newValue) => {
    setSelectedAgent(newValue);
    handleAgentChange(params?.id, newValue);
    apiRef.current.stopCellEditMode({ id: params.id, field: params.field });
  };

  return (
    <Autocomplete
      options={members?.map((member) => member && member.user) || []}
      getOptionLabel={(option) => `${option?.firstName} ${option?.lastName}`}
      value={selectedAgent || null}
      fullWidth
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} label="Agent" />}
    />
  );
};

export default AgentEditCell;
