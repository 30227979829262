import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const tierApi = createApi({
  reducerPath: "tier",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/tiers/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Tier"],
  endpoints: (builder) => ({
    createTier: builder.mutation({
      query: ({
        name,
        shortName,
        type,
        level,
        features,
        brokerageId,
        reosadminId,
        teamId,
      }) => ({
        url: `create-one`,
        method: "POST",
        body: {
          name,
          shortName,
          type,
          level,
          features,
          brokerageId,
          reosadminId,
          teamId,
        },
      }),
      skip: (accountType) => !accountType,
      invalidatesTags: [{ type: "Tier", id: "LIST" }],
    }),
    fetchAllTiers: builder.query({
      query: (accountType) => {
        const token = localStorage.getItem("token");
        if (!token) {
          return new Request("");
        }
        return {
          url: "get-all",
          method: "GET",
        };
      },
      skip: (accountType) =>
        accountType !== "reosadmin" || accountType !== "reossuperadmin",
      providesTags: [
        { type: "User", id: "LIST" },
        { type: "User", id: "ACTIVE_LIST" },
      ],
    }),
    getAllTiers: builder.query({
      query: (accountType) => {
        return {
          url: `get-all`,
          method: "GET",
        };
      },
      skip: (accountType) => accountType !== "reosadmin",
      providesTags: [{ type: "Tier", id: "LIST" }],
    }),
    getAllBrokerageLevelTiers: builder.query({
      query: ({ accountType, filteredByBrokerage }) => {
        return {
          url: `get-all-brokerage-level`,
          method: "GET",
          params: { filteredByBrokerage },
        };
      },
      skip: (accountType) => accountType !== "reosadmin",
      providesTags: [{ type: "Tier", id: "LIST" }],
    }),
    getAllBrokerageLevelRelatedToUserTiers: builder.query({
      query: ({ accountType }) => {
        return {
          url: `get-all-brokerage-level-related-to-user`,
          method: "GET",
        };
      },
      skip: (accountType) => accountType !== "reosadmin",
      providesTags: [{ type: "Tier", id: "LIST" }],
    }),
    getTierById: builder.query({
      query: (tierId) => ({
        url: `get-one-by-id/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: (result, error, tierId) => [{ type: "Tier", id: tierId }],
    }),
    Get5YearStatsTierTransactionsById: builder.query({
      query: (tierId) => ({
        url: `get-5YearStats-brokerage-transactions-by-id/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: (result, error, tierId) => [{ type: "Tier", id: tierId }],
    }),
    updateTierStatus: builder.mutation({
      query: ({ tierId, status }) => ({
        url: `update-tier-status/${tierId}`,
        method: "PUT",
        body: { status },
      }),
      invalidatesTags: (result, error, { tierId }) => [
        { type: "Tier", id: tierId },
      ],
    }),
    updateTier: builder.mutation({
      query: ({
        tierId,
        name,
        shortName,
        type,
        level,
        features,
        brokerageId,
        reosadminId,
        teamId,
      }) => ({
        url: `update/${tierId}`,
        method: "PUT",
        body: {
          name,
          shortName,
          type,
          level,
          features,
          brokerageId,
          reosadminId,
          teamId,
        },
      }),
      invalidatesTags: (result, error, { tierId }) => [
        { type: "Tier", id: tierId },
      ],
    }),
    getTierTypeEnums: builder.query({
      query: () => ({
        url: `get-tier-type-enums`,
        method: "GET",
      }),
      providesTags: [{ type: "Tier", id: "LIST" }],
    }),
    getTierLevelEnums: builder.query({
      query: () => ({
        url: `get-tier-level-enums`,
        method: "GET",
      }),
      providesTags: [{ type: "Tier", id: "LIST" }],
    }),
    getTierFeaturesEnums: builder.query({
      query: () => ({
        url: `get-tier-features-enums`,
        method: "GET",
      }),
      providesTags: [{ type: "Tier", id: "LIST" }],
    }),
    getTiersFromUserRoleTiersCollection: builder.query({
      query: () => ({
        url: `get-all-local-office-from-user-role-tiers-collection`,
        method: "GET",
      }),
      providesTags: [{ type: "Tier", id: "LIST" }],
    }),
    assignTierToBrokerage: builder.mutation({
      query: ({ tierId, brokerageId }) => ({
        url: "assign-tier-to-brokerage",
        method: "POST",
        body: { tierId, brokerageId },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Brokerage", id: "LIST" },
      ],
    }),
    assignTierToRole: builder.mutation({
      query: ({ tierId, roleId, permissions }) => ({
        url: "assign-tier-to-role",
        method: "POST",
        body: { tierId, roleId, permissions },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Role", id: "LIST" },
      ],
    }),
    assignViewerTierToRole: builder.mutation({
      query: ({ viewerTierId, roleId, permissions }) => ({
        url: "assign-viewer-tier-to-role",
        method: "POST",
        body: { viewerTierId, roleId, permissions },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Role", id: "LIST" },
      ],
    }),
    assignTierToMultipleRoles: builder.mutation({
      query: ({ tierId, roleIds, permissions }) => ({
        url: "assign-tier-to-multiple-roles",
        method: "POST",
        body: { tierId, roleIds, permissions },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Role", id: "LIST" },
      ],
    }),
    assignTierToTeam: builder.mutation({
      query: ({ tierId, teamId }) => ({
        url: "assign-tier-to-team",
        method: "POST",
        body: { tierId, teamId },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Team", id: "LIST" },
      ],
    }),
    assignTierToGroup: builder.mutation({
      query: ({ tierId, groupId }) => ({
        url: "assign-tier-to-group",
        method: "POST",
        body: { tierId, groupId },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Group", id: "LIST" },
      ],
    }),
    removeTierFromRole: builder.mutation({
      query: ({ tierId, roleId }) => ({
        url: "remove-tier-from-role",
        method: "POST",
        body: { tierId, roleId },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Role", id: "LIST" },
      ],
    }),
    removeViewerTierFromRole: builder.mutation({
      query: ({ viewerTierId, roleId }) => ({
        url: "remove-viewer-tier-from-role",
        method: "POST",
        body: { viewerTierId, roleId },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Role", id: "LIST" },
      ],
    }),
    removeTierFromMultipleRoles: builder.mutation({
      query: ({ tierId, roleIds }) => ({
        url: "remove-tier-from-multiple-roles",
        method: "POST",
        body: { tierId, roleIds },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Role", id: "LIST" },
      ],
    }),
    migrateCategoryToNewTier: builder.mutation({
      query: ({ categoryId, newTierId }) => ({
        url: "migrate-category-to-new-tier",
        method: "POST",
        body: { categoryId, newTierId },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Category", id: "LIST" },
      ],
    }),
    updateChatParticipantsForTier: builder.mutation({
      query: ({ tierId }) => ({
        url: `update-chat-participants-for-tier/${tierId}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Tier", id: "LIST" }],
    }),
    deleteTier: builder.mutation({
      query: ({ tierId }) => ({
        url: `delete-one/${tierId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Tier", id: "LIST" }],
    }),
  }),
});

export const {
  useCreateTierMutation,
  useFetchAllTiersQuery,
  useGetAllTiersQuery,
  useGetAllBrokerageLevelTiersQuery,
  useGetAllBrokerageLevelRelatedToUserTiersQuery,
  useUpdateTierMutation,
  useUpdateTierStatusMutation,
  useGetTierByIdQuery,
  useGet5YearStatsTierTransactionsByIdQuery,
  useGetTiersFromUserRoleTiersCollectionQuery,
  useGetTierTypeEnumsQuery,
  useGetTierLevelEnumsQuery,
  useGetTierFeaturesEnumsQuery,
  useDeleteTierMutation,
  useAssignTierToBrokerageMutation,
  useAssignTierToRoleMutation,
  useAssignViewerTierToRoleMutation,
  useAssignTierToMultipleRolesMutation,
  useAssignTierToTeamMutation,
  useAssignTierToGroupMutation,
  useMigrateCategoryToNewTierMutation,
  useUpdateChatParticipantsForTierMutation,
  useRemoveTierFromMultipleRolesMutation,
  useRemoveTierFromRoleMutation,
  useRemoveViewerTierFromRoleMutation,
} = tierApi;

export default tierApi;
export { tierApi };
