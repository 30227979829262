import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const listingsOnToursApi = createApi({
  reducerPath: "listings-on-the-tours",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/listings-on-the-tours/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["ListingsOnTour"],
  endpoints: (builder) => ({
    addListingsToListingTour: builder.mutation({
      query: ({ tierId, listingTourId, listingIds }) => ({
        url: `add-listings-to-listing-tour/${tierId}/${listingTourId}`,
        method: "POST",
        body: { listingIds },
      }),
      invalidatesTags: [
        { type: "ListingsOnTour", id: "LIST" },
        { type: "ListingTour", id: "LIST" },
      ],
    }),
    removeListingsFromListingTour: builder.mutation({
      query: ({ tierId, listingTourId, listingIds }) => ({
        url: `remove-listings-from-listing-tour/${tierId}/${listingTourId}`,
        method: "POST",
        body: { listingIds },
      }),
      invalidatesTags: [
        { type: "ListingsOnTour", id: "LIST" },
        { type: "ListingTour", id: "LIST" },
      ],
    }),
    updateListingTourOrder: builder.mutation({
      query: ({ tierId, listingTourId, listings }) => ({
        url: `update-listing-tour-order/${tierId}/${listingTourId}`,
        method: "POST",
        body: { listings },
      }),
      invalidatesTags: [
        { type: "ListingsOnTour", id: "LIST" },
        { type: "ListingTour", id: "LIST" },
        { type: "Listing", id: "LIST" },
      ],
    }),
    updateIsForListingTour: builder.mutation({
      query: ({ tierId, listingIds, isForListingTour }) => ({
        url: `update-is-for-listing-tour/${tierId}`,
        method: "POST",
        body: { listingIds, isForListingTour },
      }),
      invalidatesTags: [
        { type: "ListingsOnTour", id: "LIST" },
        { type: "ListingTour", id: "LIST" },
        { type: "Listing", id: "LIST" },
      ],
    }),
    sendPDFSummaryOfListingTourEmails: builder.mutation({
      query: ({ tierId, listingTourId, formData }) => ({
        url: `send-pdf-summary-of-listing-tour-emails/${tierId}/${listingTourId}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [
        { type: "ListingsOnTour", id: "LIST" },
        { type: "ListingTour", id: "LIST" },
        { type: "Listing", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useAddListingsToListingTourMutation,
  useRemoveListingsFromListingTourMutation,
  useUpdateListingTourOrderMutation,
  useUpdateIsForListingTourMutation,
  useSendPDFSummaryOfListingTourEmailsMutation,
} = listingsOnToursApi;

export default listingsOnToursApi;
export { listingsOnToursApi };
