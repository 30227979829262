import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const tierPermissionApi = createApi({
  reducerPath: "tier-permission",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/tier-permissions/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["TierPermission"],
  endpoints: (builder) => ({
    getAllTierPermissions: builder.query({
      query: ({
        tierId,
        page = 1,
        pageSize = 25,
        userAccountType,
        userRoleName,
        userNameSearch,
      }) => {
        const params = new URLSearchParams();
        if (tierId) params.append("tierId", tierId);
        if (userAccountType) params.append("userAccountType", userAccountType);
        if (userRoleName) params.append("userRoleName", userRoleName);
        if (userNameSearch) params.append("userNameSearch", userNameSearch);
        params.append("page", page);
        params.append("pageSize", pageSize);

        return {
          url: `get-all?${params.toString()}`,
          method: "GET",
        };
      },
      providesTags: (result, error, { tierId }) =>
        result
          ? [
              ...result.tierPermissions.map(({ _id }) => ({
                type: "TierPermission",
                id: _id,
              })),
              { type: "TierPermission", id: "LIST" },
            ]
          : [{ type: "TierPermission", id: "LIST" }],
    }),
    getAllTierPermissionItemsByUserRole: builder.query({
      query: ({ roleId, tierId }) => {
        return {
          url: `get-all-tierpermission-items-by-user-role/${roleId}/${tierId}`,
          method: "GET",
        };
      },
      skip: (roleId, tierId) => !roleId || !tierId,
      providesTags: [{ type: "TierPermission", id: "LIST" }],
    }),
    checkTierPermissionForUserRole: builder.query({
      query: ({ tierId, userId, permission }) => {
        return {
          url: `check-tier-permission-for-user-role/${tierId}/${userId}/${permission}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "TierPermission", id: "LIST" }],
    }),
    updateTierPermission: builder.mutation({
      query: ({ tierPermId, permissions }) => ({
        url: `update/${tierPermId}`,
        method: "PUT",
        body: { permissions },
      }),
      invalidatesTags: (result, error, { tierPermId }) => [
        { type: "TierPermission", id: tierPermId },
      ],
    }),
    updateTierPermissionToRole: builder.mutation({
      query: ({ tierId, roleId, permissions }) => ({
        url: `update-tier-permission-to-role`,
        method: "PUT",
        body: { tierId, roleId, permissions },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Role", id: "LIST" },
        { type: "TierPermission", id: "LIST" },
      ],
    }),
    addPermissionsToTierPermCollection: builder.mutation({
      query: ({ tierPermIds, permissions }) => ({
        url: `add-permissions-to-tier-perm-collection`,
        method: "PUT",
        body: { tierPermIds, permissions },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Role", id: "LIST" },
        { type: "TierPermission", id: "LIST" },
      ],
    }),
    removePermissionsFromTierPermCollection: builder.mutation({
      query: ({ tierPermIds, permissions }) => ({
        url: `remove-permissions-from-tier-perm-collection`,
        method: "PUT",
        body: { tierPermIds, permissions },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Role", id: "LIST" },
        { type: "TierPermission", id: "LIST" },
      ],
    }),
    removeTierPermission: builder.mutation({
      query: ({ tierPermId }) => ({
        url: `delete-one/${tierPermId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "TierPermission", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAllTierPermissionsQuery,
  useGetAllTierPermissionItemsByUserRoleQuery,
  useCheckTierPermissionForUserRoleQuery,
  useUpdateTierPermissionMutation,
  useUpdateTierPermissionToRoleMutation,
  useRemoveTierPermissionMutation,
  useAddPermissionsToTierPermCollectionMutation,
  useRemovePermissionsFromTierPermCollectionMutation,
} = tierPermissionApi;

export default tierPermissionApi;
export { tierPermissionApi };
