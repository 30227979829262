import React from "react";
import { Button } from "@mui/material"; // Assuming you're using Material-UI for buttons

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error("An error occurred:", error, errorInfo);
    this.setState({ error: error, errorInfo: errorInfo });
  }

  handleReload = () => {
    window.location.reload();
  };

  render() {
    // Only use the ErrorBoundary in production
    if (process.env.REACT_APP_ENV === "development") {
      return this.props.children;
    }
    if (this.state.hasError) {
      // Construct the email link with subject and body
      const mailtoLink = `mailto:support@reacirc.com?subject=Error Occurred in REACirc&body=Dear Support,%0D%0A%0D%0AA critical error occurred in REACirc and I need assistance.%0D%0A%0D%0AHere are the details for debugging:%0D%0AError Message: ${encodeURIComponent(
        this.state.error?.toString() || ""
      )}%0D%0AError Stack: ${encodeURIComponent(
        this.state.errorInfo?.componentStack || ""
      )}%0D%0A%0D%0APlease get back to me as soon as possible.%0D%0AThank you.`;

      // User-friendly fallback UI
      return (
        <div
          style={{ textAlign: "center", padding: "20px", marginTop: "50px" }}
        >
          <h1>Oops, something went wrong...</h1>
          <p>
            We're sorry for the inconvenience. Please try reloading the page. If
            the problem persists, you can email us for support.
          </p>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleReload}
            style={{ marginRight: "10px" }}
          >
            Reload Page
          </Button>
          <Button variant="contained" color="secondary">
            <a
              href={mailtoLink}
              style={{ color: "white", textDecoration: "none" }}
            >
              Email Support
            </a>
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
