import React, { useState } from "react";
import {
  useAssignTierToMultipleRolesMutation,
  useRemoveTierFromMultipleRolesMutation,
} from "../../../services/tier";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import { defaultPermissions } from "./DefaultPermissions";
import { Delete } from "@mui/icons-material";

const AssignTierToMembersDialog = ({
  selectedTier,
  open,
  onClose,
  members,
  selectedMembers,
  mode = "assign",
}) => {
  const [
    assignTierToMultipleRoles,
    { isLoading: isAssignTierToMultipleRolesLoading },
  ] = useAssignTierToMultipleRolesMutation();
  const [
    removeTierFromMultipleRoles,
    { isLoading: isRemoveTierFromMultipleRolesLoading },
  ] = useRemoveTierFromMultipleRolesMutation();

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [permissions, setPermissions] = useState([]);
  const [newPermission, setNewPermission] = useState("");

  const handleAddPermission = () => {
    if (newPermission && !permissions.includes(newPermission)) {
      setPermissions([...permissions, newPermission]);
      setNewPermission("");
    }
  };

  const handleRemovePermission = (permission) => {
    setPermissions(permissions.filter((perm) => perm !== permission));
  };

  const toggleDefaultPermissions = () => {
    const currentPermissions = new Set(permissions);
    if (defaultPermissions.every((perm) => currentPermissions.has(perm))) {
      setPermissions(
        permissions.filter((perm) => !defaultPermissions.includes(perm))
      );
    } else {
      setPermissions([...new Set([...permissions, ...defaultPermissions])]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedRoleIds = members
      .filter((member) => selectedMembers?.includes(member?._id))
      .map((member) => member?.role?._id);

    if (!selectedRoleIds || !selectedTier?._id) {
      setNotification({
        open: true,
        message: "There are no members or tier id.",
      });
      return;
    }
    if (mode === "assign" && permissions && permissions?.length === 0) {
      setNotification({
        open: true,
        message: "There are no permissions.",
      });
      return;
    }
    let result;
    if (mode === "assign") {
      result = await assignTierToMultipleRoles({
        tierId: selectedTier?._id,
        roleIds: selectedRoleIds,
        permissions,
      });
    } else if (mode === "remove") {
      result = await removeTierFromMultipleRoles({
        tierId: selectedTier?._id,
        roleIds: selectedRoleIds,
      });
    }

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (mode === "assign") {
          setPermissions([]);
        }
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error: ${result?.error?.data?.msg || "Unknown error"}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth className="edit-post">
      <DialogTitle>
        <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }}>
          {mode === "assign"
            ? "Assign Tier To Members"
            : "Remove Tier From Members"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {mode === "assign" && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="New Permission"
                  variant="outlined"
                  value={newPermission}
                  onChange={(e) => setNewPermission(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && handleAddPermission()}
                  fullWidth
                />
                <Button
                  onClick={handleAddPermission}
                  color="primary"
                  style={{ marginTop: "8px" }}
                >
                  Add Permission
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={toggleDefaultPermissions}
                  color="secondary"
                  style={{ marginBottom: "8px" }}
                >
                  Toggle Default Permissions
                </Button>
              </Grid>
              <Grid item xs={12}>
                <List dense>
                  {permissions?.map((perm, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={perm} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemovePermission(perm)}
                        >
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          )}
          {mode === "remove" && (
            <Typography sx={{ fontWeight: 500, fontSize: "1rem" }}>
              Are you sure you want to remove{" "}
              <strong>{selectedTier.brokerage?.officeShortName}</strong>
              with permissions From {selectedMembers?.length} Members
            </Typography>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Grid item>
          <Button onClick={onClose} color="secondary" type="submit" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={
              (mode === "assign" && isAssignTierToMultipleRolesLoading) ||
              (mode === "remove" && isRemoveTierFromMultipleRolesLoading)
            }
          >
            {(mode === "assign" && isAssignTierToMultipleRolesLoading) ||
            (mode === "remove" && isRemoveTierFromMultipleRolesLoading) ? (
              <CircularProgress size={24} />
            ) : mode === "assign" ? (
              "Assign"
            ) : (
              "Remove"
            )}
          </Button>
        </Grid>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AssignTierToMembersDialog;
