import React, { useState } from "react";
import {
  useGetAllListingsQuery,
  useDeleteAllListingsMutation,
} from "../../../services/listings";
import {
  Paper,
  Typography,
  Divider,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import ListingTourDataGridList from "./ListingTourDataGridList";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import { ConfirmationDialog } from "../..";

function ListingList() {
  const { data: listings } = useGetAllListingsQuery();
  const [deleteAllListings, { isLoading: isDeleteAllListingsLoading }] =
    useDeleteAllListingsMutation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleDeleteClick = async () => {
    setIsConfirmDialogOpen(true);
  };

  const handleDeleteAllListings = async () => {
    const result = await deleteAllListings();
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setIsConfirmDialogOpen(false);
    } else {
      setNotification({
        open: true,
        message: `Error Deletinge All Listing Tour Items. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <div>
      <Paper sx={{ p: 1, mb: 2, display: "flex", flexDirection: "column" }}>
        <Typography variant="h6" align="center" gutterBottom>
          Listings List
        </Typography>
        <Divider />
        <Box
          sx={{ p: 0, mt: 1, display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteClick}
            disabled={
              !listings || listings?.length === 0 || isDeleteAllListingsLoading
            }
          >
            {isDeleteAllListingsLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Delete All Listings"
            )}
          </Button>
        </Box>
      </Paper>
      <ListingTourDataGridList listings={listings} />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={handleDeleteAllListings}
        title="Confirm Deletion"
        description="Are you sure you want to delete all listings? This action cannot be undone."
      />
    </div>
  );
}

export default ListingList;
