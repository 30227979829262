import React, { useState, useContext, useMemo } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import {
  useGetAllTierPermissionsQuery,
  useRemoveTierPermissionMutation,
} from "../../../../services/tier-permission";
import { useGetAllUserRoleNameEnumsQuery } from "../../../../services/user-role-name-enum";
import {
  DataGridPro,
  useGridApiRef,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import {
  Paper,
  Tooltip,
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import { Delete } from "@mui/icons-material/";
import { ConfirmationDialog } from "../../..";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import AddOrRemovePermissionsToTierPermDialog from "./AddOrRemovePermisisonsToTierPermDialog";

function TierPermissionsDatagridList({ tiers }) {
  const { accountType, userId } = useContext(AuthContext);
  const [deleteTier] = useRemoveTierPermissionMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const apiRef = useGridApiRef();

  const [currentTier, setCurrentTier] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [tierFilter, setTierFilter] = useState("");
  const [userAccountType, setUserAccountType] = useState("");
  const [userRoleName, setUserRoleName] = useState("");
  const [userNameSearch, setUserNameSearch] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const { data, isLoading } = useGetAllTierPermissionsQuery(
    {
      tierId: tierFilter,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      userAccountType,
      userRoleName,
      userNameSearch,
    },
    {
      skip: !userId && accountType !== "reosadmin",
      keepUnusedDataFor: 60,
    }
  );
  const { data: userRoleNameEnums } = useGetAllUserRoleNameEnumsQuery(
    accountType,
    {
      skip: !userId && accountType !== "reosadmin",
    }
  );

  const [isTierPermissionDialogOpen, setIsTierPermissionDialogOpen] =
    useState(false);
  const [tierPermissionDialogMode, setTierPermissionDialogMode] =
    useState("add");

  const handleDeleteTierClick = (tierId) => {
    setCurrentTier(tierId);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
    setCurrentTier(null);
  };

  const handleConfirmationDialogConfirm = async () => {
    if (currentTier) {
      const result = await deleteTier({
        tierPermId: currentTier,
      });

      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        setConfirmationDialogOpen(false);
        setCurrentTier(null);
      } else {
        setNotification({
          open: true,
          message: `Error deleting Tier. ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        field: "userRoleName",
        headerName: "User Role & Name",
        flex: 1,
        minWidth: 200,
        sortable: true,
        renderCell: (params) => {
          const user = params?.row?.role?.user;
          const roleName = params?.row?.role?.name;
          if (user && roleName) {
            return (
              <div>
                <Typography variant="body2">
                  {user?.firstName} {user?.lastName} - {user?.email}
                </Typography>
                <Typography variant="body2">
                  account type: {user?.accountType}
                </Typography>
                <Typography variant="body2">role: {roleName}</Typography>
              </div>
            );
          }
          return "N/A";
        },
      },
      {
        field: "tierPermission",
        headerName: "Tier Permission",
        flex: 1,
        minWidth: 250,
        sortable: true,
        valueGetter: (value, row) => {
          const tier = row?.tier;
          if (!tier) return "N/A";

          let tierInfo = tier?.level;

          if (tier?.brokerage?.officeName) {
            tierInfo += ` ${tier?.brokerage?.officeName}`;
          } else if (tier?.team?.name) {
            tierInfo += ` ${tier?.team?.name}`;
          } else if (tier?.group?.name) {
            tierInfo += ` ${tier?.group?.name}`;
          }

          if (tier?.level === "system" || tier?.level === "global") {
            tierInfo += " - REACirc";
          }

          if (tier?._id === tier?.brokerage?.leadBrokerageTier) {
            tierInfo += " - (Lead Brokerage Office)";
          }

          if (tier?._id === tier?.brokerage?.tier) {
            tierInfo += " - (Office)";
          }

          return tierInfo;
        },
      },
      {
        field: "permissions",
        headerName: "Permissions",
        flex: 1,
        minWidth: 200,
        sortable: false,
        renderCell: (params) => {
          const permissions = params?.row?.permissions;
          if (!permissions || permissions?.length === 0) return "N/A";

          return (
            <Box>
              {permissions?.map((perm) => (
                <Typography key={perm?._id} variant="body2">
                  • {perm?.perm}
                </Typography>
              ))}
            </Box>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 100,
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <Tooltip title="Delete">
                <Delete color="error" />
              </Tooltip>
            }
            label="Delete"
            onClick={() => handleDeleteTierClick(params?.id)}
          />,
        ],
      },
    ],
    [handleDeleteTierClick]
  );

  const rows = useMemo(() => {
    return (data?.tierPermissions || []).map((tierPerm) => ({
      id: tierPerm?._id || "",
      ...tierPerm,
    }));
  }, [data?.tierPermissions]);

  const tierOptions = useMemo(() => {
    if (!tiers) return [];

    return tiers?.map((tier) => ({
      id: tier?._id,
      label: `${tier?.level} ${
        tier?.brokerage?.officeName ||
        tier?.team?.name ||
        tier?.group?.name ||
        ""
      } ${
        tier?.level === "system" || tier?.level === "global" ? " - REACirc" : ""
      } ${
        tier?._id === tier?.brokerage?.leadBrokerageTier
          ? " - (Lead Brokerage Office)"
          : ""
      } ${tier?._id === tier?.brokerage?.tier ? " - (Office)" : ""}`,
    }));
  }, [tiers]);

  const handleOpenAddTierPermissionDialog = () => {
    setTierPermissionDialogMode("add");
    setIsTierPermissionDialogOpen(true);
  };

  const handleOpenRemoveTierPermissionDialog = () => {
    setTierPermissionDialogMode("remove");
    setIsTierPermissionDialogOpen(true);
  };

  const handleCloseTierPermissionDialog = () => {
    setIsTierPermissionDialogOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const heightOfDataGridContainer =
    rows && rows?.length > 0 && rows?.length < 5
      ? "25rem"
      : rows?.length > 5 && rows?.length < 10
      ? "35rem"
      : rows?.length > 10
      ? "55rem"
      : "25rem";
  // console.log("tierPermissions", tierPermissions);
  // console.log("");

  return (
    <div className="tier-perm-data-grid">
      <Paper className="tier-perm-data-grid-header">
        <Box
          sx={{ p: 1, mt: 1, display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={handleOpenAddTierPermissionDialog}
            disabled={!selectionModel.length}
            sx={{ mr: 1 }}
          >
            Add To Selected Tier Permisisons New Permisisons
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenRemoveTierPermissionDialog}
            disabled={!selectionModel.length}
          >
            Remove From Selected Tier Permisisons Some Permisisons
          </Button>
        </Box>
      </Paper>
      <div className="tier-perm-data-grid-filter">
        <div className="tier-perm-data-grid-filter-item">
          <FormControl variant="outlined" size="small" sx={{ width: "100%" }}>
            <InputLabel id="tier-filter-label">Filter by Tier</InputLabel>
            <Select
              labelId="tier-filter-label"
              id="tier-filter"
              value={tierFilter}
              onChange={(e) => {
                setTierFilter(e.target.value);
              }}
              label="Filter by Tier"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {tierOptions?.map((tier) => (
                <MenuItem key={tier?.id} value={tier?.id}>
                  {tier?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="tier-perm-data-grid-filter-item">
          <TextField
            label="Search by User Name or Email"
            variant="outlined"
            size="small"
            value={userNameSearch}
            onChange={(e) => setUserNameSearch(e.target.value)}
            fullWidth
            sx={{ width: "100%" }}
          />
        </div>
        <div className="tier-perm-data-grid-filter-item">
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            sx={{ width: "100%" }}
          >
            <InputLabel id="user-accoun-type-filter-label">
              Filter by User Account Type
            </InputLabel>
            <Select
              labelId="user-accoun-type-filter-label"
              id="user-accoun-type-filter"
              value={userAccountType}
              onChange={(e) => setUserAccountType(e.target.value)}
              label="Filter by User Account Type"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              <MenuItem value="reosadmin">REOS Admin</MenuItem>
              <MenuItem value="brokerage">Brokerage</MenuItem>
              <MenuItem value="member">Member</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="tier-perm-data-grid-filter-item">
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            sx={{ width: "100%" }}
          >
            <InputLabel id="user-role-filter-label">
              Filter by User Role
            </InputLabel>
            <Select
              labelId="user-role-filter-label"
              id="user-role-filter"
              value={userRoleName}
              onChange={(e) => setUserRoleName(e.target.value)}
              label="Filter by User Role"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {userRoleNameEnums?.map((tier) => (
                <MenuItem key={tier?._id} value={tier?.userRoleName}>
                  {tier?.userRoleName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {rows && rows?.length > 0 ? (
        <div className="tier-perm-data-grid-body">
          <DataGridPro
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            paginationModel={paginationModel}
            onPaginationModelChange={(model) => {
              console.log("Pagination Model Changed:", model);
              setPaginationModel(model);
            }}
            rowsPerPageOptions={[25, 50, 100]}
            pagination
            paginationMode="server"
            rowCount={data?.total || 0}
            loading={isLoading}
            checkboxSelection
            disableSelectionOnClick
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={(newSelection) => {
              setSelectionModel(newSelection);
              console.log("setSelectionModel Rows:", newSelection);
            }}
            sx={{ height: heightOfDataGridContainer }}
            getRowId={(row) => row?.id}
            getRowHeight={() => "auto"}
            estimatedRowCount={data?.total || 0}
          />
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            py: 5,
          }}
        >
          <Typography variant="h6" gutterBottom>
            No Tier Permisisons available
          </Typography>
        </Box>
      )}
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmationDialogConfirm}
        title="Confirm Deleting the Tier Permission"
        description="Are you sure you want to delete this Tier Permission permanently?"
      />
      <AddOrRemovePermissionsToTierPermDialog
        open={isTierPermissionDialogOpen}
        onClose={handleCloseTierPermissionDialog}
        selectedTierPermIds={selectionModel}
        mode={tierPermissionDialogMode}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </div>
  );
}

export default TierPermissionsDatagridList;
