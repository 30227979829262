import React, { useState, useEffect, memo } from "react";
import { useLazyDownloadVideoQuery } from "../../services/post";
import {
  CardMedia,
  Typography,
  Box,
  Link as LinkComponent,
} from "@mui/material";
import { GetApp } from "@mui/icons-material/";

const PostVideos = ({ videos, isPostPage = "false" }) => {
  const [videoUrls, setVideoUrls] = useState({});
  const [, setSelectedVideoId] = useState("");

  const [trigger, { data: downloadedVideo }] = useLazyDownloadVideoQuery();

  useEffect(() => {
    if (videos && videos?.length > 0) {
      videos?.forEach((video) => {
        if (video && video?._id && !videoUrls[video?._id]) {
          trigger({ videoId: video?._id }).then((result) => {
            if (result?.data?.url) {
              setVideoUrls((prev) => ({
                ...prev,
                [video._id]: result.data?.url,
              }));
            }
          });
        }
      });
    }
  }, [videos, trigger]);

  const handleDownloadClick = async (video) => {
    setSelectedVideoId(video._id);
    trigger({ videoId: video._id });
    if (downloadedVideo && downloadedVideo.url) {
      window.open(downloadedVideo.url, "_blank");
      setSelectedVideoId("");
    }
  };

  const videosToDisplay = isPostPage ? videos : videos?.slice(0, 1);

  return (
    <Box
      sx={{
        backgroundColor: "var(--bg-color)",
        p: 1,
        mt: 0,
        mb: 3,
        borderRadius: 1,
      }}
    >
      <Typography variant="h6" sx={{ mb: 1 }}>
        Post Videos:
      </Typography>
      <Box>
        {videosToDisplay?.map((video) => {
          const urlParts = video && video?.url && video?.url?.split("/");
          const fileName = urlParts && urlParts[urlParts?.length - 1];
          const videoUrl = videoUrls[video?._id];

          return (
            <Typography key={video?._id} variant="body2">
              {videoUrl && (
                <CardMedia
                  component="video"
                  controls
                  muted
                  sx={{ width: "100%", borderRadius: 1, marginBottom: 2 }}
                >
                  <source src={videoUrl} type="video/mp4" />
                </CardMedia>
              )}
              <LinkComponent
                onClick={() => handleDownloadClick(video)}
                underline="hover"
                color="primary"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  cursor: "pointer",
                }}
              >
                <GetApp />
                {decodeURIComponent(fileName)}
              </LinkComponent>
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

export default memo(PostVideos);
