import React from "react";
import { Container, Typography, Paper, Divider } from "@mui/material";
import ListingTourManagementTabs from "./ListingTourManagementTabs";

const ListingTourManagement = () => {
  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Listing Tour Management
        </Typography>
        <Divider />
      </Paper>
      <ListingTourManagementTabs />
    </Container>
  );
};

export default ListingTourManagement;
