import React, { useState } from "react";
import { useDeleteRolePermissionEnumMutation } from "../../../services/role-permission-enum";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Box,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { DeleteForever, Edit } from "@mui/icons-material/";
import ConfirmationDialog from "../../helpers/ConfirmationDialog";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CreateEditRolePermissionEnumForm from "./CreateEditRolePermissionEnumForm";

function RolePermissionEnumsList({
  rolePermissionEnums,
  refetch,
  isLoadingPermissions,
}) {
  const [deleteRolePermissionEnum] = useDeleteRolePermissionEnumMutation();
  const [currentRolePermissionEnum, setCurrentRolePermissionEnum] =
    useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [openEditRolePermissionDialog, setOpenEditRolePermissionDialog] =
    useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleOpenEditRolePermissionDialog = (rolePermissionEnum) => {
    setCurrentRolePermissionEnum(rolePermissionEnum);
    setOpenEditRolePermissionDialog(true);
  };

  const handleCloseEditRolePermissionDialog = () => {
    setOpenEditRolePermissionDialog(false);
    setCurrentRolePermissionEnum(null);
  };

  const handleDeleteClick = (rolePermissionEnum) => {
    setCurrentRolePermissionEnum(rolePermissionEnum);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
    setCurrentRolePermissionEnum(null);
  };

  const handleConfirmDelete = async () => {
    if (currentRolePermissionEnum) {
      const result = await deleteRolePermissionEnum(
        currentRolePermissionEnum._id
      );
      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        setConfirmationDialogOpen(false);
        refetch();
      } else {
        setNotification({
          open: true,
          message: `Error deleting Role Permission Enum. ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (!rolePermissionEnums || rolePermissionEnums?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
        }}
      >
        <Typography variant="h5" gutterBottom>
          No Role Permission Enums available
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Role Name</TableCell>
              <TableCell>Role Permission</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rolePermissionEnums?.map((rpe) => (
              <TableRow key={rpe?._id}>
                <TableCell>{rpe?.userRoleName?.userRoleName}</TableCell>
                <TableCell>{rpe?.rolePermission}</TableCell>
                <TableCell align="right">
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Tooltip title="Edit Role Permission Enum">
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleOpenEditRolePermissionDialog(rpe)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Role Permission Enum">
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteClick(rpe)}
                      >
                        <DeleteForever />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
            {isLoadingPermissions && <CircularProgress />}
          </TableBody>
        </Table>
      </TableContainer>
      {currentRolePermissionEnum && (
        <CreateEditRolePermissionEnumForm
          mode="edit"
          rolePermissionEnum={currentRolePermissionEnum}
          open={openEditRolePermissionDialog}
          onClose={handleCloseEditRolePermissionDialog}
          setNotification={setNotification}
          refetch={refetch}
        />
      )}
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        description="Are you sure you want to delete this Role Permission Enum? This action cannot be undone."
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default RolePermissionEnumsList;
