import React, { useContext, useState } from "react";
import {
  useGetAllDutyScheduleQuery,
  useDeleteAllDutyScheduleMutation,
} from "../../../services/duty-schedule";
import { useGetAllTiersQuery } from "../../../services/tier";
import { AuthContext } from "../../../context/AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Box,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { AccessTime, CalendarToday } from "@mui/icons-material/";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import { formatDateToDayMonthYear } from "../../helpers/utils";
import { ConfirmationDialog } from "../..";

function DutyScheduleList() {
  const { accountType, userId } = useContext(AuthContext);
  const { data: tiers } = useGetAllTiersQuery(accountType, {
    skip: !userId && accountType !== "reosadmin",
  });
  const { data: dutySchedules } = useGetAllDutyScheduleQuery();
  const [selectedTier, setSelectedTier] = useState(null);
  const [
    deleteAllDutySchedules,
    { isLoading: isDeleteAllDutyScheduleLoading },
  ] = useDeleteAllDutyScheduleMutation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleDeleteClick = async () => {
    setIsConfirmDialogOpen(true);
  };

  const handleDeleteAllDutySchedules = async () => {
    const result = await deleteAllDutySchedules();
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setIsConfirmDialogOpen(false);
    } else {
      setNotification({
        open: true,
        message: `Error deleting duties. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <div className="management-page-body">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Duties Management
        </Typography>
        <Divider />
        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteClick}
            disabled={
              !dutySchedules ||
              dutySchedules?.length === 0 ||
              isDeleteAllDutyScheduleLoading
            }
          >
            {isDeleteAllDutyScheduleLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Delete All Duty Schedules"
            )}
          </Button>
        </Box>
      </Paper>
      <Box>
        <Autocomplete
          value={selectedTier}
          onChange={(event, newValue) => {
            setSelectedTier(newValue);
          }}
          options={tiers || []}
          getOptionLabel={(option) =>
            option
              ? `${option?.level} ${
                  option?.brokerage || option?.team || option?.group
                    ? option?.brokerage?.officeName ||
                      option?.team?.name ||
                      option?.group?.name
                    : ""
                } ${
                  option?.level === "system" || option?.level === "global"
                    ? " - REACirc"
                    : ""
                } ${
                  option?._id === option?.brokerage?.leadBrokerageTier
                    ? " - (Lead Brokerage Office)"
                    : ""
                } ${
                  option?._id === option?.brokerage?.tier ? " - (Office)" : ""
                }`
              : ""
          }
          isOptionEqualToValue={(option, value) => option?._id === value._id}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a Tier"
              variant="outlined"
              margin="normal"
              required
            />
          )}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Office Hours</TableCell>
              <TableCell>Operating Days</TableCell>
              <TableCell>People in Office</TableCell>
              <TableCell>Skipped Date-Time Slots</TableCell>
              <TableCell>Holidays</TableCell>
              <TableCell>Allow Duty Swapping</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dutySchedules &&
              dutySchedules.length > 0 &&
              dutySchedules?.map((dutySchedule, index) => (
                <TableRow key={`${dutySchedule._id}-${index}`}>
                  <TableCell>
                    <div style={{ marginLeft: "20px" }}>
                      <AccessTime fontSize="small" />{" "}
                      <Typography
                        component="div"
                        variant="body1"
                        className="data-item"
                      >
                        <strong>Start:</strong>{" "}
                        {(dutySchedule &&
                          dutySchedule?.officeHours &&
                          dutySchedule?.officeHours?.start) ||
                          ""}
                      </Typography>
                      <Typography
                        component="div"
                        variant="body1"
                        className="data-item"
                      >
                        <strong>End:</strong>{" "}
                        {(dutySchedule &&
                          dutySchedule?.officeHours &&
                          dutySchedule?.officeHours?.end) ||
                          ""}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Typography
                      component="div"
                      variant="body1"
                      className="data-item"
                    >
                      {dutySchedule && dutySchedule?.operatingDays?.length > 0
                        ? dutySchedule.operatingDays.join(", ")
                        : "No operating days set."}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <div style={{ marginLeft: "20px" }}>
                      <Typography
                        component="div"
                        variant="body1"
                        className="data-item"
                      >
                        <strong>Min:</strong>{" "}
                        {(dutySchedule && dutySchedule?.minPeopleInOffice) || 1}
                      </Typography>
                      <Typography
                        component="div"
                        variant="body1"
                        className="data-item"
                      >
                        <strong>Max:</strong>{" "}
                        {(dutySchedule && dutySchedule?.maxPeopleInOffice) ||
                          "No limit"}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ marginLeft: "20px" }}>
                      {dutySchedule?.skipDateTimeSlots?.length > 0 ? (
                        dutySchedule?.skipDateTimeSlots?.map((slot, index) => (
                          <Typography
                            key={index}
                            component="div"
                            variant="body1"
                            className="data-item"
                          >
                            <strong>Start:</strong>{" "}
                            {formatDateToDayMonthYear(slot?.startDate)}{" "}
                            <strong>End:</strong>{" "}
                            {formatDateToDayMonthYear(slot?.endDate)}
                          </Typography>
                        ))
                      ) : (
                        <Typography variant="body1" className="data-item">
                          No skipped date-time slots.
                        </Typography>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ marginLeft: "20px" }}>
                      {dutySchedule?.holidays?.length > 0 ? (
                        dutySchedule?.holidays?.map((holiday, index) => (
                          <Typography
                            key={index}
                            component="div"
                            variant="body1"
                            className="data-item"
                          >
                            <CalendarToday fontSize="small" />{" "}
                            {formatDateToDayMonthYear(holiday?.date)}
                            {holiday?.description && (
                              <>
                                {" "}
                                <strong>Description:</strong>{" "}
                                {holiday?.description}
                              </>
                            )}
                          </Typography>
                        ))
                      ) : (
                        <Typography variant="body1" className="data-item">
                          No holidays set.
                        </Typography>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Typography
                      component="div"
                      variant="body1"
                      className="data-item"
                    >
                      {dutySchedule?.allowDutySwapping ? "Yes" : "No"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {(!dutySchedules || dutySchedules?.length === 0) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 20,
          }}
        >
          <Typography variant="h5" gutterBottom>
            No Duty Schedules available
          </Typography>
        </Box>
      )}
      <Divider style={{ margin: "20px 0" }} />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={handleDeleteAllDutySchedules}
        title="Confirm Deletion"
        description="Are you sure you want to delete all Duty Schedules? This action cannot be undone."
      />
    </div>
  );
}

export default DutyScheduleList;
