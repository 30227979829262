import React, { useState } from "react";
import {
  useAddPermissionsToTierPermCollectionMutation,
  useRemovePermissionsFromTierPermCollectionMutation,
} from "../../../../services/tier-permission";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import { allTierPermissions } from "../DefaultPermissions";

const AddOrRemovePermisisonsToTierPermDialog = ({
  open,
  onClose,
  selectedTierPermIds,
  mode = "add",
}) => {
  const [
    addPermissionsToTierPermCollection,
    { isLoading: isAddPermissionsLoading },
  ] = useAddPermissionsToTierPermCollectionMutation();
  const [
    removePermissionsFromTierPermCollection,
    { isLoading: isRemovePermissionsLoading },
  ] = useRemovePermissionsFromTierPermCollectionMutation();

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [permissions, setPermissions] = useState([]);

  const toggleAllPermissions = () => {
    const currentPermissions = new Set(permissions);
    if (allTierPermissions.every((perm) => currentPermissions.has(perm))) {
      setPermissions(
        permissions.filter((perm) => !allTierPermissions.includes(perm))
      );
    } else {
      setPermissions([...new Set([...permissions, ...allTierPermissions])]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !selectedTierPermIds ||
      !selectedTierPermIds.length ||
      selectedTierPermIds.length === 0
    ) {
      setNotification({
        open: true,
        message: "There are no tier permissions selected.",
      });
      return;
    }
    if (permissions && permissions?.length === 0) {
      setNotification({
        open: true,
        message: `There are no permissions ${
          mode === "add" ? "to add" : mode === "remove" ? "to remove" : ""
        }`,
      });
      return;
    }

    let result;
    if (mode === "add") {
      result = await addPermissionsToTierPermCollection({
        tierPermIds: selectedTierPermIds,
        permissions,
      });
    } else if (mode === "remove") {
      result = await removePermissionsFromTierPermCollection({
        tierPermIds: selectedTierPermIds,
        permissions,
      });
    }

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        setPermissions([]);
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error: ${result?.error?.data?.msg || "Unknown error"}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth className="edit-post">
      <DialogTitle>
        <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }}>
          {mode === "add"
            ? "Assign Permissions to Selected Tier Permissions"
            : "Remove Permissions from Selected Tier Permissions"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {mode === "remove" && (
              <Grid item xs={12}>
                <Typography variant="body1">
                  Are you sure you want to remove the following permissions from
                  selected Tier Permissions Collections?
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Select Permissions to {`${mode === "add" ? " Add" : " Remove"}`}
              </Typography>
              <Autocomplete
                multiple
                options={allTierPermissions}
                getOptionLabel={(option) => option}
                value={permissions}
                onChange={(event, newValue) => setPermissions(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Permissions"
                    placeholder="Select permissions"
                  />
                )}
              />
            </Grid>
            {mode === "add" && (
              <Grid item xs={12}>
                <Button
                  onClick={toggleAllPermissions}
                  color="secondary"
                  style={{ marginBottom: "8px" }}
                >
                  Toggle All Permissions
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid item>
          <Button onClick={onClose} color="secondary" type="button" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={
              (mode === "add" && isAddPermissionsLoading) ||
              (mode === "remove" && isRemovePermissionsLoading)
            }
          >
            {(mode === "add" && isAddPermissionsLoading) ||
            (mode === "remove" && isRemovePermissionsLoading) ? (
              <CircularProgress size={24} color="inherit" />
            ) : mode === "add" ? (
              "Assign Permissions"
            ) : (
              "Remove Permissions"
            )}
          </Button>
        </Grid>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AddOrRemovePermisisonsToTierPermDialog;
