import React, { useState, useEffect } from "react";
import {
  IconButton,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Switch,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Paper,
} from "@mui/material";
import { Delete, BuildCircle, Add } from "@mui/icons-material";
import {
  useCreateDutyScheduleMutation,
  useUpdateDutyScheduleMutation,
  useSetupMembersDefaultDutyPreferencesMutation,
} from "../../../../services/duty-schedule";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MembersDefaultDutyPreferencesDialog from "./MembersDefaultDutyPreferencesDialog";
import dayjs from "dayjs";

function CreateEditDutyScheduleForm({
  open,
  onClose,
  tierId,
  mode = "create",
  dutySchedule,
}) {
  const [createDutySchedule, { isLoading: isCreateLoading }] =
    useCreateDutyScheduleMutation();
  const [updateDutySchedule, { isLoading: isUpdateLoading }] =
    useUpdateDutyScheduleMutation();
  const [
    setupMembersDefaultDutyPreferences,
    { isLoading: isUpdateMembersDefaultDutyPreferencesLoading },
  ] = useSetupMembersDefaultDutyPreferencesMutation();

  const [formData, setFormData] = useState({
    title: "",
    dutyScheduleType: ["Office", "Phone"],
    dutyScheduleLocation: "Main Office",
    dailyTimeSlots: {},
    skipDateTimeSlots: [],
    holidays: [],
    minPeopleInOffice: 1,
    maxPeopleInOffice: 1,
    allowDutySwapping: true,
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [timeSlots, setTimeSlots] = useState([
    {
      id: 1,
      startTime: "",
      startPeriod: "AM",
      endTime: "",
      endPeriod: "AM",
      dutyType: "Office",
    },
  ]);

  const [selectedDays, setSelectedDays] = useState([]);
  const [generationStartDate, setGenerationStartDate] = useState(null);
  const [generationEndDate, setGenerationEndDate] = useState(null);
  const [shiftLength, setShiftLength] = useState(2);
  const [
    membersDefaultDutyPreferencesDialogOpen,
    setMembersDefaultDutyPreferencesDialogOpen,
  ] = useState(false);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [generationType, setGenerationType] = useState(
    dayjs().format("YYYY-MM")
  );

  // Update this function to get current and next 4 month names
  const getMonthOptions = () => {
    const options = [];
    for (let i = 0; i < 5; i++) {
      const date = dayjs().add(i, "month");
      options.push({
        value: date.format("YYYY-MM"),
        label: date.format("MMMM YYYY"),
      });
    }
    return options;
  };

  const monthOptions = getMonthOptions();

  useEffect(() => {
    if (mode === "edit" && dutySchedule) {
      setFormData(dutySchedule);
    }
  }, [mode, dutySchedule]);

  const handleNewTimeSlotChange = (id, field, value) => {
    setTimeSlots((prevSlots) =>
      prevSlots.map((slot) => {
        if (slot.id === id) {
          let updatedSlot = { ...slot, [field]: value };

          if (field === "startTime" && value.includes(":")) {
            const [startHours, startMinutes] = value.split(":").map(Number);
            if (startHours !== undefined && startMinutes !== undefined) {
              updatedSlot.startPeriod = determineDefaultPeriod(startHours);

              // Calculate end time based on shift length
              const shiftMinutes = Math.floor(shiftLength * 60);
              const totalMinutes =
                startHours * 60 + startMinutes + shiftMinutes;

              let endHours = Math.floor(totalMinutes / 60) % 24;
              let endMinutes = totalMinutes % 60;

              let endPeriod = updatedSlot.startPeriod;
              if (endHours >= 12 && updatedSlot.startPeriod === "AM") {
                endPeriod = "PM";
              } else if (endHours < 12 && updatedSlot.startPeriod === "PM") {
                endPeriod = "AM";
              }

              if (endHours > 12) endHours -= 12;
              if (endHours === 0) endHours = 12;

              updatedSlot.endTime = `${endHours}:${endMinutes
                .toString()
                .padStart(2, "0")}`;
              updatedSlot.endPeriod = endPeriod;
            }
          }

          if (field === "endTime" && value.includes(":")) {
            const [hours] = value.split(":");
            if (hours) {
              updatedSlot.endPeriod = determineDefaultPeriod(hours);
            }
          }

          return updatedSlot;
        }
        return slot;
      })
    );
  };

  const handleAddTimeSlot = () => {
    setTimeSlots((prevSlots) => {
      const lastSlot = prevSlots[prevSlots.length - 1];
      const newStartTime = lastSlot.endTime;
      const newStartPeriod = lastSlot.endPeriod;

      if (!newStartTime) return prevSlots;
      // Calculate new end time based on shift length
      let [hours, minutes] = newStartTime.split(":").map(Number);

      // Convert shiftLength to minutes
      const shiftMinutes = Math.floor(shiftLength * 60);
      const totalMinutes = hours * 60 + minutes + shiftMinutes;

      hours = Math.floor(totalMinutes / 60) % 24;
      minutes = totalMinutes % 60;

      let newEndPeriod = newStartPeriod;
      if (newStartPeriod === "PM") newEndPeriod = "PM";

      if (hours >= 12 && newStartPeriod === "AM") {
        newEndPeriod = "PM";
      } else if (newStartPeriod === "PM" && hours > 12) {
        newEndPeriod = "AM";
      }

      if (hours > 12) hours -= 12;
      if (hours === 0) hours = 12;

      const newEndTime = `${hours}:${minutes.toString().padStart(2, "0")}`;

      return [
        ...prevSlots,
        {
          id: prevSlots.length + 1,
          startTime: newStartTime,
          startPeriod: newStartPeriod,
          endTime: newEndTime,
          endPeriod: newEndPeriod,
          dutyType: lastSlot.dutyType,
        },
      ];
    });
  };

  const handleRemoveTimeSlotRow = (id) => {
    setTimeSlots((prevSlots) => prevSlots.filter((slot) => slot.id !== id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.title ||
      !formData.dutyScheduleType?.length ||
      !formData.dutyScheduleLocation ||
      timeSlots.length === 0 ||
      selectedDays.length === 0
    ) {
      setNotification({
        open: true,
        message:
          "Please fill in all required fields and add time slots for at least one day.",
      });
      return;
    }

    // Add timeSlots to formData
    const updatedFormData = {
      ...formData,
      selectedDays: selectedDays,

      timeSlots: timeSlots.map((slot) => ({
        startTime: slot.startTime,
        startPeriod: slot.startPeriod,
        endTime: slot.endTime,
        endPeriod: slot.endPeriod,
        dutyType: slot.dutyType,
      })),
    };

    let startDateToSend, endDateToSend;

    if (generationType === "custom") {
      startDateToSend = generationStartDate
        ? generationStartDate.toISOString()
        : dayjs().toISOString();
      endDateToSend = generationEndDate
        ? generationEndDate.toISOString()
        : dayjs().add(1, "month").toISOString();
    } else {
      // Parse YYYY-MM format
      const [year, month] = generationType.split("-");
      startDateToSend = dayjs(`${year}-${month}-01`)
        .startOf("month")
        .toISOString();
      endDateToSend = dayjs(`${year}-${month}-01`).endOf("month").toISOString();
    }

    let result;
    if (mode === "create") {
      result = await createDutySchedule({
        tierId,
        dutyScheduleData: updatedFormData,
        startDate: startDateToSend,
        endDate: endDateToSend,
      });
    } else if (mode === "edit") {
      result = await updateDutySchedule({
        dutyScheduleId: dutySchedule?._id,
        dutyScheduleData: updatedFormData,
      });
    }

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (mode === "create") {
          setStartDate("");
          setEndDate("");
        }
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving duty Schedule: ${
          result?.error?.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleOpenMembersDefaultDutyPreferncesDialog = () => {
    setMembersDefaultDutyPreferencesDialogOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const formatTime = (timeString) => {
    if (!timeString) return "";
    let [hours, minutes] = timeString.split(":");
    hours = parseInt(hours, 10);
    minutes = minutes || "00";

    if (hours === 0) hours = 12;
    if (hours > 12) hours = hours % 12;

    return `${hours}:${minutes.padStart(2, "0")}`;
  };

  const determineDefaultPeriod = (hours) => {
    hours = parseInt(hours, 10);
    if (hours >= 0 && hours < 12) return "AM";
    return "PM";
  };

  const handleTimeBlur = (id, field) => {
    setTimeSlots((prevSlots) =>
      prevSlots.map((slot) => {
        if (slot.id === id) {
          const formattedTime = formatTime(slot[field]);
          return { ...slot, [field]: formattedTime };
        }
        return slot;
      })
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        sx={{
          backgroundColor: "#1976d2",
          color: "white",
          marginBottom: "1rem",
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }}>
          {mode === "create" ? "Create Schedule" : "Update Schedule"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} mb={2} mt={1}>
          <TextField
            label="Duty Schedule Title"
            name="title"
            type="text"
            value={formData.title}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                title: e.target.value,
              }))
            }
            required
            fullWidth
          />
        </Grid>
        <Grid container spacing={1}>
          {mode === "create" && (
            <Grid item xs={12} mt={0} mb={0}>
              <Typography mb={1} variant="subtitle1">
                Schedule Time Period
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="generation-type-label">Time Period</InputLabel>
                <Select
                  labelId="generation-type-label"
                  label="Generation Type"
                  value={generationType}
                  onChange={(e) => setGenerationType(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="">-- Not Set --</MenuItem>
                  {monthOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                  <MenuItem value="custom">Custom Range</MenuItem>
                </Select>
              </FormControl>
              {generationType === "custom" && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container spacing={1} mt={0}>
                    <Grid item xs={6}>
                      <DatePicker
                        label="Start Date"
                        value={generationStartDate}
                        onChange={(newValue) =>
                          setGenerationStartDate(newValue)
                        }
                        slotProps={{ textField: { fullWidth: true } }}
                        disableFuture={false}
                        showDaysOutsideCurrentMonth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        label="End Date"
                        value={generationEndDate}
                        onChange={(newValue) => setGenerationEndDate(newValue)}
                        slotProps={{ textField: { fullWidth: true } }}
                        disableFuture={false}
                        showDaysOutsideCurrentMonth
                      />
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              )}
            </Grid>
          )}

          <Grid item xs={12} mb={1}>
            <TextField
              label="Duty Schedule Location"
              name="dutyScheduleLocation"
              type="text"
              value={formData.dutyScheduleLocation}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  dutyScheduleLocation: e.target.value,
                }))
              }
              fullWidth
              required
              helperText="Enter Duty Schedule Location (e.g., Main Office)."
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Max People for Shift"
              name="maxPeopleInOffice"
              type="number"
              value={formData.maxPeopleInOffice}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  maxPeopleInOffice: parseInt(e.target.value, 10) || 0,
                }))
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.allowDutySwapping}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      allowDutySwapping: e.target.checked,
                    }))
                  }
                />
              }
              label="Allow Duty Swapping"
            />
          </Grid>
          <Paper
            elevation={2}
            sx={{
              px: 1,
              pb: 1,
              mt: 1,
              border: "1px solid #e0e0e0",
              backgroundColor: "hsl(210, 100%, 99%)", // Very light blue background
              borderRadius: "8px", // Rounded corners
            }}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={700} mt={1}>
                Time Slots
              </Typography>
              <Grid container spacing={0.5} mb={0} p={0} alignItems="center">
                <Grid item xs={8} mb={0}>
                  <TextField
                    select
                    label="Select Day(s)"
                    value={selectedDays}
                    sx={{ backgroundColor: "#ffffff" }}
                    onChange={(e) => setSelectedDays(e.target.value)}
                    fullWidth
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected) => selected.join(", "),
                    }}
                  >
                    {[
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                      "Sunday",
                    ].map((day) => (
                      <MenuItem key={day} value={day}>
                        <Checkbox checked={selectedDays.indexOf(day) > -1} />
                        <ListItemText primary={day} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="shift-length-label">
                      Shift Length (hours)
                    </InputLabel>
                    <Select
                      labelId="shift-length-label"
                      label="Shift Length (hours)"
                      sx={{ backgroundColor: "#ffffff" }}
                      value={shiftLength}
                      onChange={(e) => setShiftLength(Number(e.target.value))}
                    >
                      {[
                        0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7,
                        7.5, 8,
                      ].map((hours) => (
                        <MenuItem key={hours} value={hours}>
                          {hours}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {timeSlots.map((slot, index) => (
                  <React.Fragment key={slot.id}>
                    <Grid item xs={3} mb={0}>
                      <TextField
                        label="Start Time"
                        type="text"
                        sx={{ backgroundColor: "#ffffff" }}
                        value={slot.startTime}
                        onChange={(e) =>
                          handleNewTimeSlotChange(
                            slot.id,
                            "startTime",
                            e.target.value
                          )
                        }
                        onBlur={() => handleTimeBlur(slot.id, "startTime")}
                        fullWidth
                        placeholder="HH:MM"
                        size="small"
                        margin="dense"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      mb={0}
                      container
                      justifyContent="flex-start"
                    >
                      <Button
                        onClick={() =>
                          handleNewTimeSlotChange(
                            slot.id,
                            "startPeriod",
                            slot.startPeriod === "AM" ? "PM" : "AM"
                          )
                        }
                        style={{ minWidth: "auto", padding: 0, margin: 0 }}
                      >
                        {slot.startPeriod}
                      </Button>
                    </Grid>
                    <Grid item xs={3} mb={0}>
                      <TextField
                        label="End Time"
                        type="text"
                        value={slot.endTime}
                        size="small"
                        sx={{ backgroundColor: "#ffffff" }}
                        margin="dense"
                        onChange={(e) =>
                          handleNewTimeSlotChange(
                            slot.id,
                            "endTime",
                            e.target.value
                          )
                        }
                        onBlur={() => handleTimeBlur(slot.id, "endTime")}
                        fullWidth
                        placeholder="HH:MM"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      container
                      mb={0}
                      justifyContent="flex-start"
                    >
                      <Button
                        onClick={() =>
                          handleNewTimeSlotChange(
                            slot.id,
                            "endPeriod",
                            slot.endPeriod === "AM" ? "PM" : "AM"
                          )
                        }
                        style={{ minWidth: "auto", padding: "6px 8px" }}
                      >
                        {slot.endPeriod}
                      </Button>
                    </Grid>
                    <Grid item xs={3} mb={0}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel id={`duty-type-select-label-${slot.id}`}>
                          Type
                        </InputLabel>
                        <Select
                          label="Type"
                          size="small"
                          margin="dense"
                          sx={{ backgroundColor: "#ffffff" }}
                          labelId={`duty-type-select-label-${slot.id}`}
                          id={`duty-type-select-${slot.id}`}
                          value={slot.dutyType}
                          onChange={(e) =>
                            handleNewTimeSlotChange(
                              slot.id,
                              "dutyType",
                              e.target.value
                            )
                          }
                          fullWidth
                          required
                        >
                          {formData.dutyScheduleType?.map((type, i) => (
                            <MenuItem key={i} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1} mb={0}>
                      <IconButton
                        onClick={() => handleRemoveTimeSlotRow(slot.id)}
                        color="secondary"
                        sx={{
                          "&:hover": {
                            color: "red",
                          },
                        }}
                        disabled={timeSlots.length === 1}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ))}

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddTimeSlot}
                    startIcon={<Add />}
                  >
                    Add Time Slot
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          {mode === "create" && (
            <Grid item xs={12} mt={2} mb={2}>
              <IconButton
                onClick={handleOpenMembersDefaultDutyPreferncesDialog}
              >
                {isUpdateMembersDefaultDutyPreferencesLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <BuildCircle className="icon" />
                    Update Members Default Floor Duty Preferences
                  </>
                )}
              </IconButton>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item>
          <Button onClick={onClose} color="secondary" type="submit" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={
              isCreateLoading || isUpdateLoading || selectedDays.length === 0
            }
          >
            {isCreateLoading || isUpdateLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Save"
            )}
          </Button>
        </Grid>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <MembersDefaultDutyPreferencesDialog
        open={membersDefaultDutyPreferencesDialogOpen}
        onClose={() => setMembersDefaultDutyPreferencesDialogOpen(false)}
        tierId={tierId}
        setupMembersDefaultDutyPreferences={setupMembersDefaultDutyPreferences}
        isLoading={isUpdateMembersDefaultDutyPreferencesLoading}
        dutyScheduleOfficeLocation={formData.dutyScheduleLocation}
      />
    </Dialog>
  );
}

export default CreateEditDutyScheduleForm;
