import React, { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Tabs, Tab } from "@mui/material";
import { SidebarContext } from "../../../context/SidebarContext";
import MemberPostsTab from "./MemberPostsTab";
import MemberTransactionsTab from "./MemberTransactionsTab";
import MemberCommentsTab from "./MemberCommentsTab";
import MemberDataTab from "./MemberDataTab";
import MemberBillingTab from "./MemberBillingTab";
import { TabPanel } from "../../helpers/utils";

const MemberProfileTabs = ({ member, tierId }) => {
  const { userId, accountType } = useContext(AuthContext);
  const [value, setValue] = React.useState(0);
  const { sidebarOpen } = useContext(SidebarContext);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="member-profile-tabs">
      <Tabs
        value={value}
        onChange={handleChange}
        className={`${
          !sidebarOpen
            ? "member-profile-tabs-header sidebar-opened"
            : "member-profile-tabs-header"
        }`}
      >
        <Tab label="About" />
        <Tab label="Posts" />
        <Tab label="Comments" />
        {member.user === userId ||
        ["brokerage", "reosadmin"].includes(accountType) ? (
          <Tab label="Transactions" />
        ) : null}

        {member?.isBillingActive ? <Tab label="Billing" /> : null}
      </Tabs>
      <div className="member-profile-tabs-body">
        <TabPanel value={value} index={0}>
          <MemberDataTab member={member} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MemberPostsTab member={member} tierId={tierId} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MemberCommentsTab member={member} tierId={tierId} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MemberTransactionsTab member={member} tierId={tierId} />
        </TabPanel>
        {member?.isBillingActive ? (
          <TabPanel value={value} index={4}>
            <MemberBillingTab
              member={member}
              tierId={tierId}
              sidebarOpen={sidebarOpen}
            />
          </TabPanel>
        ) : null}
      </div>
    </div>
  );
};

export default MemberProfileTabs;
