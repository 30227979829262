import React, { useState, useEffect, useContext, memo } from "react";
import { useLazyDownloadImageQuery } from "../../services/post";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { modules } from "../helpers/utils";
import { AuthContext } from "../../context/AuthContext";
import EditIcon from "@mui/icons-material/Edit";
import { addImageClassToContent } from "../helpers/utils";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardMedia,
  Collapse,
  Divider,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Badge,
  Chip,
  Box,
} from "@mui/material";
import {
  MoreVert,
  DeleteForever,
  ExpandMore,
  Edit,
  ThumbUpAlt,
  ThumbUpOffAlt,
  Send,
  Comment,
  Visibility,
} from "@mui/icons-material/";
import {
  useLikePostMutation,
  useUnlikePostMutation,
  useCommentOnPostMutation,
} from "../../services/post";
import PostContent from "./PostContent";
import EditPost from "./EditPost";
import CommentCard from "./CommentCard";
import PostVideos from "./PostVideos";
import Attachments from "./Attachments";
import NotificationSnackbar from "../helpers/notification-snackbar";
import MemberProfileDrawer from "../members/MemberProfileDrawer";
import { countCommentsAndReplies, AvatarStyledBadge } from "../helpers/utils";
import ReactQuill from "react-quill";

const ExpandComments = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  transform: expand ? "rotate(180deg)" : "rotate(0deg)",
}));

const ForumPostCard = ({
  post,
  isPostList = false,
  isPostPage = false,
  isPostHomePageList = false,
  onDialogOpen,
  onMigratePostToNewCategoryDialogOpen,
  refetchPosts,
  currentUserId,
  onSelect,
  isPostsLoading,
  isPostsError,
  isNewPost,
  viewMode,
}) => {
  const { accountType, userImage, userName, userRole } =
    useContext(AuthContext);
  const [trigger, { data: downloadedImage }] = useLazyDownloadImageQuery();
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const likes = post?.likes;
  const [newComment, setNewComment] = useState("");
  const [isComment, setIsComment] = useState("");
  const [commentOnPost] = useCommentOnPostMutation();
  const [likePost] = useLikePostMutation();
  const [unlikePost] = useUnlikePostMutation();
  const [menuAnchorEl, setMenuAnchorEl] = useState({});
  const [expandedPosts, setExpandedPosts] = useState({});
  const [expandedComments, setExpandedComments] = useState(false);
  const [openEditPostDialog, setOpenEditPostDialog] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (post?.image?.url && !post?.image?.url.includes("cloudinary")) {
      trigger({ imageUrl: post?.image?.url, disposition: "attachment" });
    }
  }, [post, trigger]);

  const isLikedByCurrentUser = likes?.some(
    (like) => like?.author?._id === currentUserId
  );

  const totalCommentsCount =
    post?.comments && countCommentsAndReplies(post?.comments);

  const handleMenuOpen = (event, postId) => {
    event.stopPropagation();
    setMenuAnchorEl({ ...menuAnchorEl, [postId]: event.currentTarget });
  };

  const handleMenuClose = (postId) => {
    setMenuAnchorEl({ ...menuAnchorEl, [postId]: null });
  };

  const handleExpandClick = (postId) => {
    setExpandedPosts({
      ...expandedPosts,
      [postId]: !expandedPosts[postId],
    });
  };

  const handleExpandCommentsClick = () => {
    setExpandedComments(!expandedComments);
  };

  const handleOpenEditPostDialog = (event) => {
    event.stopPropagation();
    setIsEditing(true);
    setOpenEditPostDialog(true);
  };

  const handleCloseEditPostDialog = () => {
    setOpenEditPostDialog(false);
    handleMenuClose(post?._id);
  };

  const handleLikePost = async () => {
    const result = await likePost({
      postId: post?._id,
      tierId: post?.tier?._id,
      userId: currentUserId,
    });

    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `The error occured ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleUnlikePost = async () => {
    const result = await unlikePost({
      postId: post?._id,
      userId: currentUserId,
    });

    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `The error occured ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleCommentInputClick = (event) => {
    event.stopPropagation();
  };

  const handleCommentSubmit = async (event) => {
    event && event?.stopPropagation();
    const result = await commentOnPost({
      userId: currentUserId,
      postId: post?._id,
      content: newComment,
      tierId: post?.tier?._id,
    });

    if (result) {
      setNotification({
        open: true,
        message: `Post commented successfully`,
      });
      setNewComment("");
    } else {
      setNotification({
        open: true,
        message: `Error submitting comment ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleKeyDown = (event) => {
    // if (event.key === "Enter" && !event.shiftKey) {
    //   event.preventDefault();
    //   handleCommentSubmit();
    // }
  };

  const handleMemberClick = (memberId) => {
    setSelectedMemberId(memberId);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedMemberId(null);
  };

  const additionalLikes = likes?.length > 2 ? likes?.length - 2 : 0;

  const renderLikeAvatars = () => {
    const avatarsToShow = likes?.slice(0, 2)?.map((like, index) => (
      <Tooltip key={index} title="Member Profile link">
        <Avatar
          src={like.author?.image?.url}
          alt={`${like.author?.firstName} ${like.author?.lastName}`}
          sx={{
            width: 24,
            height: 24,
            marginRight: -0.75,
            cursor: "pointer",
          }}
        />
      </Tooltip>
    ));

    return <div className="likes-avatars">{avatarsToShow}</div>;
  };

  const isAuthor = post?.author?._id === currentUserId;
  const isAuthorOrAdmin =
    isAuthor ||
    accountType === "reosadmin" ||
    userRole.includes("brokerage") ||
    userRole.includes("reos");
  const isAllOffices =
    post?.tier &&
    post?.tier?.brokerage &&
    post?.tier?.brokerage?.leadBrokerageTier &&
    post?.tier?._id === post?.tier?.brokerage?.leadBrokerageTier?._id;

  return (
    <Card
      sx={{ marginBottom: 4 }}
      className={`post-card ${
        viewMode === "grid-view" ? "grid-view" : "list-view"
      }`}
    >
      {isNewPost && <Box className="post-card-new-post-label">NEW POST</Box>}
      {isPostHomePageList &&
        post?.tier &&
        post?.tier?.brokerage?.officeName && (
          <Box className="post-card-tier-label">
            {`${post?.tier?.brokerage?.officeName}${
              isAllOffices ? " - all offices" : ""
            }` || "Unknown Office"}
          </Box>
        )}
      {viewMode !== "grid-view" && post?.image && post?.image?.url && (
        <div className="aspect-ratio-16-9">
          <CardMedia
            component="img"
            className="aspect-ratio-content"
            image={
              post?.image?.url.includes("cloudinary")
                ? post?.image?.url
                : downloadedImage?.url
            }
            alt="Post"
          />
        </div>
      )}
      <CardHeader
        className="post-card-header"
        avatar={
          <Tooltip title="Member Profile link">
            <AvatarStyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              color="success"
              invisible={!post?.author?.isOnline}
              width="5px"
              height="5px"
            >
              <Avatar
                className="avatar"
                src={post?.author?.image?.url}
                onClick={(event) => {
                  event.stopPropagation();
                  handleMemberClick(post?.author?._id);
                }}
              >
                R
              </Avatar>
            </AvatarStyledBadge>
          </Tooltip>
        }
        action={
          <>
            <IconButton
              className="menu-button"
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleMenuOpen(event, post?._id)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id={`menu-${post?._id}`}
              anchorEl={menuAnchorEl[post?._id]}
              open={Boolean(menuAnchorEl[post?._id])}
              onClose={() => handleMenuClose(post?._id)}
            >
              {isAuthorOrAdmin && (
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenEditPostDialog(event);
                  }}
                >
                  <Edit />
                  <Typography ml={1}>Edit Post</Typography>
                </MenuItem>
              )}
              {isAuthorOrAdmin && (
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    onMigratePostToNewCategoryDialogOpen(post);
                    handleMenuClose(post?._id);
                  }}
                >
                  <Edit />
                  <Typography ml={1}>Migrate Post To New Category</Typography>
                </MenuItem>
              )}
              {isAuthorOrAdmin && (
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMenuClose(post?._id);
                    onDialogOpen(post?._id);
                  }}
                >
                  <DeleteForever />
                  <Typography ml={1}>Delete Post</Typography>
                </MenuItem>
              )}
              {!(accountType === "reosadmin") && !isAuthorOrAdmin && (
                <MenuItem>
                  <Typography ml={1}>No actions</Typography>
                </MenuItem>
              )}
            </Menu>
          </>
        }
        title={
          <div className="card-header-title">
            <Tooltip title="Member Profile link">
              <Link
                onClick={(event) => {
                  event.stopPropagation();
                  handleMemberClick(post?.author?._id);
                }}
                className="avatar-name"
              >
                {post?.author?.firstName} {post?.author?.lastName}
              </Link>
            </Tooltip>
            <Typography className="category-text">
              {" posted in "}
              {post?.category?.name}
            </Typography>
          </div>
        }
        subheader={
          <Typography className="date-text">
            {new Date(post?.createdAt).toLocaleString()}
          </Typography>
        }
      />
      <CardHeader
        title={
          <div className="post-card-title-wrap">
            <Typography className="post-card-title">
              {post?.postTitle || ""}
            </Typography>
            {post?.accessCount > 0 && (
              <Tooltip
                title={`Post Viewed ${post?.accessCount} time${
                  post?.accessCount === 1 ? "" : "s"
                }`}
              >
                <Badge badgeContent={post?.accessCount} color="primary">
                  <Visibility />
                </Badge>
              </Tooltip>
            )}
          </div>
        }
        sx={{ cursor: "pointer" }}
        onClick={(event) => {
          if (isPostList && !isDrawerOpen && !isEditing) {
            onSelect(event);
          }
        }}
      />
      <CardContent className="custom-card-content">
        {post?.videos && post.videos.length > 0 && (
          <PostVideos videos={post?.videos} isPostPage={isPostPage} />
        )}
        {viewMode !== "grid-view" &&
          post?.attachments &&
          post.attachments.length > 0 && (
            <Attachments attachments={post.attachments} />
          )}
        <PostContent
          content={addImageClassToContent(post?.content || "")}
          isExpanded={expandedPosts[post?._id]}
          onExpandClick={(event) => {
            event.stopPropagation();
            console.log("handleExpandClick");
            handleExpandClick(post?._id);
          }}
          isPostPage={isPostPage}
          viewMode={viewMode}
        />
        {viewMode !== "grid-view" && post?.lastEdited && (
          <Typography variant="caption" color="textSecondary" textAlign="right">
            <EditIcon sx={{ verticalAlign: "middle", mr: 0.5 }} />
            Last edited on {new Date(post?.lastEdited)?.toLocaleString()}
          </Typography>
        )}
        {viewMode !== "grid-view" && post?.tags && (
          <Box
            sx={{ mt: 2, mb: 2, display: "flex", flexWrap: "wrap", gap: 0.5 }}
          >
            {Array.isArray(post.tags)
              ? post.tags.map((tag, index) => (
                  <Chip key={index} label={tag} variant="outlined" />
                ))
              : post.tags
                  .split(",")
                  .map((tag, index) => (
                    <Chip key={index} label={tag.trim()} variant="outlined" />
                  ))}
          </Box>
        )}
        <div className="likes-block">
          <Tooltip
            title={additionalLikes > 0 ? `+ ${additionalLikes} more` : ""}
          >
            <Badge
              badgeContent={additionalLikes > 0 ? `+${additionalLikes}` : ""}
              className="likes-block-avatars"
            >
              {renderLikeAvatars()}
            </Badge>
          </Tooltip>
        </div>
      </CardContent>
      <Divider />
      <CardActions className="post-card-actions-block">
        <div className="post-card-actions">
          <div className="likes-block">
            <Tooltip title={isLikedByCurrentUser ? "Unlike it" : "Like it"}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  isLikedByCurrentUser ? handleUnlikePost() : handleLikePost();
                }}
              >
                {isLikedByCurrentUser ? <ThumbUpAlt /> : <ThumbUpOffAlt />}
              </IconButton>
            </Tooltip>
            <Tooltip title={"Comment the Post"}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setIsComment(!isComment);
                }}
              >
                <Comment />
              </IconButton>
            </Tooltip>
          </div>
          <div className="comments-block">
            {post?.comments && post?.comments?.length > 0 ? (
              <>
                <span className="comments-block-span">{`${
                  post?.comments &&
                  post?.comments?.length > 0 &&
                  (totalCommentsCount === 1
                    ? "1 comment"
                    : `${totalCommentsCount} comments`)
                }`}</span>
                <ExpandComments
                  expand={expandedComments}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleExpandCommentsClick();
                  }}
                  aria-expanded={expandedComments}
                  aria-label="show more"
                >
                  <ExpandMore />
                </ExpandComments>
              </>
            ) : (
              <span className="comments-block-span">no comments</span>
            )}
          </div>
        </div>
      </CardActions>
      <Collapse in={expandedComments} timeout="auto" unmountOnExit>
        <CardContent>
          {post?.comments && post?.comments?.length > 0 ? (
            post?.comments?.map((comment) => (
              <CommentCard
                key={comment?._id}
                comment={comment}
                post={post}
                onMemberClick={handleMemberClick}
                refetchPosts={refetchPosts}
                isPostsError={isPostsError}
                isPostsLoading={isPostsLoading}
              />
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              No comments yet
            </Typography>
          )}
          {expandedComments && (
            <div
              className="post-comment-block"
              onClick={handleCommentInputClick}
            >
              <Avatar
                height="100"
                width="100"
                src={userImage || ""}
                alt={userName || "UN"}
              />
              <div className="comment-input">
                <ReactQuill
                  className="comment-input-textarea"
                  modules={modules}
                  placeholder="Enter New Comment..."
                  value={newComment}
                  onChange={(value) => {
                    setNewComment(value);
                  }}
                  // onKeyDown={handleKeyDown}
                  onClick={handleCommentInputClick}
                />
                <Tooltip title="Comment Post">
                  <IconButton
                    className="comment-input-button"
                    onClick={handleCommentSubmit}
                  >
                    <Send />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          )}
        </CardContent>
      </Collapse>
      {isComment && !expandedComments && (
        <div className="post-comment-block" onClick={handleCommentInputClick}>
          <Avatar
            height="100"
            width="100"
            src={userImage || ""}
            alt={userName || "UN"}
          />
          <div className="comment-input">
            <ReactQuill
              className="comment-input-textarea"
              modules={modules}
              placeholder="Enter New Comment..."
              value={newComment}
              onChange={(value) => {
                setNewComment(value);
              }}
              fullWidth
              onKeyDown={handleKeyDown}
              onClick={handleCommentInputClick}
            />
            <Tooltip title="Comment Post">
              <IconButton
                className="comment-input-button"
                onClick={handleCommentSubmit}
              >
                <Send />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <EditPost
        refetchPosts={refetchPosts}
        post={post}
        open={openEditPostDialog}
        onClose={() => {
          handleCloseEditPostDialog();
          handleMenuClose(post?._id);
        }}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <MemberProfileDrawer
        userId={selectedMemberId}
        open={isDrawerOpen}
        onClose={handleDrawerClose}
      />
    </Card>
  );
};

export default memo(ForumPostCard);
