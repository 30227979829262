import React, { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useMigratePostsToListingsMutation } from "../../../services/migration";
import {
  Container,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Divider,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import MigrationResultsModal from "./MigrationResultsModal";

const MigrationManagement = () => {
  const { accountType } = useContext(AuthContext);
  const [migratePostsToListings, { isLoading: isMigrating }] =
    useMigratePostsToListingsMutation(accountType);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [migratedListings, setMigratedListings] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const handleSubmitMigration = async (e) => {
    e.preventDefault();
    if (accountType !== "reosadmin") {
      setNotification({
        open: true,
        message: "The current user is not REOS Admin",
      });
      return;
    }

    const result = await migratePostsToListings();

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      if (result?.data?.migratedListings) {
        setMigratedListings(result?.data?.migratedListings);
        setOpenModal(true);
      }
    } else {
      setNotification({
        open: true,
        message: `Error submitting migration: ${
          result?.error?.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setNotification({ ...notification, open: false });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Migration Management
        </Typography>
        <Divider />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmitMigration}
          disabled={isMigrating || accountType !== "reosadmin"}
        >
          {isMigrating ? (
            <CircularProgress size={24} />
          ) : (
            "Migrate Posts To Listings"
          )}
        </Button>
      </Paper>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <MigrationResultsModal
        open={openModal}
        onClose={handleCloseModal}
        migratedListings={migratedListings}
      />
    </Container>
  );
};

export default MigrationManagement;
