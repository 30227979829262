import React, { useState, useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { TextField, IconButton } from "@mui/material";
import { Send } from "@mui/icons-material/";

const CommentsEditCell = ({ params, handleCommentChange }) => {
  const { userId } = useContext(AuthContext);
  const [inputValue, setInputValue] = useState("");

  const handleAddComment = () => {
    if (inputValue.trim() !== "") {
      const updatedComments = [
        ...params.value,
        { user: userId, comment: inputValue.trim() },
      ];

      handleCommentChange(params?.id, updatedComments);
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleAddComment();
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <TextField
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Add a comment..."
          variant="outlined"
          size="small"
        />
        <IconButton onClick={handleAddComment} size="small">
          <Send />
        </IconButton>
      </div>
    </div>
  );
};

export default CommentsEditCell;
