import React, { useState, useContext } from "react";
import { useGetAllLevelsBrokerageProfilesQuery } from "../../../services/brokerage";
import { AuthContext } from "../../../context/AuthContext";
import { Typography, List, ListItem, Divider, Grid } from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import BrokeragesList from "./BrokeragesList";
import BrokerageEditDrawer from "../../brokerage/brokerage-ecosytem/BrokerageEditDrawer";
import AddRemoveAssignUnassignToFromLeadBrokerageDialog from "./AddRemoveAssignUnassignToFromLeadBrokerageDialog";

const BrokerageManagement = () => {
  const { userId, userRole, accountType } = useContext(AuthContext);
  const { data: brokerages, refetch } = useGetAllLevelsBrokerageProfilesQuery(
    userId,
    {
      skip:
        !userId && (userRole !== "reosadmin" || userRole !== "reossuperadmin"),
    }
  );
  const [action, setAction] = useState("");
  const [isBrokerageEditDrawerOpen, setIsBrokerageEditDrawerOpen] =
    useState(false);
  const [isLeadBrokerageDialogOpen, setIsLeadBrokerageDialogOpen] =
    useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleBrokerageEditDrawerOpen = () => {
    setIsBrokerageEditDrawerOpen(true);
  };

  const handleBrokerageEditDrawerClose = () => {
    setIsBrokerageEditDrawerOpen(false);
  };

  const handleLeadBrokerageDialogOpen = (actionType) => {
    setAction(actionType);
    setIsLeadBrokerageDialogOpen(true);
  };

  const handleLeadBrokerageDialogClose = () => {
    setIsLeadBrokerageDialogOpen(false);
  };

  if (accountType !== "reosadmin") {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        You are not allowed access to this page
      </Typography>
    );
  }

  return (
    <div className="brokerage-page">
      <Typography variant="h4" align="center" gutterBottom>
        Brokerage Management
      </Typography>
      <Divider />
      <Grid container>
        <Grid item xs={6} sx={{ p: 2 }}>
          <List style={{ width: "100%", marginRight: "0.1rem" }}>
            <ListItem
              onClick={handleBrokerageEditDrawerOpen}
              className="user-management-item"
            >
              Create new Brokerage
            </ListItem>
            <Divider />
            <ListItem
              onClick={(event) => {
                event.stopPropagation();
                handleLeadBrokerageDialogOpen("addToLeadBrokerage");
              }}
              className="user-management-item"
            >
              Add To Lead Brokerage
            </ListItem>
            <Divider />
            <ListItem
              onClick={(event) => {
                event.stopPropagation();
                handleLeadBrokerageDialogOpen("removeFromLeadBrokerage");
              }}
              className="user-management-item"
            >
              Remove From Lead Brokerage
            </ListItem>
            <Divider />
          </List>
        </Grid>
      </Grid>
      <BrokeragesList brokerages={brokerages} refetchBrokerages={refetch} />
      <BrokerageEditDrawer
        mode="create"
        open={isBrokerageEditDrawerOpen}
        onClose={handleBrokerageEditDrawerClose}
      />
      <AddRemoveAssignUnassignToFromLeadBrokerageDialog
        open={isLeadBrokerageDialogOpen}
        onClose={handleLeadBrokerageDialogClose}
        action={action}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </div>
  );
};

export default BrokerageManagement;
