import React, { useState, useEffect } from "react";
import {
  useCreateListingTourMutation,
  useUpdateListingTourMutation,
} from "../../../services/listing-tours";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
dayjs.extend(utc);

const ACRE_IN_SQFT = 43560;

function CreateEditListingTourDialog({
  tier,
  open,
  onClose,
  mode = "create",
  listingTour,
}) {
  const [formValues, setFormValues] = useState(() => ({
    listingTourTitle: "",
    listingTourDate: null,
  }));

  const [createListingTour, { isLoading: isCreateListingTourLoading }] =
    useCreateListingTourMutation();
  const [updateListingTour, { isLoading: isUpdateListingTourLoading }] =
    useUpdateListingTourMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (listingTour) {
      setFormValues({
        listingTourTitle: listingTour.listingTourTitle || "",
        listingTourDate: listingTour.listingTourDate
          ? dayjs(listingTour.listingTourDate)
          : null,
      });
    } else {
      setFormValues({
        listingTourTitle: "",
        listingTourDate: null,
      });
    }
  }, [listingTour]);

  const handleSubmit = async () => {
    if (!formValues.listingTourTitle.trim() || !formValues.listingTourDate) {
      setNotification({
        open: true,
        message:
          "Please fill in all required fields correctly, including a valid date.",
      });
      return;
    }

    let result;
    if (mode === "edit" && listingTour?._id) {
      result = await updateListingTour({
        listingTourId: listingTour?._id,
        listingTourData: formValues,
      });
    } else if (mode === "create" && tier?._id) {
      result = await createListingTour({
        tierId: tier?._id,
        listingTourData: formValues,
      });
    }

    if (result && result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (mode === "create") {
          setFormValues({
            listingTourTitle: "",
            listingTourDate: null,
          });
        }
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error Saving Listing Tour. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleListingTourTitleChange = (e) => {
    setFormValues({ ...formValues, listingTourTitle: e.target.value });
  };

  const tomorrow = dayjs().startOf("tomorrow");

  const handleListingTourDateChange = (newValue) => {
    if (newValue && newValue.isBefore(tomorrow)) {
      setNotification({
        open: true,
        message: "The date is in the past.",
      });
    } else {
      setNotification({
        open: false,
        message: "",
      });
    }

    setFormValues({
      ...formValues,
      listingTourDate: dayjs(newValue || new Date()),
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Typography align="center" gutterBottom>
          {listingTour ? "Edit Listing Tour" : "Create New Listing Tour"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Listing Tour Title"
              type="text"
              fullWidth
              value={formValues.listingTourTitle}
              onChange={(e) => handleListingTourTitleChange(e)}
              helperText="You can leave this as 'Weekly' or enter a custom title."
            />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Listing Tour Date"
                  value={dayjs(formValues.listingTourDate)}
                  onChange={handleListingTourDateChange}
                  format="LL"
                  disableFuture={false}
                  showDaysOutsideCurrentMonth
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={isCreateListingTourLoading || isUpdateListingTourLoading}
        >
          {isCreateListingTourLoading || isUpdateListingTourLoading ? (
            <CircularProgress size={24} />
          ) : listingTour ? (
            "Update Listing Tour"
          ) : (
            "Create Listing Tour"
          )}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
}

export default CreateEditListingTourDialog;
