import React, { useContext } from "react";
import {
  AppBar,
  Toolbar,
  Tooltip,
  Typography,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  ListItemIcon,
} from "@mui/material";
import { GridView, ViewList } from "@mui/icons-material/";
import { SidebarContext } from "../../context/SidebarContext";
import { categoryIcon } from "../sidebar/sidebarHelpers";

const MembersPageNavbar = ({ label, membersAmount, viewMode, setViewMode }) => {
  const { sidebarOpen } = useContext(SidebarContext);

  const handleCardView = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  const membersPageNavbarClass = `app-bar ${
    !sidebarOpen ? "sidebar-closed" : "sidebar-open"
  }`;

  return (
    <AppBar className={membersPageNavbarClass}>
      <Toolbar>
        <Typography
          variant="h6"
          className="main-title"
          sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}
        >
          <ListItemIcon
            sx={{
              minWidth: "1.75rem",
              display: "flex",
              alignItems: "center",
              ml: 1,
              mr: 1,
            }}
          >
            {categoryIcon("group")}
          </ListItemIcon>
          {label} {membersAmount ? `(${membersAmount})` : ""}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleCardView}
            aria-label="text alignment"
          >
            <Tooltip title="grid view">
              <ToggleButton value="grid" aria-label="grid view">
                <GridView />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="row view">
              <ToggleButton value="row" aria-label="row view">
                <ViewList />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MembersPageNavbar;
