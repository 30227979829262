import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useGetMemberTransactionsQuery } from "../../../services/member";
import {
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const MemberTransactionsTab = ({ member, tierId }) => {
  const memberId = member?._id || null;
  const { userId } = useContext(AuthContext);

  const {
    data: memberTransactions,
    isLoading,
    error,
  } = useGetMemberTransactionsQuery({ memberId, tierId }, { skip: !memberId });

  const [filter, setFilter] = useState("ytd");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [yearlySummary, setYearlySummary] = useState({});

  let startDate = new Date();
  let endDate = new Date();

  useEffect(() => {
    if (memberTransactions) {
      let filtered = [...memberTransactions];
      const today = new Date();
      let currentStatus = ["SLD"];

      switch (filter) {
        case "ytd":
          startDate = new Date(today.getFullYear(), 0, 1);
          endDate = new Date(today.getFullYear() + 100, 0, 1);
          currentStatus = ["SLD"];
          break;
        case "past":
          startDate = new Date(today.getFullYear() - 1, 0, 1);
          endDate = new Date(today.getFullYear() - 1, 11, 31);
          currentStatus = ["SLD"];
          break;
        case "active":
          currentStatus = ["ACT"];
          break;
        case "pending":
          currentStatus = ["PND"];
          break;
        case "custom":
          if (customStartDate && customEndDate) {
            startDate = new Date(customStartDate);
            endDate = new Date(customEndDate);
          }
          currentStatus = ["SLD"];
          break;
        default:
          startDate = new Date(0);
          endDate = today;
          currentStatus = ["SLD"];
          break;
      }

      filtered = filtered.filter((transaction) => {
        const isStatusValid = currentStatus.includes(transaction.status);
        const dateSold = new Date(transaction.dateSold);

        return currentStatus.includes("PND") || currentStatus.includes("ACT")
          ? isStatusValid
          : isStatusValid && dateSold >= startDate && dateSold <= endDate;
      });

      setFilteredTransactions(filtered);

      // Calculate yearly summary
      const yearlySummary = filtered.reduce((summary, transaction) => {
        const year = new Date(transaction.dateSold).getFullYear();
        const isSeller =
          transaction.licensee?._id === memberId ||
          transaction.coLicensee?._id === memberId ||
          transaction.coLicensee2?._id === memberId;
        const priceToUse =
          filter === "active" && transaction.status === "ACT"
            ? transaction.listPrice
            : transaction.priceSold;

        if (!summary[year]) {
          summary[year] = {
            totalTransactions: 0,
            totalCommission: 0,
            averageCommission: 0,
            buyers: 0,
            sellers: 0,
          };
        }

        summary[year].totalTransactions += 1;
        summary[year].totalCommission +=
          100000 * 0.035 +
          (priceToUse > 100000 ? (priceToUse - 100000) * 0.015 : 0);

        // Update average commission calculation
        summary[year].averageCommission =
          summary[year].totalCommission / summary[year].totalTransactions;

        if (isSeller) summary[year].sellers += 1;
        else summary[year].buyers += 1;

        return summary;
      }, {});

      setYearlySummary(yearlySummary);
    }
  }, [memberTransactions, filter, customStartDate, customEndDate, memberId]);

  const calculateRollingAverages = (yearlyData) => {
    const averages = {
      totalTransactions: 0,
      totalBuyers: 0,
      totalSellers: 0,
      totalCommission: 0,
    };

    const years = Object.keys(yearlyData);
    const count = Math.min(years.length, 5); // To avoid undefined values

    years.slice(-count).forEach((year) => {
      averages.totalTransactions += yearlyData[year].totalTransactions;
      averages.totalBuyers += yearlyData[year].buyers;
      averages.totalSellers += yearlyData[year].sellers;
      averages.totalCommission += yearlyData[year].totalCommission;
    });

    // Calculate averages
    for (const key in averages) {
      averages[key] /= count;
    }

    return averages;
  };

  // Assuming `yearlySummary` holds your yearly data
  const rollingAverages = calculateRollingAverages(yearlySummary);

  const totalTransactions = filteredTransactions.length;
  let totalCommission = 0;
  let buyers = 0;
  let sellers = 0;

  filteredTransactions.forEach((transaction) => {
    if (
      transaction.licensee?._id === memberId ||
      transaction.coLicensee?._id === memberId ||
      transaction.coLicensee2?._id === memberId
    ) {
      sellers++;
    } else {
      buyers++;
    }

    const priceToUse =
      filter === "active" && transaction.status === "ACT"
        ? transaction.listPrice
        : transaction.priceSold;

    if (priceToUse != null) {
      totalCommission +=
        100000 * 0.035 +
        (priceToUse > 100000 ? (priceToUse - 100000) * 0.015 : 0);
    }
  });

  if (isLoading)
    return (
      <div
        style={{ display: "flex", justifyContent: "center", padding: "20px" }}
      >
        <CircularProgress size={24} />
      </div>
    );

  if (error) return <p>Error loading transactions</p>;

  return (
    <div style={{ marginBottom: "170px", padding: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="filter-label">Filter</InputLabel>
            <Select
              labelId="filter-label"
              label="Filter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <MenuItem value="all">All Transactions</MenuItem>
              <MenuItem value="ytd">Year to Date Sales</MenuItem>
              <MenuItem value="past">Past Year Sales</MenuItem>
              <MenuItem value="custom">Custom Date Range Sales</MenuItem>
              <MenuItem value="active">Active Listings</MenuItem>
              <MenuItem value="pending">Pending Sales</MenuItem>
              <MenuItem value="yearly">Yearly Summary</MenuItem>{" "}
            </Select>
          </FormControl>
          {filter === "custom" && (
            <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
              <TextField
                label="Start Date"
                type="date"
                value={customStartDate}
                onChange={(e) => {
                  setCustomStartDate(e.target.value);
                  startDate = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="End Date"
                type="date"
                value={customEndDate}
                onChange={(e) => {
                  setCustomEndDate(e.target.value);
                  endDate = e.target.value;
                }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Summary</Typography>
          <Card style={{ backgroundColor: "#f0f4f8", borderRadius: "10px" }}>
            <CardContent>
              {filter === "custom" && customStartDate && customEndDate && (
                <Typography variant="subtitle1">
                  <strong>Date Range:</strong>{" "}
                  {new Date(customStartDate).toLocaleDateString()} to{" "}
                  {new Date(customEndDate).toLocaleDateString()}
                </Typography>
              )}
              <Typography variant="subtitle1">
                <strong>
                  Total {filter === "active" ? "Listings" : "Transactions"}:
                </strong>{" "}
                {totalTransactions}
              </Typography>
              {filter !== "active" &&
                filter !== "yearly" && ( // Exclude totals for yearly summary
                  <>
                    <Typography variant="subtitle1">
                      <strong>Total Buyers:</strong> {buyers}
                    </Typography>
                    <Typography variant="subtitle1">
                      <strong>Total Sellers:</strong> {sellers}
                    </Typography>
                  </>
                )}
              <Typography variant="subtitle1">
                <strong>
                  Total {filter === "active" ? "Potential" : "Estimated"}{" "}
                  Commission:
                </strong>{" "}
                {totalCommission != null
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(totalCommission)
                  : "$0.00"}
              </Typography>

              {filter === "yearly" && (
                <>
                  {/* Rolling Averages */}
                  <Typography variant="subtitle1">
                    <strong>5-Year Average Transactions:</strong>{" "}
                    <span
                      style={{
                        color:
                          totalTransactions < rollingAverages.totalTransactions
                            ? "red"
                            : "green",
                      }}
                    >
                      {rollingAverages.totalTransactions.toFixed(0)}{" "}
                      {/* Assuming totalTransactions is an integer */}
                    </span>
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>5-Year Average Buyers:</strong>{" "}
                    <span
                      style={{
                        color:
                          buyers < rollingAverages.totalBuyers
                            ? "red"
                            : "green",
                      }}
                    >
                      {rollingAverages.totalBuyers.toFixed(0)}
                    </span>
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>5-Year Average Sellers:</strong>{" "}
                    <span
                      style={{
                        color:
                          sellers < rollingAverages.totalSellers
                            ? "red"
                            : "green",
                      }}
                    >
                      {rollingAverages.totalSellers.toFixed(0)}
                    </span>
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>5-Year Average Commission:</strong>{" "}
                    <span
                      style={{
                        color:
                          totalCommission < rollingAverages.totalCommission
                            ? "red"
                            : "green",
                      }}
                    >
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(rollingAverages.totalCommission)}
                    </span>
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Yearly Summary Table */}
        {filter === "yearly" && (
          <Grid item xs={12}>
            <Typography variant="h6">Yearly Summary</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Year</TableCell>
                    <TableCell>Total Transactions</TableCell>
                    <TableCell>Total Buyers</TableCell>
                    <TableCell>Total Sellers</TableCell>
                    <TableCell>Total Commission</TableCell>
                    <TableCell>Average Commission</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(yearlySummary).map(([year, summary]) => (
                    <TableRow key={year}>
                      <TableCell>{year}</TableCell>
                      <TableCell
                        style={{
                          color:
                            summary.totalTransactions <
                            rollingAverages.totalTransactions
                              ? "red"
                              : "green",
                        }}
                      >
                        {summary.totalTransactions}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            summary.buyers < rollingAverages.totalBuyers
                              ? "red"
                              : "green",
                        }}
                      >
                        {summary.buyers}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            summary.sellers < rollingAverages.totalSellers
                              ? "red"
                              : "green",
                        }}
                      >
                        {summary.sellers}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            summary.totalCommission <
                            rollingAverages.totalCommission
                              ? "red"
                              : "green",
                        }}
                      >
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(summary.totalCommission)}
                      </TableCell>
                      <TableCell>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }).format(yearlySummary[year].averageCommission)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        {/* Filtered Transactions Table */}

        {filter !== "yearly" && filteredTransactions.length > 0 && (
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#e3f2fd" }}>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {filter === "active" ? "Date Listed" : "Date Sold"}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Property
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Side</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {filter === "active" ? "List Price" : "Sold Price"}
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Commission (Est)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTransactions.map((transaction) => (
                    <TableRow key={transaction._id}>
                      <TableCell>
                        {filter === "active"
                          ? new Date(
                              transaction.dateListed
                            ).toLocaleDateString()
                          : new Date(transaction.dateSold).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{transaction.address}</TableCell>
                      <TableCell>
                        {transaction.licensee?._id === memberId ||
                        transaction.coLicensee?._id === memberId ||
                        transaction.coLicensee2?._id === memberId
                          ? "Seller"
                          : "Buyer"}
                      </TableCell>
                      <TableCell>
                        {transaction.status === "ACT"
                          ? transaction.listPrice != null
                            ? new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }).format(transaction.listPrice)
                            : "$0.00"
                          : transaction.priceSold != null
                          ? new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(transaction.priceSold)
                          : "$0.00"}
                      </TableCell>
                      <TableCell>{transaction.status}</TableCell>
                      <TableCell>
                        {transaction.status === "ACT"
                          ? new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(
                              100000 * 0.035 +
                                (transaction.listPrice > 100000
                                  ? (transaction.listPrice - 100000) * 0.015
                                  : 0)
                            )
                          : transaction.priceSold != null
                          ? new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(
                              100000 * 0.035 +
                                (transaction.priceSold > 100000
                                  ? (transaction.priceSold - 100000) * 0.015
                                  : 0)
                            )
                          : "$0.00"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default MemberTransactionsTab;
