import React, { memo } from "react";
import { Backdrop, Fade, Modal, Box } from "@mui/material";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { printPlugin } from "@react-pdf-viewer/print";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";

const PreviewTourSheetModal = ({ open, onClose, pdfUrl }) => {
  const zoomPluginInstance = zoomPlugin();
  const printPluginInstance = printPlugin();
  const getFilePluginInstance = getFilePlugin();
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { PrintButton } = printPluginInstance;
  // console.log("")

  return (
    <Modal
      open={open && !!pdfUrl}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            maxHeight: "90%",
            overflowY: "auto",
          }}
        >
          {pdfUrl && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Toolbar>
                  {(props) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      <ZoomOutButton {...props} />
                      <ZoomPopover {...props} />
                      <ZoomInButton {...props} />
                      <PrintButton {...props} />
                    </div>
                  )}
                </Toolbar>
                <Viewer
                  fileUrl={pdfUrl}
                  plugins={[
                    toolbarPluginInstance,
                    zoomPluginInstance,
                    printPluginInstance,
                    getFilePluginInstance,
                  ]}
                  defaultScale={SpecialZoomLevel.PageWidth}
                />
              </div>
            </Worker>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default memo(PreviewTourSheetModal);
