import React, { useState, useEffect, memo } from "react";
import { useSendPDFSummaryOfListingTourEmailsMutation } from "../../../services/listings-on-the-tours";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  Box,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import PDFDocument from "./PDFDocument";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { printPlugin } from "@react-pdf-viewer/print";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import { Download, ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";

const steps = ["Select Columns", "Preview PDF", "Select Members & Send Email"];
const defaultColumns = [
  "address",
  "area",
  "subarea",
  "listPrice",
  "listingDate",
  "bedrooms",
  "bathrooms",
  "totalSqft",
  "mls",
  "openTime",
  "lockboxLocation",
  "agent",
  "comments",
];

const GeneratePDFDialog = ({
  open,
  onClose,
  columns,
  data,
  members,
  listingTour,
}) => {
  const [sendPDFSummaryOfListingTourEmails, { isLoading }] =
    useSendPDFSummaryOfListingTourEmailsMutation();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedColumns, setSelectedColumns] = useState(
    columns
      ?.filter((col) => defaultColumns?.includes(col?.field))
      ?.map((col) => col?.field)
  );
  const [emailSubject, setEmailSubject] = useState(
    `Office Tour Sheet - ${listingTour?.listingTourTitle}, ${
      listingTour?.listingTourDate
        ? new Date(listingTour?.listingTourDate)?.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })
        : "No Date"
    }`
  );
  const [emailBody, setEmailBody] = useState(
    "You can find the sheet for the next office's tour in the attached files"
  );
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfBlob, setPdfBlob] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const zoomPluginInstance = zoomPlugin();
  const printPluginInstance = printPlugin();
  const getFilePluginInstance = getFilePlugin();
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { PrintButton } = printPluginInstance;

  useEffect(() => {
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [pdfUrl]);

  const handleNext = async () => {
    if (activeStep === 0) {
      const selectedCols = columns?.filter((col) =>
        selectedColumns?.includes(col?.field)
      );
      const doc = (
        <PDFDocument
          data={data}
          columns={selectedCols}
          listingTourTitle={listingTour?.listingTourTitle}
          listingTourDate={listingTour?.listingTourDate}
        />
      );
      const asBlob = await pdf(doc).toBlob();
      setPdfBlob(asBlob);
      const url = URL.createObjectURL(asBlob);
      setPdfUrl(url);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
        setPdfUrl("");
      }
      setPdfBlob(null);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDownload = () => {
    if (pdfUrl) {
      const a = document.createElement("a");
      a.href = pdfUrl;
      a.download = `listing_tour_${listingTour?.listingTourTitle}_${listingTour?.listingTourDate}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleSendEmail = async () => {
    if (!selectedMembers.length) {
      setNotification({
        open: true,
        message: "No members selected.",
      });
      return;
    }

    if (!pdfBlob) {
      setNotification({
        open: true,
        message: "PDF file is missing.",
      });
      return;
    }

    const formData = new FormData();
    selectedMembers.forEach((memberId) => {
      formData.append("selectedMembers[]", memberId);
    });
    formData.append("emailSubject", emailSubject);
    formData.append("emailBody", emailBody);
    formData.append(
      "pdfFile",
      pdfBlob,
      `listing_tour_${listingTour?.listingTourTitle}_${listingTour?.listingTourDate}.pdf`
    );

    const result = await sendPDFSummaryOfListingTourEmails({
      tierId: listingTour?.tier?._id,
      listingTourId: listingTour?._id,
      formData,
    });
    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
      }, 2000);
    } else {
      setNotification({
        open: true,
        message: `Error sending email: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
    onClose();
  };

  const handleToggleColumn = (field) => {
    setSelectedColumns((prevSelected) =>
      prevSelected.includes(field)
        ? prevSelected.filter((col) => col !== field)
        : [...prevSelected, field]
    );
  };

  const handleToggleMember = (memberId) => {
    setSelectedMembers((prevSelected) =>
      prevSelected.includes(memberId)
        ? prevSelected.filter((id) => id !== memberId)
        : [...prevSelected, memberId]
    );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };
  // console.log("")

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>Generate PDF</DialogTitle>
      <DialogContent dividers sx={{ height: "65vh", width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <Box mt={2}>
            {columns.map((col) => (
              <FormControlLabel
                key={col.field}
                control={
                  <Checkbox
                    checked={selectedColumns?.includes(col?.field)}
                    onChange={() => handleToggleColumn(col?.field)}
                  />
                }
                label={col.headerName}
              />
            ))}
          </Box>
        )}
        {activeStep === 1 && (
          <Box mt={2}>
            {pdfUrl && (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Toolbar>
                    {(props) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "8px",
                            marginBottom: "8px",
                          }}
                        >
                          <ZoomOutButton {...props} />
                          <ZoomPopover {...props} />
                          <ZoomInButton {...props} />
                          <PrintButton {...props} />
                        </div>
                      );
                    }}
                  </Toolbar>
                  <Viewer
                    fileUrl={pdfUrl}
                    plugins={[
                      toolbarPluginInstance,
                      zoomPluginInstance,
                      printPluginInstance,
                      getFilePluginInstance,
                    ]}
                    defaultScale={SpecialZoomLevel.PageWidth}
                  />
                </div>
              </Worker>
            )}
          </Box>
        )}
        {activeStep === 2 && (
          <Box mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    selectedMembers?.length === members?.length &&
                    members?.length > 0
                  }
                  onChange={() => {
                    if (selectedMembers?.length === members?.length) {
                      setSelectedMembers([]);
                    } else {
                      setSelectedMembers(
                        members?.map((member) => member?.user?._id)
                      );
                    }
                  }}
                />
              }
              label="Select All"
            />
            {members?.map((member) => (
              <FormControlLabel
                key={member?.user?._id}
                control={
                  <Checkbox
                    checked={selectedMembers?.includes(member?.user?._id)}
                    onChange={() => handleToggleMember(member?.user?._id)}
                  />
                }
                label={`${member?.user?.firstName} ${member?.user?.lastName}`}
              />
            ))}
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid item xs={12}>
                <TextField
                  label="Email Subject"
                  name="emailSubject"
                  type="text"
                  value={emailSubject}
                  onChange={(e) => setEmailSubject(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Members amount"
                  name="selectedMembers"
                  type="text"
                  value={selectedMembers?.length}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email Body"
                  name="emailBody"
                  type="text"
                  value={emailBody}
                  onChange={(e) => setEmailBody(e.target.value)}
                  required
                  multiline
                  rows={5}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {activeStep > 0 && (
          <Button
            onClick={handleBack}
            color="primary"
            startIcon={<ArrowBackIos />}
          >
            Back
          </Button>
        )}
        {activeStep === 1 && (
          <Button
            onClick={handleDownload}
            color="primary"
            endIcon={<Download />}
          >
            Download PDF
          </Button>
        )}
        {activeStep < steps?.length - 1 && (
          <Button
            onClick={handleNext}
            color="primary"
            endIcon={<ArrowForwardIos />}
            disabled={
              activeStep === 0 && selectedColumns?.length === 0 ? true : false
            }
          >
            Next
          </Button>
        )}
        {activeStep === steps?.length - 1 && (
          <Button
            onClick={handleSendEmail}
            color="primary"
            disabled={
              !selectedMembers.length ||
              selectedMembers.length === 0 ||
              isLoading
            }
          >
            {isLoading ? <CircularProgress size={24} /> : "Send Email(s)"}
          </Button>
        )}
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default memo(GeneratePDFDialog);
