import React, { useState, useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { TextField, IconButton } from "@mui/material";
import { Send } from "@mui/icons-material/";

const ESPEditCell = ({ params, handleESPChange }) => {
  const { userId } = useContext(AuthContext);
  const [inputValue, setInputValue] = useState("");

  const handleAddESP = () => {
    if (inputValue.trim() !== "") {
      const newESP = {
        user: userId,
        estimatedPrice: parseFloat(inputValue.trim()),
      };

      const updatedESPs = [...(params?.value || []), newESP];
      handleESPChange(params?.id, updatedESPs);
      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleAddESP();
    }
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", width: "100%" }}>
        <TextField
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Add estimated price..."
          variant="outlined"
          size="small"
          type="number"
          inputProps={{ min: 0, step: 0.01 }}
        />
        <IconButton onClick={handleAddESP} size="small">
          <Send />
        </IconButton>
      </div>
    </div>
  );
};

export default ESPEditCell;
