import React, { useState, useEffect, memo } from "react";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { Tooltip, Button } from "@mui/material";
import { sliceMiddleContent } from "../helpers/utils";
import {
  replaceVimeoLinks,
  extractFirstVimeoLinkAndComponent,
} from "../helpers/VimeoUtils";
import { replaceGoogleSheetsLinks } from "../helpers/GoogleSheetsUtils";

function PostContent({
  content,
  isExpanded = false,
  onExpandClick,
  isPostPage = false,
  isAnnouncementWidget = false,
  isLegallySpeakingWidget = false,
  isListingWidget = false,
  isMarketingWidget = false,
  isBusinessWidget = false,
  isStrataWidget = false,
  showOpenButton = true,
  announcementId,
  postId,
  listingId,
  onSelect,
  viewMode,
}) {
  const [expanded, setExpanded] = useState(isExpanded);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const isWidget =
    isAnnouncementWidget ||
    isLegallySpeakingWidget ||
    isMarketingWidget ||
    isBusinessWidget ||
    isStrataWidget;

  const previewContentLength = isAnnouncementWidget
    ? 200
    : isLegallySpeakingWidget
    ? 150
    : viewMode === "grid-view"
    ? 100
    : 200;

  const shouldShowButton = content?.length > previewContentLength;

  const handleExpandClick = (event) => {
    setExpanded(!expanded);
    if (isAnnouncementWidget) {
      onSelect(announcementId);
    } else if (isListingWidget) {
      onSelect(listingId);
    } else if (isLegallySpeakingWidget) {
      onSelect(postId);
    } else {
      onExpandClick(event);
    }
  };

  const renderContent = () => {
    let displayContent = DOMPurify.sanitize(content);

    if ((!expanded && !isPostPage) || (!isListingWidget && isWidget)) {
      const { contentWithoutFirstVimeo, vimeoComponent } =
        extractFirstVimeoLinkAndComponent(displayContent);

      let truncatedContent = contentWithoutFirstVimeo;

      if (contentWithoutFirstVimeo?.length > previewContentLength) {
        truncatedContent = sliceMiddleContent(
          contentWithoutFirstVimeo,
          previewContentLength
        );
      }

      let replacedContent = replaceVimeoLinks(truncatedContent);

      let finalContent = [];

      replacedContent?.forEach((part) => {
        if (typeof part === "string") {
          const { parts: sheetsParts } = replaceGoogleSheetsLinks(part);
          finalContent.push(...sheetsParts);
        } else {
          if (
            !finalContent?.includes(vimeoComponent) &&
            part === vimeoComponent
          ) {
            finalContent.push(part);
          }
        }
      });

      return (
        <div>
          {vimeoComponent && (
            <React.Fragment key="vimeo">{vimeoComponent}</React.Fragment>
          )}
          {finalContent?.map((part, index) => (
            <React.Fragment key={index}>
              {typeof part === "string" ? parse(part) : part}
            </React.Fragment>
          ))}
        </div>
      );
    } else {
      let replacedContent = replaceVimeoLinks(displayContent);

      let finalContent = [];

      replacedContent?.forEach((part) => {
        if (typeof part === "string") {
          const { parts: sheetsParts } = replaceGoogleSheetsLinks(part);
          finalContent.push(...sheetsParts);
        } else {
          finalContent.push(part);
        }
      });

      return (
        <div>
          {finalContent?.map((part, index) => (
            <React.Fragment key={index}>
              {typeof part === "string" ? parse(part) : part}
            </React.Fragment>
          ))}
        </div>
      );
    }
  };

  return (
    <div
      className={`post-content ${isWidget ? "widget-mode" : ""}`}
      style={{ padding: 0, margin: 0 }}
    >
      <Tooltip
        sx={{ padding: 0, margin: 0 }}
        title=""
        className={`post-content-tooltip ${isWidget ? "widget-mode-mini" : ""}`}
      >
        {renderContent()}
      </Tooltip>

      {viewMode === "list-view" &&
        showOpenButton &&
        shouldShowButton &&
        !expanded &&
        !isPostPage && (
          <div style={{ width: "100%" }} className="widget-mode">
            <Button
              sx={{ marginTop: "10px" }}
              className="widget-mode"
              variant="outlined"
              color="success"
              onClick={(event) => handleExpandClick(event)}
            >
              {isWidget ? "Open..." : "Expand..."}
            </Button>
          </div>
        )}

      {expanded && (
        <div style={{ width: "100%" }} className="widget-mode">
          <Button
            sx={{ marginTop: "10px" }}
            className="widget-mode"
            color="success"
            variant="outlined"
            onClick={() => setExpanded(false)}
          >
            Collapse...
          </Button>
        </div>
      )}
    </div>
  );
}

export default memo(PostContent);
