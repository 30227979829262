import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const rolePermissionEnumsApi = createApi({
  reducerPath: "role-permission-enums",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/role-permission-enums/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["RolePermissionEnums"],
  endpoints: (builder) => ({
    createRolePermissionEnum: builder.mutation({
      query: ({ userRoleNameId, rolePermission }) => ({
        url: "create-one",
        method: "POST",
        body: { userRoleNameId, rolePermission },
      }),
      invalidatesTags: [{ type: "RolePermissionEnums", id: "LIST" }],
    }),
    getAllRolePermissionEnums: builder.query({
      query: () => ({
        url: "get-all",
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({
                type: "RolePermissionEnums",
                id: _id,
              })),
              { type: "RolePermissionEnums", id: "LIST" },
            ]
          : [{ type: "RolePermissionEnums", id: "LIST" }],
    }),
    getRolePermissionEnumById: builder.query({
      query: (rolePermissionEnumId) => ({
        url: `get-one-by-id/${rolePermissionEnumId}`,
        method: "GET",
      }),
      providesTags: (result, error, rolePermissionEnumId) => [
        { type: "RolePermissionEnums", id: rolePermissionEnumId },
      ],
    }),
    updateRolePermissionEnum: builder.mutation({
      query: ({ rolePermissionEnumId, userRoleNameId, rolePermission }) => ({
        url: `update/${rolePermissionEnumId}`,
        method: "PUT",
        body: { userRoleNameId, rolePermission },
      }),
      invalidatesTags: (result, error, { rolePermissionEnumId }) => [
        { type: "RolePermissionEnums", id: rolePermissionEnumId },
        { type: "RolePermissionEnums", id: "LIST" },
      ],
    }),
    deleteRolePermissionEnum: builder.mutation({
      query: (rolePermissionEnumId) => ({
        url: `delete-one/${rolePermissionEnumId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, rolePermissionEnumId) => [
        { type: "RolePermissionEnums", id: rolePermissionEnumId },
        { type: "RolePermissionEnums", id: "LIST" },
      ],
    }),
    getRolePermissionEnumsByUserRoleName: builder.query({
      query: (userRoleNameId) => ({
        url: `get-all-by-user-role-name/${userRoleNameId}`,
        method: "GET",
      }),
      providesTags: (result, error, userRoleNameId) =>
        result
          ? [
              ...result.map(({ _id }) => ({
                type: "RolePermissionEnums",
                id: _id,
              })),
              {
                type: "RolePermissionEnums",
                id: `USER_ROLE_NAME_${userRoleNameId}`,
              },
            ]
          : [
              {
                type: "RolePermissionEnums",
                id: `USER_ROLE_NAME_${userRoleNameId}`,
              },
            ],
    }),
    deleteRolePermissionEnumsByUserRoleName: builder.mutation({
      query: (userRoleNameId) => ({
        url: `delete-all-by-user-role-name/${userRoleNameId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, userRoleNameId) => [
        { type: "RolePermissionEnums", id: `USER_ROLE_NAME_${userRoleNameId}` },
        { type: "RolePermissionEnums", id: "LIST" },
      ],
    }),
    deleteAllRolePermissionEnums: builder.mutation({
      query: () => ({
        url: "delete-all",
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "RolePermissionEnums", id: "LIST" }],
    }),
    createInitialRolePermissionsEnums: builder.mutation({
      query: () => ({
        url: "create-initial-role-permissions-enums",
        method: "POST",
      }),
      invalidatesTags: [{ type: "RolePermissionEnums", id: "LIST" }],
    }),
    syncRolePermissionsEnums: builder.mutation({
      query: () => ({
        url: "sync-role-permissions-enums",
        method: "POST",
      }),
      invalidatesTags: [{ type: "RolePermissionEnums", id: "LIST" }],
    }),
  }),
});

export const {
  useCreateRolePermissionEnumMutation,
  useGetAllRolePermissionEnumsQuery,
  useGetRolePermissionEnumByIdQuery,
  useUpdateRolePermissionEnumMutation,
  useDeleteRolePermissionEnumMutation,
  useGetRolePermissionEnumsByUserRoleNameQuery,
  useDeleteRolePermissionEnumsByUserRoleNameMutation,
  useDeleteAllRolePermissionEnumsMutation,
  useCreateInitialRolePermissionsEnumsMutation,
  useSyncRolePermissionsEnumsMutation,
} = rolePermissionEnumsApi;

export default rolePermissionEnumsApi;
export { rolePermissionEnumsApi };
