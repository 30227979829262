import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../context/SidebarContext";
import { AuthContext } from "../../context/AuthContext";
import { useGetMemberProfileQuery } from "../../services/member";
import { formatPhoneNumber } from "../helpers/utils";

import {
  Grid,
  Typography,
  IconButton,
  Tooltip,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import { Fullscreen, MapsUgc, Edit } from "@mui/icons-material/";
import {
  ProfileDrawerBadge,
  ProfilePageBadge,
  formatDate,
} from "../helpers/utils";
import MemberProfileTabs from "./member-profile-tabs/MemberProfileTabs";
import MemberMessageForm from "./MemberMessageForm";
import MemberProfileEditForm from "./MemberProfileEditForm";

const MemberProfile = ({ userMemberId, isDrawer = false, onClose, tierId }) => {
  const { userId, accountType } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isCreateChatOpen, setIsCreateChatOpen] = useState(false);
  const { sidebarOpen } = useContext(SidebarContext);
  const {
    data: member,
    isLoading,
    refetch,
  } = useGetMemberProfileQuery(userMemberId, {
    skip: !userMemberId,
  });
  const formattedPhone = formatPhoneNumber(member?.phone);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [memberProfileEditFrom, setMemberProfileEditFrom] = useState(false);

  const navigateToProfile = () => {
    onClose();
    navigate(`/member-profile/${userMemberId}`);
  };

  const handleSaveSuccess = () => {
    setIsEditFormOpen(false);
    refetch();
  };

  const isAuthor = member?.user === userId;
  const isAuthorOrAdmin =
    isAuthor || accountType === "reosadmin" || accountType === "brokerage";
  const defaultImage = "/imgs/Coming-soon-Headshot.webp"; // Define the default image path
  const imageUrl = member?.image?.url || defaultImage; // Use the member image URL or the default
  const altText = `${member?.firstName} ${member?.lastName}`; // Construct the alt text for the image

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );

  return (
    <div
      style={isDrawer ? {} : { marginTop: "110px" }}
      className={isDrawer ? "member-drawer" : "member-profile-page"}
    >
      <div className="member-profile-body">
        <div
          className={`${
            !isDrawer && !sidebarOpen
              ? "member-profile-header sidebar-opened"
              : "member-profile-header"
          }`}
          style={{ display: "flex", width: "100%" }}
        >
          <div
            className="header-profile-image-block"
            style={{ flexBasis: "30%", flexGrow: 0, flexShrink: 0 }}
          >
            {member?.isOnline && isDrawer && (
              <ProfileDrawerBadge
                variant="dot"
                color="success"
                invisible={!member?.isOnline}
                className="header-profile-avatar"
              >
                <CardMedia
                  component="img"
                  className="member-profile-image"
                  image={imageUrl}
                  alt={`${member?.firstName} ${member?.lastName}`}
                />
                <span className="header-profile-avatar-span">Online</span>
              </ProfileDrawerBadge>
            )}
            {member?.isOnline && !isDrawer && (
              <ProfilePageBadge
                variant="dot"
                color="success"
                invisible={!member?.isOnline}
                className="header-profile-avatar"
              >
                <CardMedia
                  component="img"
                  className="member-profile-image"
                  image={imageUrl}
                  alt={`${member?.firstName} ${member?.lastName}`}
                />
                <span className="header-profile-avatar-span">Online</span>
              </ProfilePageBadge>
            )}
            {!member?.isOnline && (
              <>
                <CardMedia
                  component="img"
                  className="member-profile-image"
                  image={imageUrl}
                  alt={altText || "Member Image"} // Provide a fallback alt text if names are not available
                />
                
                <span className="header-profile-avatar-offline-span">
                  <strong>Last seen:</strong> <br />
                  {formatDate(member?.lastOnlineTime) || "Yet to Login"}
                </span>
              </>
            )}
          </div>
          <div style={{ flexBasis: "80%", flexGrow: 1 }}>
            <div className="header-profile-title">
              <Typography
                gutterBottom
                component="div"
                className="header-profile-title-name"
                sx={{
                  fontWeight: "bolder",
                  fontSize: "1.5rem",
                  marginBottom: "0px",
                }}
              >
                {member?.firstName} {member?.lastName}
              </Typography>
              <Typography
                gutterBottom
                component="div"
                className="header-profile-title-profile-title"
                sx={{
                  fontSize: "1rem",
                  marginBottom: "3px",
                  marginTop: "0px",
                  padding: "0px",
                }}
              >
                {member?.profileTitle || "No profile title"}
              </Typography>
              <Typography
                gutterBottom
                component="div"
                className="header-profile-title-profile-title"
                sx={{
                  fontSize: "0.9rem",
                  marginBottom: "5px",
                  marginTop: "0px",
                  padding: "0px",
                }}
              >
                <a href={`tel:${member?.phone}`} style={{ color: "inherit" }}>
                  {formattedPhone}
                </a>
              </Typography>
              <div className="actions-block">
                <Tooltip
                  title={`Direct Message ${member?.firstName || "User"}`}
                >
                  <IconButton
                    className="create-chat-button"
                    aria-label="create-chat"
                    onClick={() => setIsCreateChatOpen(true)}
                    size="large"
                    sx={{
                      fontSize: "0.9rem",
                      marginBottom: "0px",
                      marginTop: "0px",
                      padding: "0px",
                    }}
                  >
                    <MapsUgc />
                  </IconButton>
                </Tooltip>
                {isAuthorOrAdmin && (
                  <Tooltip title="Edit Profile">
                    <IconButton
                      className="edit-profile-button"
                      aria-label="edit-profile"
                      onClick={() => {
                        setMemberProfileEditFrom(true);
                        setIsEditFormOpen(true);
                      }}
                      size="large"
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          {isDrawer && (
            <Grid item>
              <Tooltip title="View Profile">
                <IconButton onClick={navigateToProfile}>
                  <Fullscreen />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </div>
        <MemberProfileTabs member={member} tierId={tierId} />
      </div>
      <MemberMessageForm
        open={isCreateChatOpen}
        onClose={() => setIsCreateChatOpen(false)}
        member={member}
      />
      {isEditFormOpen && (
        <MemberProfileEditForm
          userMemberId={userMemberId}
          open={isEditFormOpen}
          onClose={handleSaveSuccess}
          memberProfileEditFrom={memberProfileEditFrom}
        />
      )}
    </div>
  );
};

export default MemberProfile;
