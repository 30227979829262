export const areaSubareaMap = {
  Colwood: [
    { value: "Colwood Corners", title: "Colwood Corners" },
    { value: "Colwood Lake", title: "Colwood Lake" },
    { value: "Hatley Park", title: "Hatley Park" },
    { value: "Lagoon", title: "Lagoon" },
    { value: "Latoria", title: "Latoria" },
    { value: "Olympic View", title: "Olympic View" },
    { value: "Royal Bay", title: "Royal Bay" },
    { value: "Royal Roads", title: "Royal Roads" },
    { value: "Sun Ridge", title: "Sun Ridge" },
    { value: "Triangle", title: "Triangle" },
    { value: "Wishart North", title: "Wishart North" },
    { value: "Wishart South", title: "Wishart South" },
  ],
  "Campbell River": [
    { value: "Campbell River Central", title: "Campbell River Central" },
    { value: "Campbell River North", title: "Campbell River North" },
    { value: "Campbell River South", title: "Campbell River South" },
    { value: "Campbell River West", title: "Campbell River West" },
    { value: "Campbellton", title: "Campbellton" },
    { value: "Willow Point", title: "Willow Point" },
  ],
  "Central Saanich": [
    { value: "Brentwood Bay", title: "Brentwood Bay" },
    { value: "Hawthorne", title: "Hawthorne" },
    { value: "Inlet", title: "Inlet" },
    { value: "Island View", title: "Island View" },
    { value: "Keating", title: "Keating" },
    { value: "Martindale", title: "Martindale" },
    { value: "Oldfield", title: "Oldfield" },
    { value: "Saanichton", title: "Saanichton" },
    { value: "Tanner", title: "Tanner" },
    { value: "Turgoose", title: "Turgoose" },
    { value: "Willis Point", title: "Willis Point" },
  ],
  "Comox Valley": [
    { value: "Comox (Town of)", title: "Comox (Town of)" },
    { value: "Comox Peninsula", title: "Comox Peninsula" },
    { value: "Courtenay City", title: "Courtenay City" },
    { value: "Courtenay East", title: "Courtenay East" },
    { value: "Courtenay North", title: "Courtenay North" },
    { value: "Courtenay South", title: "Courtenay South" },
    { value: "Courtenay West", title: "Courtenay West" },
    { value: "Crown Isle", title: "Crown Isle" },
    { value: "Cumberland", title: "Cumberland" },
    { value: "Merville Black Creek", title: "Merville Black Creek" },
    { value: "Mt Washington", title: "Mt Washington" },
    { value: "Union Bay/Fanny Bay", title: "Union Bay/Fanny Bay" },
  ],
  Duncan: [
    { value: "Chemainus", title: "Chemainus" },
    { value: "Cowichan Bay", title: "Cowichan Bay" },
    {
      value: "Cowichan Station/Glenora",
      title: "Cowichan Station/Glenora",
    },
    { value: "Crofton", title: "Crofton" },
    { value: "East Duncan", title: "East Duncan" },
    { value: "Honeymoon Bay", title: "Honeymoon Bay" },
    { value: "Ladysmith", title: "Ladysmith" },
    { value: "Lake Cowichan", title: "Lake Cowichan" },
    { value: "Saltair", title: "Saltair" },
    { value: "West Duncan", title: "West Duncan" },
    { value: "Youbou", title: "Youbou" },
  ],
  Esquimalt: [
    { value: "Esquimalt", title: "Esquimalt" },
    { value: "Gorge Vale", title: "Gorge Vale" },
    { value: "Kinsmen Park", title: "Kinsmen Park" },
    { value: "Old Esquimalt", title: "Old Esquimalt" },
    { value: "Rockheights", title: "Rockheights" },
    { value: "Saxe Point", title: "Saxe Point" },
  ],
  "Gulf Islands": [
    { value: "Galiano", title: "Galiano" },
    { value: "Gulf Isl Other", title: "Gulf Isl Other" },
    { value: "Mayne Island", title: "Mayne Island" },
    { value: "Moresby Island", title: "Moresby Island" },
    { value: "Pender Island", title: "Pender Island" },
    { value: "Piers Island", title: "Piers Island" },
    { value: "Prevost Island", title: "Prevost Island" },
    { value: "Salt Spring", title: "Salt Spring" },
    { value: "Saturna Island", title: "Saturna Island" },
    { value: "Sidney Island", title: "Sidney Island" },
  ],
  Highlands: [
    { value: "Bear Mountain", title: "Bear Mountain" },
    { value: "Eastern Highlands", title: "Eastern Highlands" },
    { value: "Western Highlands", title: "Western Highlands" },
  ],
  Islands: [
    { value: "Alert Bay", title: "Alert Bay" },
    { value: "Cortes Island", title: "Cortes Island" },
    { value: "Decourcy Island", title: "Decourcy Island" },
    { value: "Denman Island", title: "Denman Island" },
    { value: "Gabriola Island", title: "Gabriola Island" },
    { value: "Hornby Island", title: "Hornby Island" },
    { value: "Lasqueti Island", title: "Lasqueti Island" },
    { value: "Mudge Island", title: "Mudge Island" },
    { value: "Protection Island", title: "Protection Island" },
    { value: "Quadra Island", title: "Quadra Island" },
    { value: "Read Island", title: "Read Island" },
    { value: "Ruxton Island", title: "Ruxton Island" },
    {
      value: "Small Islands  (Campbell River Area)",
      title: "Small Islands  (Campbell River Area)",
    },
    {
      value: "Small Islands (Courtenay Area)",
      title: "Small Islands (Courtenay Area)",
    },
    {
      value: "Small Islands (Duncan Area)",
      title: "Small Islands (Duncan Area)",
    },
    {
      value: "Small Islands (Nanaimo Area)",
      title: "Small Islands (Nanaimo Area)",
    },
    {
      value: "Small Islands (North Island Area)",
      title: "Small Islands (North Island Area)",
    },
    {
      value: "Small Islands (Other Boards Area)",
      title: "Small Islands (Other Boards Area)",
    },
    {
      value: "Small Islands (Parksville Area)",
      title: "Small Islands (Parksville Area)",
    },
    {
      value: "Small Islands (Port Alberni Area)",
      title: "Small Islands (Port Alberni Area)",
    },
    { value: "Sointula", title: "Sointula" },
    { value: "Stubbs Island", title: "Stubbs Island" },
    { value: "Thetis Island", title: "Thetis Island" },
    { value: "Valdes Island", title: "Valdes Island" },
  ],
  Langford: [
    { value: "Atkins", title: "Atkins" },
    { value: "Bear Mountain", title: "Bear Mountain" },
    { value: "Fairway", title: "Fairway" },
    { value: "Florence Lake", title: "Florence Lake" },
    { value: "Glen Lake", title: "Glen Lake" },
    { value: "Goldstream", title: "Goldstream" },
    { value: "Happy Valley", title: "Happy Valley" },
    { value: "Humpback", title: "Humpback" },
    { value: "Jacklin", title: "Jacklin" },
    { value: "Langford Lake", title: "Langford Lake" },
    { value: "Langford Proper", title: "Langford Proper" },
    { value: "Luxton", title: "Luxton" },
    { value: "Mill Hill", title: "Mill Hill" },
    { value: "Olympic View", title: "Olympic View" },
    { value: "Thetis Heights", title: "Thetis Heights" },
    { value: "Walfred", title: "Walfred" },
    { value: "Westhills", title: "Westhills" },
  ],
  Metchosin: [
    { value: "Albert Head", title: "Albert Head" },
    { value: "Kangaroo", title: "Kangaroo" },
    { value: "Metchosin", title: "Metchosin" },
    { value: "Neild", title: "Neild" },
    { value: "Olympic View", title: "Olympic View" },
    { value: "Pedder Bay", title: "Pedder Bay" },
    { value: "Rocky Point", title: "Rocky Point" },
    { value: "William Head", title: "William Head" },
  ],
  "Malahat Area": [
    { value: "Cobble Hill", title: "Cobble Hill" },
    { value: "Malahat Proper", title: "Malahat Proper" },
    { value: "Mill Bay", title: "Mill Bay" },
    { value: "Shawnigan", title: "Shawnigan" },
    { value: "Sooke Lake", title: "Sooke Lake" },
  ],
  Mainland: [{ value: "Mn Mainland Proper", title: "Mn Mainland Proper" }],
  Nanaimo: [
    { value: "Brechin Hill", title: "Brechin Hill" },
    { value: "Cedar", title: "Cedar" },
    { value: "Central Nanaimo", title: "Central Nanaimo" },
    { value: "Chase River", title: "Chase River" },
    { value: "Departure Bay", title: "Departure Bay" },
    { value: "Diver Lake", title: "Diver Lake" },
    { value: "Extension", title: "Extension" },
    { value: "Hammond Bay", title: "Hammond Bay" },
    { value: "Lower Lantzville", title: "Lower Lantzville" },
    { value: "North Jingle Pot", title: "North Jingle Pot" },
    { value: "North Nanaimo", title: "North Nanaimo" },
    { value: "Old City", title: "Old City" },
    { value: "Pleasant Valley", title: "Pleasant Valley" },
    { value: "South Jingle Pot", title: "South Jingle Pot" },
    { value: "South Nanaimo", title: "South Nanaimo" },
    { value: "University District", title: "University District" },
    { value: "Uplands", title: "Uplands" },
    { value: "Upper Lantzville", title: "Upper Lantzville" },
  ],
  "North Island": [
    { value: "Gold River", title: "Gold River" },
    {
      value: "Hyde Creek//Nimpkish Heights",
      title: "Hyde Creek//Nimpkish Heights",
    },
    { value: "Kelsey Bay/Sayward", title: "Kelsey Bay/Sayward" },
    { value: "Port Alice", title: "Port Alice" },
    { value: "Port Hardy", title: "Port Hardy" },
    { value: "Port McNeill", title: "Port McNeill" },
    { value: "Tahsis/Zeballos", title: "Tahsis/Zeballos" },
    { value: "Woss", title: "Woss" },
  ],
  "North Saanich": [
    { value: "Airport", title: "Airport" },
    { value: "Ardmore", title: "Ardmore" },
    { value: "Bazan Bay", title: "Bazan Bay" },
    { value: "Coles Bay", title: "Coles Bay" },
    { value: "Curteis Point", title: "Curteis Point" },
    { value: "Dean Park", title: "Dean Park" },
    { value: "Deep Cove", title: "Deep Cove" },
    { value: "Lands End", title: "Lands End" },
    { value: "McDonald Park", title: "McDonald Park" },
    { value: "Sandown", title: "Sandown" },
    { value: "Swartz Bay", title: "Swartz Bay" },
  ],
  "Oak Bay": [
    { value: "Estevan", title: "Estevan" },
    { value: "Gonzales", title: "Gonzales" },
    { value: "Henderson", title: "Henderson" },
    { value: "North Oak Bay", title: "North Oak Bay" },
    { value: "South Oak Bay", title: "South Oak Bay" },
    { value: "Uplands", title: "Uplands" },
  ],
  "Other Boards": [{ value: "Other Boards", title: "Other Boards" }],
  "Port Alberni": [
    { value: "Alberni Inlet", title: "Alberni Inlet" },
    { value: "Alberni Valley", title: "Alberni Valley" },
    { value: "Bamfield", title: "Bamfield" },
    { value: "Port Alberni", title: "Port Alberni" },
    { value: "Salmon Beach", title: "Salmon Beach" },
    { value: "Sproat Lake", title: "Sproat Lake" },
    { value: "Tofino", title: "Tofino" },
    { value: "Ucluelet", title: "Ucluelet" },
  ],
  "Parksville/Qualicum": [
    { value: "Bowser/Deep Bay", title: "Bowser/Deep Bay" },
    {
      value: "Errington/Coombs/Hilliers",
      title: "Errington/Coombs/Hilliers",
    },
    { value: "Fairwinds", title: "Fairwinds" },
    { value: "French Creek", title: "French Creek" },
    {
      value: "Little Qualicum River Village",
      title: "Little Qualicum River Village",
    },
    { value: "Nanoose", title: "Nanoose" },
    { value: "Parksville", title: "Parksville" },
    { value: "Qualicum Beach", title: "Qualicum Beach" },
    { value: "Qualicum North", title: "Qualicum North" },
  ],
  "Saanich East": [
    { value: "Arbutus", title: "Arbutus" },
    { value: "Blenkinsop", title: "Blenkinsop" },
    { value: "Broadmead", title: "Broadmead" },
    { value: "Cadboro Bay", title: "Cadboro Bay" },
    { value: "Camosun", title: "Camosun" },
    { value: "Cedar Hill", title: "Cedar Hill" },
    { value: "Cordova Bay", title: "Cordova Bay" },
    { value: "Gordon Head", title: "Gordon Head" },
    { value: "High Quadra", title: "High Quadra" },
    { value: "Lake Hill", title: "Lake Hill" },
    { value: "Lambrick Park", title: "Lambrick Park" },
    { value: "Maplewood", title: "Maplewood" },
    { value: "Mt Doug", title: "Mt Doug" },
    { value: "Mt Tolmie", title: "Mt Tolmie" },
    { value: "Quadra", title: "Quadra" },
    { value: "Queenswood", title: "Queenswood" },
    { value: "Sunnymead", title: "Sunnymead" },
    { value: "Swan Lake", title: "Swan Lake" },
    { value: "Ten Mile Point", title: "Ten Mile Point" },
  ],
  Sidney: [
    { value: "Sidney North-East", title: "Sidney North-East" },
    { value: "Sidney North-West", title: "Sidney North-West" },
    { value: "Sidney South-East", title: "Sidney South-East" },
    { value: "Sidney South-West", title: "Sidney South-West" },
  ],
  Sooke: [
    { value: "17 Mile", title: "17 Mile" },
    { value: "Becher Bay", title: "Becher Bay" },
    { value: "Billings Spit", title: "Billings Spit" },
    { value: "Broomhill", title: "Broomhill" },
    { value: "Carmanah", title: "Carmanah" },
    { value: "East Sooke", title: "East Sooke" },
    { value: "French Beach", title: "French Beach" },
    { value: "John Muir", title: "John Muir" },
    { value: "Jordan River", title: "Jordan River" },
    { value: "Kemp Lake", title: "Kemp Lake" },
    { value: "Otter Point", title: "Otter Point" },
    { value: "Phillips North", title: "Phillips North" },
    { value: "Port Renfrew", title: "Port Renfrew" },
    { value: "Saseenos", title: "Saseenos" },
    { value: "Sheringham Pnt", title: "Sheringham Pnt" },
    { value: "Silver Spray", title: "Silver Spray" },
    { value: "Sooke River", title: "Sooke River" },
    { value: "Sooke Village", title: "Sooke Village" },
    { value: "Sunriver", title: "Sunriver" },
    { value: "West Coast Rd", title: "West Coast Rd" },
    { value: "Whiffin Spit", title: "Whiffin Spit" },
  ],
  "Saanich West": [
    { value: "Beaver Lake", title: "Beaver Lake" },
    { value: "Elk Lake", title: "Elk Lake" },
    { value: "Gateway", title: "Gateway" },
    { value: "Glanford", title: "Glanford" },
    { value: "Gorge", title: "Gorge" },
    { value: "Granville", title: "Granville" },
    { value: "Interurban", title: "Interurban" },
    { value: "Layritz", title: "Layritz" },
    { value: "Marigold", title: "Marigold" },
    { value: "Northridge", title: "Northridge" },
    { value: "Portage Inlet", title: "Portage Inlet" },
    { value: "Prospect Lake", title: "Prospect Lake" },
    { value: "Royal Oak", title: "Royal Oak" },
    { value: "Rudd Park", title: "Rudd Park" },
    { value: "Strawberry Vale", title: "Strawberry Vale" },
    { value: "Tillicum", title: "Tillicum" },
    { value: "West Saanich", title: "West Saanich" },
  ],
  Victoria: [
    { value: "Burnside", title: "Burnside" },
    { value: "Central Park", title: "Central Park" },
    { value: "Downtown", title: "Downtown" },
    { value: "Fairfield East", title: "Fairfield East" },
    { value: "Fairfield West", title: "Fairfield West" },
    { value: "Fernwood", title: "Fernwood" },
    { value: "Hillside", title: "Hillside" },
    { value: "James Bay", title: "James Bay" },
    { value: "Jubilee", title: "Jubilee" },
    { value: "Mayfair", title: "Mayfair" },
    { value: "Oaklands", title: "Oaklands" },
    { value: "Rock Bay", title: "Rock Bay" },
    { value: "Rockland", title: "Rockland" },
  ],
  "View Royal": [
    { value: "Glentana", title: "Glentana" },
    { value: "Hospital", title: "Hospital" },
    { value: "Prior Lake", title: "Prior Lake" },
    { value: "Six Mile", title: "Six Mile" },
    { value: "View Royal", title: "View Royal" },
  ],
  "Victoria West": [
    { value: "Songhees", title: "Songhees" },
    { value: "Victoria West", title: "Victoria West" },
  ],
};
