import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Grid,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import {
  useDeleteAllRolePermissionEnumsMutation,
  useDeleteRolePermissionEnumsByUserRoleNameMutation,
} from "../../../services/role-permission-enum";
import { useGetAllUserRoleNameEnumsQuery } from "../../../services/user-role-name-enum";

const DeleteRolePermissionsDialog = ({ open, onClose, refetch }) => {
  const [deleteOption, setDeleteOption] = useState("all");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const { data: userRoleNameEnums } = useGetAllUserRoleNameEnumsQuery();

  const [deleteAllRolePermissions, { isLoading: isDeletingAll }] =
    useDeleteAllRolePermissionEnumsMutation();
  const [deleteRolePermissionsByRole, { isLoading: isDeletingByRole }] =
    useDeleteRolePermissionEnumsByUserRoleNameMutation();

  const handleDeleteOptionChange = (event) => {
    setDeleteOption(event.target.value);
    if (event.target.value === "all") {
      setSelectedRoleId("");
    }
  };

  const handleConfirmDelete = async () => {
    if (deleteOption) {
      let result;
      if (deleteOption === "all") {
        result = await deleteAllRolePermissions();
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
      } else if (deleteOption === "specific" && selectedRoleId) {
        result = await deleteRolePermissionsByRole(selectedRoleId);
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
      }
      if (result?.data) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        setTimeout(() => {
          if (deleteOption === "specific") {
            setSelectedRoleId("");
          }
          refetch?.();
          onClose();
        }, 1500);
      } else {
        setNotification({
          open: true,
          message: `Error deleting Role Permission Enums: ${
            result?.error?.data?.msg || "Unknown error"
          }`,
        });
      }
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const isSubmitting = isDeletingAll || isDeletingByRole;

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ backgroundColor: "#d32f2f", color: "white" }}>
          Delete Role Permissions
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            What would you like to delete?
          </Typography>
          <FormControl component="fieldset" sx={{ mt: 2 }}>
            <RadioGroup
              value={deleteOption}
              onChange={handleDeleteOptionChange}
            >
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="Delete All Role Permissions"
              />
              <FormControlLabel
                value="specific"
                control={<Radio />}
                label="Delete Role Permissions for a Specific Role"
              />
            </RadioGroup>
          </FormControl>

          {deleteOption === "specific" && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="select-role-label">Select Role</InputLabel>
              <Select
                labelId="select-role-label"
                value={selectedRoleId}
                label="Select Role"
                onChange={(e) => setSelectedRoleId(e.target.value)}
              >
                {userRoleNameEnums &&
                  userRoleNameEnums?.map((role) => (
                    <MenuItem key={role?._id} value={role?._id}>
                      {role?.userRoleName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

          {deleteOption === "all" && (
            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
              <strong>Warning:</strong> This action will delete{" "}
              <strong>all</strong> role permission enums across all roles. This
              action cannot be undone.
            </Typography>
          )}

          {deleteOption === "specific" && selectedRoleId && (
            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
              <strong>Warning:</strong> This action will delete role permission
              enums for the selected role only. This action cannot be undone.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
            disabled={
              isSubmitting || (deleteOption === "specific" && !selectedRoleId)
            }
          >
            {isSubmitting ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Delete"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleCloseNotification}
      />
    </>
  );
};

export default DeleteRolePermissionsDialog;
