import React, { memo } from "react";
import { useParams } from "react-router-dom";
import ListingCard from "./ListingCard";
import ListingPageNavbar from "./ListingPageNavbar";

const ListingPage = () => {
  const { listingId } = useParams();

  return (
    <div mt={2} className="listing-page">
      <ListingPageNavbar />
      <div className="listing-page-body">
        {listingId && <ListingCard listingId={listingId} />}
      </div>
    </div>
  );
};

export default memo(ListingPage);
