import React, { useState } from "react";
import {
  useGetAllListingToursQuery,
  useDeleteAllListingToursMutation,
} from "../../../services/listing-tours";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import { ConfirmationDialog } from "../..";

function ListingTourList() {
  const { data: listingTours } = useGetAllListingToursQuery();
  const [deleteAllListingTours, { isLoading: isDeleteAllListingToursLoading }] =
    useDeleteAllListingToursMutation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleDeleteClick = async () => {
    setIsConfirmDialogOpen(true);
  };

  const handleDeleteAllListingTours = async () => {
    const result = await deleteAllListingTours();
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setIsConfirmDialogOpen(false);
    } else {
      setNotification({
        open: true,
        message: `Error deleting Listing Tours. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };
  // console.log("listingTours", listingTours);
  return (
    <div className="management-page-body">
      <Paper sx={{ p: 1, mb: 2, display: "flex", flexDirection: "column" }}>
        <Typography variant="h6" align="center" gutterBottom>
          Listing Tours List
        </Typography>
        <Divider />
        <Box sx={{ mt: 1, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteClick}
            disabled={
              !listingTours ||
              listingTours?.length === 0 ||
              isDeleteAllListingToursLoading
            }
          >
            {isDeleteAllListingToursLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Delete All Listing Tours"
            )}
          </Button>
        </Box>
      </Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tier</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listingTours &&
              listingTours.length > 0 &&
              listingTours?.map((tour, index) => (
                <TableRow key={`${tour?._id}-${index}`}>
                  <TableCell>
                    {tour?.tier?.brokerage?.officeName || tour?.tier?.name}
                  </TableCell>
                  <TableCell>{tour?.title}</TableCell>
                  <TableCell>{`` || "No Actions Yet"}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {(!listingTours || listingTours?.length === 0) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 20,
          }}
        >
          <Typography variant="h5" gutterBottom>
            No Listing Tours available
          </Typography>
        </Box>
      )}
      <Divider style={{ margin: "20px 0" }} />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={handleDeleteAllListingTours}
        title="Confirm Deletion"
        description="Are you sure you want to delete all listing tours? This action cannot be undone."
      />
    </div>
  );
}

export default ListingTourList;
