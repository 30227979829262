import React, { useEffect, useState, memo, useMemo } from "react";
import { useIsFeatureActiveQuery } from "../../../services/feature-settings";
import { Paper, Tabs, Tab, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MembersManager from "../members/MembersManager";
import SalesManager from "../members/SalesManager";
import ListingTourManager from "../listing-tour/ListingTourManager";
import FlooorDutyTabs from "../duty-scheduling/FlooorDutyTabs";
import CategoriesManager from "../categories/CategoryManager";
import TeamAndGroupsManagerTabs from "./TeamAndGroupsManagerTabs";
import InvitationTabs from "../invitations/InvitationTabs";
import BillingTabs from "../billing/BillingTabs";
import { TabPanel } from "../../helpers/utils";
import debounce from "lodash/debounce";

function BrokerageTabs({ brokerageId, brokerageName, selectedTier }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: isFloorDutySchedulingFeatureActive } = useIsFeatureActiveQuery(
    "floor-duty-scheduling"
  );
  const { data: isBillingModuleFeatureActive } =
    useIsFeatureActiveQuery("billing-module");
  const { data: isListingTourFeatureActive } =
    useIsFeatureActiveQuery("listing-tour");
  const { data: isTransactionReviewFeatureActive } =
    useIsFeatureActiveQuery("transaction-review");
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const initialTabParam = parseInt(queryParams.get("tab"), 10);
  const initialTab = isNaN(initialTabParam) ? 0 : initialTabParam;
  const [btabValue, setBtabValue] = useState(initialTab);

  const tabs = useMemo(
    () => [
      {
        label: "Members",
        content: (
          <MembersManager brokerageId={brokerageId} tier={selectedTier} />
        ),
      },
      {
        label: "Sales Management",
        content: <SalesManager brokerageId={brokerageId} tier={selectedTier} />,
      },
      {
        label: "Brokerage Categories",
        content: <CategoriesManager tier={selectedTier} />,
      },
      {
        label: "Group Management",
        content: (
          <TeamAndGroupsManagerTabs
            brokerageId={brokerageId}
            tier={selectedTier}
          />
        ),
      },
      ...(isFloorDutySchedulingFeatureActive?.isActive
        ? [
            {
              label: "Flooor Duty Scheduling",
              content: <FlooorDutyTabs tier={selectedTier} />,
            },
          ]
        : []),
      {
        label: "Invitations",
        content: <InvitationTabs tierId={selectedTier?._id} />,
      },
      ...(isBillingModuleFeatureActive?.isActive
        ? [
            {
              label: "Billing",
              content: <BillingTabs tier={selectedTier} />,
            },
          ]
        : []),
      ...(isListingTourFeatureActive?.isActive
        ? [
            {
              label: "Listing Tour",
              content: <ListingTourManager tier={selectedTier} />,
            },
          ]
        : []),
      ...(isTransactionReviewFeatureActive?.isActive
        ? [
            {
              label: "Transaction Review",
              content: <SalesManager tier={selectedTier} />,
            }, //updating
          ]
        : []),
    ],
    [
      isFloorDutySchedulingFeatureActive,
      isBillingModuleFeatureActive,
      brokerageId,
      selectedTier,
    ]
  );

  useEffect(() => {
    if (btabValue >= tabs.length) {
      setBtabValue(0);
    }
  }, [btabValue, tabs.length]);

  const updateUrlParams = useMemo(
    () =>
      debounce((newTab) => {
        const params = new URLSearchParams(location.search);
        const currentTab = tabs[newTab]?.label;

        params.set("tab", newTab);
        if (currentTab !== "Group Management") params.delete("tgtab");
        if (currentTab !== "Flooor Duty Scheduling") params.delete("fdtab");
        if (currentTab !== "Invitations") params.delete("invtab");
        if (currentTab !== "Billing") params.delete("billtab");

        const newUrl = `${location.pathname}?${params.toString()}`;
        if (location.search !== newUrl) {
          navigate(newUrl, { replace: true });
        }
      }, 300),
    [location.search, location.pathname, navigate, tabs]
  );

  useEffect(() => {
    updateUrlParams(btabValue);
    return () => {
      updateUrlParams.cancel();
    };
  }, [btabValue, updateUrlParams]);

  const handleTabChange = (event, newValue) => {
    if (newValue < tabs.length) {
      setBtabValue(newValue);
    } else {
      setBtabValue(0);
    }
  };

  const validatedTabValue = btabValue < tabs?.length ? btabValue : 0;

  return (
    <Paper className="tabs-paper-brokerage-ecosys">
      <Typography gutterBottom className="main-title">
        Brokerage Management{brokerageName ? ": " + brokerageName : ""}
      </Typography>
      <Tabs
        value={validatedTabValue}
        onChange={handleTabChange}
        aria-label="brokerage tabs"
        variant="scrollable"
        scrollButtons="auto"
        className="tabs-paper"
      >
        {tabs?.map((tab, index) => (
          <Tab
            key={index}
            label={tab?.label}
            id={`brokerage-tab-${index}`}
            aria-controls={`brokerage-tabpanel-${index}`}
          />
        ))}
      </Tabs>
      {tabs?.map((tab, index) => (
        <TabPanel
          value={validatedTabValue}
          index={index}
          key={index}
          className="brokerage-tabs-paper"
        >
          {tab?.content}
        </TabPanel>
      ))}
    </Paper>
  );
}

export default memo(BrokerageTabs);
