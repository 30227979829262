import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetAllListingsByTierQuery,
  useUpdateListingMutation,
  useLazyDownloadPhotographQuery,
  useDeleteListingMutation,
} from "../../../services/listings";
import { useGetAllListingToursByTierQuery } from "../../../services/listing-tours";
import { useGetAllTierMembersQuery } from "../../../services/member";
import { AuthContext } from "../../../context/AuthContext";
import {
  DataGridPro,
  useGridApiRef,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import { Tooltip, Paper, Typography, Box, Button } from "@mui/material";
import {
  Edit,
  Flag as FlagIcon,
  FlagOutlined as FlagOutlinedIcon,
  Delete,
  OpenInNew,
} from "@mui/icons-material/";
import CreateEditListingForm from "../../reosadmin/listing-tour/CreateEditListingForm";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CommentsEditCell from "./listings/CommentsEditCell";
import ESPEditCell from "./listings/ESPEditCell";
import AgentEditCell from "./listings/AgentEditCell";
import AddListingsToListingTOurDialog from "./AddListingsToListingTOurDialog";
import { ConfirmationDialog } from "../..";

const AllListingsDataGrid = ({ tierId }) => {
  const navigate = useNavigate();
  const { userRole } = useContext(AuthContext);
  const { data: listings, refetch } = useGetAllListingsByTierQuery(tierId, {
    skip: !tierId,
  });
  const { data: listingToursData } = useGetAllListingToursByTierQuery(tierId, {
    skip: !tierId,
  });

  const [rows, setRows] = useState([]);
  const { data: members } = useGetAllTierMembersQuery(
    { tierId: tierId },
    { skip: !tierId }
  );
  const apiRef = useGridApiRef();
  const [trigger, { data: downloadedPhotograph }] =
    useLazyDownloadPhotographQuery();
  const [photographUrls, setPhotographUrls] = useState({});
  const [updateListing, { isLoading: isUpdating }] = useUpdateListingMutation();
  const [deleteListing] = useDeleteListingMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [createListingDialogOpen, setCreateListingDialogOpen] = useState(false);
  const [addListingsToTourDialogOpen, setAddListingsToTourDialogOpen] =
    useState(false);
  const [addListingsToTourDialogMode, setAddListingsToTourDialogMode] =
    useState("add");
  const editableRoles = [
    "reosadmin",
    "reossuperadmin",
    "brokeragesuperadmin",
    "brokerageadmin",
    "brokerage",
  ];
  const isEditable = editableRoles?.includes(userRole);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedListing, setSelectedListing] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const updateListingInArray = (array, id, updatedData) => {
    return array.map((item) =>
      item?._id === id ? { ...item, ...updatedData } : item
    );
  };
  const findAndUpdateListing = (id, updatedData) => {
    setRows((prev) => updateListingInArray(prev, id, updatedData));
  };

  useEffect(() => {
    if (listings) {
      const newRows = (listings || [])?.map((listing) => ({
        ...listing,
        id: listing?._id,
      }));

      setRows(newRows);
    }
  }, [listings]);

  useEffect(() => {
    if (listings && listings.length > 0) {
      listings?.forEach((listing) => {
        if (listing.isGroup) return;
        const firstPhoto = listing?.photographs?.[0];
        if (firstPhoto && !photographUrls[firstPhoto?._id]) {
          trigger({
            photographId: firstPhoto?._id,
            disposition: "photoghraphs",
          }).then((result) => {
            if (result?.data?.url) {
              setPhotographUrls((prev) => ({
                ...prev,
                [firstPhoto?._id]: result?.data?.url,
              }));
            }
          });
        }
      });
    }
  }, [listings, trigger, photographUrls]);

  const handleCreateListingDialogOpen = () => {
    setSelectedListing(null);
    setCreateListingDialogOpen(true);
  };

  const handleCreateListingDialogClose = () => {
    setSelectedListing(null);
    setCreateListingDialogOpen(false);
  };

  const handleAddListingsToTourDialogOpen = () => {
    setAddListingsToTourDialogMode("add");
    setAddListingsToTourDialogOpen(true);
  };

  const handleRemoveListingsFromTourDialogOpen = () => {
    setAddListingsToTourDialogMode("remove");
    setAddListingsToTourDialogOpen(true);
  };

  const handleAddListingsToTourDialogClose = () => {
    setAddListingsToTourDialogMode("");
    setAddListingsToTourDialogOpen(false);
  };

  const handleAgentChange = async (id, agent) => {
    if (agent) {
      const formData = new FormData();
      const submissionData = {
        agent: agent?._id,
      };
      formData.append("listingData", JSON.stringify(submissionData));

      const result = await updateListing({
        tierId: tierId,
        listingId: id,
        formData,
      });

      if (result?.data) {
        findAndUpdateListing(id, { agent });
        setNotification({
          open: true,
          message: `Agent Field: ${result?.data?.msg}`,
        });
        refetch?.();
      } else {
        setNotification({
          open: true,
          message: `Error updating agent: ${
            result?.error?.data?.msg || "Unknown error"
          }`,
        });
      }
    }
  };

  const handleCommentChange = async (id, updatedComments) => {
    const row = rows?.find((row) => row?._id === id);

    const existingComments = row?.comments || [];

    const allComments = [...existingComments, ...updatedComments];

    if (allComments && allComments?.length > 0) {
      const formData = new FormData();
      const submissionData = {
        comments: allComments,
      };
      formData.append("listingData", JSON.stringify(submissionData));

      const result = await updateListing({
        tierId: tierId,
        listingId: id,
        formData,
      });
      if (result?.data) {
        findAndUpdateListing(id, {
          comments: allComments,
        });
        apiRef.current.stopCellEditMode({ id, field: "comments" });

        setNotification({
          open: true,
          message: `Comment Added: ${result?.data?.msg}`,
        });
        setTimeout(() => {
          refetch?.();
        }, 500);
      } else {
        setNotification({
          open: true,
          message: `Error adding comment: ${
            result?.error?.data?.msg || "Unknown error"
          }`,
        });
      }
    }
  };

  const handleESPChange = async (id, updatedESPs) => {
    const row = rows?.find((row) => row?._id === id);

    const existingESPs = row?.estimatedSellingPrice || [];

    const allESPs = [...existingESPs, ...updatedESPs];

    if (allESPs && allESPs?.length > 0) {
      const formData = new FormData();
      const submissionData = {
        estimatedSellingPrice: allESPs,
      };
      formData.append("listingData", JSON.stringify(submissionData));

      const result = await updateListing({
        tierId: tierId,
        listingId: id,
        formData,
      });
      if (result?.data) {
        findAndUpdateListing(id, {
          estimatedSellingPrice: updatedESPs,
        });

        apiRef.current.stopCellEditMode({
          id,
          field: "estimatedSellingPrice",
        });

        setNotification({
          open: true,
          message: `Estimated Price Field: ${result?.data?.msg}`,
        });
        setTimeout(() => {
          refetch?.();
        }, 500);
      } else {
        setNotification({
          open: true,
          message: `Error adding Estimated Price: ${
            result?.error?.data?.msg || "Unknown error"
          }`,
        });
      }
    }
  };

  const columns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<OpenInNew />}
          label="Open"
          onClick={() => handleOpenClick(params?.id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEditClick(params?.id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={() => handleDeleteClick(params?.id)}
          color="inherit"
        />,
      ],
    },
    {
      field: "photographs",
      headerName: "icon",
      width: 75,
      renderCell: (params) => {
        const firstPhoto = params.row?.photographs?.[0];
        const photoUrl = photographUrls[firstPhoto?._id];

        const listingId = params.row?._id;

        const handleClick = () => {
          navigate(`/listing/${listingId}`);
        };

        return (
          <Tooltip title="View Listing Details">
            <img
              src={photoUrl || "/imgs/Home-img-coming-soon.jpg"}
              alt="Photograph"
              style={{
                width: 50,
                height: 50,
                objectFit: "cover",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={handleClick}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "isForListingTour",
      headerName: "For Tour",
      width: 50,
      renderCell: (params) => {
        const isForTour = params.row?.isForListingTour;

        return (
          <>
            {isForTour ? (
              <FlagIcon color="error" />
            ) : (
              <FlagOutlinedIcon color="disabled" />
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      valueGetter: (value, row) =>
        `${row?.status === "pending" ? "coming" : row?.status}`,
    },
    {
      field: "tourComments",
      headerName: "Tour Comments",
      width: 300,
      valueGetter: (value, row) => {
        return row?.tourComments ? row?.tourComments.join("\n") : "";
      },
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", lineHeight: "1.5" }}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      width: 200,
    },
    {
      field: "listPrice",
      headerName: "List Price",
      width: 100,
      valueGetter: (value, row) => (row?.listPrice ? `$${row?.listPrice}` : ""),
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "listingDate",
      headerName: "Listing Date",
      width: 150,
      valueGetter: (value, row) => {
        return row?.listingDate
          ? new Date(row?.listingDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : "";
      },
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "bedrooms",
      headerName: "Bedrooms",
      width: 75,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "bathrooms",
      headerName: "Bathrooms",
      width: 75,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "totalSqft",
      headerName: "Total (sqft)",
      width: 100,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "finishedSqft",
      headerName: "Finished (sqft)",
      width: 100,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "lotSqft",
      headerName: "Lot (sqft)",
      width: 100,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "lotAcres",
      headerName: "Lot Acres",
      width: 100,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "mls",
      headerName: "MLS",
      width: 75,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "area",
      headerName: "Area",
      width: 110,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "subarea",
      headerName: "Subarea",
      width: 150,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "openTime",
      headerName: "Open Time",
      width: 150,
      valueGetter: (value, row) =>
        `${
          row?.openTime && !row?.openTime?.includes("undefined")
            ? row?.openTime
            : "Not limited"
        }`,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "lockboxLocation",
      headerName: "Lockbox Location",
      width: 150,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "agent",
      headerName: "Agent",
      width: 200,
      editable: isEditable,
      valueGetter: (value, row) => {
        return row.agent
          ? `${row?.agent?.firstName} ${row?.agent?.lastName}`
          : "";
      },
      renderEditCell: (params) => {
        return (
          <AgentEditCell
            params={params}
            handleAgentChange={handleAgentChange}
            members={members}
          />
        );
      },
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "listingComment",
      headerName: "Listing Comments",
      width: 300,
      valueGetter: (value, row) => {
        return row?.listingComment ? row?.listingComment.join("\n") : "";
      },
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", lineHeight: "1.5" }}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 300,
      editable: isEditable,
      valueFormatter: (value, row) => {
        if (!row?.comments || !Array.isArray(row?.comments)) {
          return "";
        }
        return row?.comments
          .map(
            (comment) =>
              `${comment?.user?.firstName || ""} ${
                comment?.user?.lastName?.charAt(0) || ""
              }: ${comment?.comment || ""}`
          )
          .join("; ");
      },
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", lineHeight: "1.5" }}>
            {params.formattedValue}
          </div>
        );
      },
      renderEditCell: (params) => {
        return (
          <CommentsEditCell
            params={params}
            handleCommentChange={handleCommentChange}
          />
        );
      },
    },
    {
      field: "estimatedSellingPrice",
      headerName: "Estimated Selling Price",
      width: 300,
      editable: isEditable,
      valueFormatter: (value, row) => {
        if (
          !row?.estimatedSellingPrice ||
          !Array.isArray(row?.estimatedSellingPrice)
        ) {
          return "";
        }
        return row?.estimatedSellingPrice
          ?.map(
            (esp) =>
              `${esp?.user?.firstName || ""} ${
                esp?.user?.lastName?.charAt(0) || ""
              }: $${esp?.estimatedPrice || ""}`
          )
          ?.join("; ");
      },
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", lineHeight: "1.5" }}>
            {params.formattedValue}
          </div>
        );
      },
      renderEditCell: (params) => {
        return (
          <ESPEditCell params={params} handleESPChange={handleESPChange} />
        );
      },
    },
    {
      field: "videoLinks",
      headerName: "Video Links",
      width: 250,
      editable: isEditable,
      valueGetter: (value, row) => {
        return row?.videoLinks ? row?.videoLinks.join("\n") : "";
      },
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", lineHeight: "1.5" }}>
            {params.value}
          </div>
        );
      },
    },
  ];

  const handleOpenClick = (id) => {
    if (id) {
      navigate(`/listing/${id}`);
    } else {
      console.log("Listing ID is missing");
    }
  };

  const handleEditClick = (id) => {
    const listing = rows?.find((item) => item?.id === id);
    setSelectedListing(listing);
    setCreateListingDialogOpen(true);
  };

  const handleDeleteClick = async (id) => {
    const listing = rows?.find((item) => item?.id === id);
    setSelectedListing(listing);
    setIsConfirmDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedListing?._id) {
      setNotification({
        open: true,
        message: "The listing ID is missing.",
      });
      return;
    }

    const result = await deleteListing({ listingId: selectedListing?._id });
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });

      setRows((prev) =>
        prev.filter((item) => item?.id !== selectedListing?._id)
      );

      setTimeout(() => {
        setIsConfirmDialogOpen(false);
      }, 1000);
    } else {
      setNotification({
        open: true,
        message: `Error deleting listing: ${
          result?.error?.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const heightOfDataGridContainer =
    rows && rows?.length > 0 && rows?.length < 4
      ? "25rem"
      : rows?.length > 5
      ? "35rem"
      : rows?.length > 10
      ? "45rem"
      : "25rem";
  // console.log("");
  return (
    <div className="listing-tour-tab-body">
      <Paper className="listing-tour-tab-body-header">
        <Box
          sx={{ p: 0, mt: 1, display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateListingDialogOpen}
          >
            Create New Listing
          </Button>
          <Box>
            <Button
              variant="contained"
              color="success"
              onClick={handleAddListingsToTourDialogOpen}
              disabled={!selectionModel.length}
              sx={{ mr: 1 }}
            >
              Add Selected Listings to the Tour
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleRemoveListingsFromTourDialogOpen}
              disabled={!selectionModel.length}
            >
              Remove Selected Listings from the Tour
            </Button>
          </Box>
        </Box>
      </Paper>
      {rows && rows?.length > 0 && (
        <div className="listing-tour-tab-body-data-grids-item past-tour">
          <Typography variant="h6">All Listings</Typography>
          <DataGridPro
            apiRef={apiRef}
            rows={rows}
            columns={columns}
            sx={{ height: heightOfDataGridContainer }}
            getRowId={(row) => row.id}
            getRowHeight={() => "auto"}
            checkboxSelection
            disableSelectionOnClick
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={(newSelection) => {
              setSelectionModel(newSelection);
              // console.log("setPastSelectionModel Rows:", newSelection);
            }}
          />
        </div>
      )}
      {(!rows || rows?.length === 0) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 2,
          }}
        >
          <Typography variant="h5" gutterBottom>
            No Listings available
          </Typography>
        </Box>
      )}
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <CreateEditListingForm
        open={createListingDialogOpen}
        onClose={handleCreateListingDialogClose}
        mode={selectedListing ? "edit" : "create"}
        tierId={tierId}
        listingTours={listingToursData?.upcomingTours || []}
        listing={selectedListing}
      />
      <AddListingsToListingTOurDialog
        open={addListingsToTourDialogOpen}
        onClose={handleAddListingsToTourDialogClose}
        tierId={tierId}
        listingIds={selectionModel}
        listingTours={listingToursData?.upcomingTours || []}
        refetch={refetch}
        mode={addListingsToTourDialogMode}
      />
      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={handleDeleteConfirm}
        title="Confirm Deletion"
        description="Are you sure you want to delete this listing? This action cannot be undone."
      />
    </div>
  );
};

export default AllListingsDataGrid;
