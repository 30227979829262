import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const migrationApi = createApi({
  reducerPath: "migration",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/migrations/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Migration"],
  endpoints: (builder) => ({
    migrateCategoryToNewTier: builder.mutation({
      query: ({ categoryId, newTierId }) => ({
        url: "category-to-new-tier",
        method: "POST",
        body: { categoryId, newTierId },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Category", id: "LIST" },
      ],
    }),
    migratePostToNewCategory: builder.mutation({
      query: ({ postId, newCategoryId }) => ({
        url: "post-to-new-category",
        method: "POST",
        body: { postId, newCategoryId },
      }),
      invalidatesTags: [{ type: "Post", id: "LIST" }],
    }),
    migratePostsToListings: builder.mutation({
      query: (accountType) => ({
        url: "migrate-posts-to-listings",
        method: "POST",
      }),
      skip: (accountType) => accountType !== "reosadmin",
      invalidatesTags: [
        { type: "Post", id: "LIST" },
        { type: "Listing", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useMigrateCategoryToNewTierMutation,
  useMigratePostToNewCategoryMutation,
  useMigratePostsToListingsMutation,
} = migrationApi;

export default migrationApi;
export { migrationApi };
